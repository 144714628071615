<template>
  <div class="layout card">
    <div v-if="ready" class="flex column center">
      <h4 class="card-header" v-if="status != 'Dashboard'">{{ status }}</h4>
      <!-- <div class="width-80"> -->
      <i
        @click="() => downloadCSV()"
        class="hover-pointer fas fa-file-download export-data"
        style="font-size: 30px; margin: 10px; color: rgba(0, 245, 175, 0.85)"
      ></i>
      <!-- </div> -->
      <el-input
        v-model="filterBy"
        placeholder="Filter By"
        @input="changeFilter"
        class="mb16 filter"
      ></el-input>
      <div
        class="flex column center"
        v-if="allExampleItems.length && allExampleItems[0].user && !unpassed"
      >
        <p class="mrg0">
          Filter those who have not completed the selected games
        </p>
        <el-select
          @change="changeSelect"
          v-model="selectedGames"
          multiple
          placeholder="Filter by game"
        >
          <el-option
            v-for="item in games"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <el-checkbox
        v-if="
          allExampleItems.length &&
          (allExampleItems[0].user || status === 'Dashboard')
        "
        @change="showUnpassedUsers"
        v-model="unpassed"
        >show only unpassed users</el-checkbox
      >
      <el-checkbox
        v-if="allExampleItems.length && status === 'Dashboard' && oktaUsers && oktaUsers.length"
        @change="showUnregisteredUsers"
        v-model="unregistered"
        >show only unregistered users</el-checkbox
      >
      <div class="card-body flex column align-center space-around">
        <div v-if="items.length" class="width-80">
          <div
            v-for="user in pageOfItems"
            :key="user.id"
            class="flex user align-center space-between width-100"
          >
            <p class="mrg0 mr16">
              {{
                status === "Dashboard"
                  ? user.totalScore
                    ? user.fullName +
                      " : " +
                      user.email +
                      " - score: " +
                      user.totalScore
                    : user.fullName + " : " + user.email
                  : status === "Registered Slack Users"
                  ? user.slackUser.profile.real_name
                    ? user.slackUser.profile.real_name +
                      " : " +
                      user.slackUser.profile.email
                    : user.slackUser.name + " : " + user.slackUser.profile.email
                  : status === "Unregistered Slack Users"
                  ? user.profile.real_name
                    ? user.profile.real_name +
                      (user.profile.email ? " : " + user.profile.email : "")
                    : user.name
                    ? user.name +
                      (user.profile.email ? " : " + user.profile.email : "")
                    : user.name +
                      (user.profile.email ? " : " + user.profile.email : "")
                  : status === "Registered Okta Users"
                  ? user.oktaUser.profile.displayName +
                    " : " +
                    (user.oktaUser.profile.division || "") +
                    " - " +
                    user.oktaUser.profile.department
                  : (user.profile.displayName ||
                      user.profile.firstName + " " + user.profile.lastName ||
                      user.profile.email) +
                    " : " +
                    (user.profile.division || user.profile.email) +
                    " - " +
                    (user.profile.department || "")
              }}
            </p>
            <div class="icons-status flex center">
              <div class="mrg5 flex column center">
                <span>Exclude</span>
                <el-checkbox
                  @change="() => excludeEmail(user)"
                  v-model="user.exclude"
                ></el-checkbox>
              </div>
              <div class="mrg5 flex column center">
                <span v-if="status === 'Dashboard'">Passed</span>
                <i
                  v-if="
                    status === 'Registered Slack Users' ||
                    status === 'Registered Okta Users' ||
                    (status === 'Dashboard' && user.passed === 'true')
                  "
                  class="far fa-check-circle"
                  style="color: green"
                ></i>
                <i v-else class="fas fa-times-circle" style="color: red"></i>
              </div>
              <div
                class="mrg5 flex column center"
                v-if="status === 'Dashboard' && oktaUsers && oktaUsers.length"
              >
                <span>Registered</span>
                <i
                  v-if="user.totalScore >= 0"
                  class="far fa-check-circle"
                  style="color: green"
                ></i>
                <i v-else class="fas fa-times-circle" style="color: red"></i>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <h3>No users for display</h3>
        </div>
      </div>
      <div class="card-footer pb-0 pt-3">
        <jw-pagination
          :items="items"
          @changePage="onChangePage"
          :disableDefaultStyles="true"
        ></jw-pagination>
      </div>
      <div class="slack-message flex column center">
        <h3 v-if="status === 'Unregistered Okta Users'">
          Send a reminder for Okta users that exist in Slack
        </h3>
        <h3 v-else>Send a reminder</h3>
        <div class="width-100 flex center">
          <el-input
            type="textarea"
            :rows="2"
            class="slack-message-input"
            v-model="slackReminderMessage"
            placeholder="Enter your message"
          ></el-input>
          <button class="button slack-message-btn" @click="sendReminder">
            <i class="fas fa-paper-plane"></i>
          </button>
        </div>
      </div>
    </div>
    <div v-else class="loading-container flex column center">
      <img :src="require(`@/assets/img/loading.gif`)" alt="" srcset="" />
      <p>Loading...</p>
    </div>
  </div>
</template>

<script>
const jsonexport = require("jsonexport");

export default {
  props: ["exampleItems", "status"],
  data() {
    return {
      pageOfItems: [],
      slackReminderMessage: null,
      filterBy: null,
      allExampleItems: null,
      items: this.exampleItems,
      timeout: null,
      csv: [],
      selectedGames: [],
      games: [
        {
          value: "cyber-room",
          label: "Virtual Escape Room",
        },
        {
          value: "scenario",
          label: "Cyber Security Training",
        },
        {
          value: "sql-injection",
          label: "Secure Development",
        },
      ],
      ready: false,
      unpassed: false,
      unregistered: false,
      notFoundEmails: null,
      excludesEmails: [],
    };
  },
  computed: {
    loggedinUser() {
      return this.$store.getters.loggedinUser;
    },
    oktaUsers() {
      return this.$store.getters.oktaUsers;
    },
  },
  methods: {
    excludeEmail(user) {
      this.ready = false;
      console.log("excludeEmail", user);
      if (user.exclude) {
        delete user.exclude;
        this.allExampleItems.forEach((exampleItem, idx) => {
          if (JSON.stringify(exampleItem) === JSON.stringify(user)) {
            this.allExampleItems[idx].exclude = true;
          }
        });
        user.exclude = true;
      } else {
        delete user.exclude;
        this.allExampleItems.forEach((exampleItem, idx) => {
          if (JSON.stringify(exampleItem) === JSON.stringify(user)) {
            this.allExampleItems[idx].exclude = false;
          }
        });
      }
      this.ready = true;
    },
    downloadCSV() {
      this.csv = [];
      switch (this.status) {
        case "Registered Slack Users":
          console.log(this.status);
          this.items.forEach((item) => {
            var flatten = {};
            flatten.name = item.slackUser.profile.display_name;
            flatten.email = item.slackUser.profile.email;
            this.csv.push(flatten);
          });
          break;
        case "Unregistered Slack Users":
          console.log(this.status);
          this.items.forEach((item) => {
            var flatten = {};
            flatten.name = item.profile.display_name || item.profile.name;
            flatten.email = item.profile.email;
            this.csv.push(flatten);
          });
          break;
        case "Registered Okta Users":
          console.log(this.status);
          this.items.forEach((item) => {
            var flatten = {};
            flatten.name =
              item.oktaUser.profile.displayName ||
              item.oktaUser.profile.firstName +
                " " +
                item.oktaUser.profile.lastName;
            flatten.email = item.oktaUser.profile.email;
            flatten.division = item.oktaUser.profile.division;
            flatten.department = item.oktaUser.profile.department;
            flatten.manager = item.oktaUser.profile.manager;
            flatten.Account_Type = item.oktaUser.profile.Account_Type;
            flatten.Entity = item.oktaUser.profile.Entity;
            this.csv.push(flatten);
          });
          break;
        case "Unregistered Okta Users":
          console.log(this.status);
          this.items.forEach((item) => {
            var flatten = {};
            flatten.name =
              item.profile.displayName ||
              item.profile.firstName + " " + item.profile.lastName;
            flatten.email = item.profile.email;
            flatten.division = item.profile.division;
            flatten.department = item.profile.department;
            flatten.manager = item.profile.manager;
            flatten.Account_Type = item.profile.Account_Type;
            flatten.Entity = item.profile.Entity;
            this.csv.push(flatten);
          });
          break;
        case "Dashboard":
          console.log(this.status);
          this.items.forEach((item) => {
            // var flatten = {};
            // flatten.name = item.fullName;
            // flatten.email = item.email;
            // flatten.division = item.division;
            // flatten.department = item.department;
            // flatten.manager = item.manager;
            // flatten.Account_Type = item.Account_Type;
            // flatten.Entity = item.Entity;
            // this.csv.push(flatten);
            this.csv.push(item);
          });
          break;
      }
      var csv = this.csv;
      var status = this.status;
      jsonexport(csv, function (err, csv) {
        if (err) return console.error(err);
        var hiddenElement = document.createElement("a");
        hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
        hiddenElement.target = "_blank";

        //provide the name for the CSV file to be downloaded
        hiddenElement.download = status;
        hiddenElement.click();
      });
    },
    changeSelect() {
      clearTimeout(this.timeout);
      this.ready = true;
      this.timeout = setTimeout(() => {
        console.log("changeSelect");
        this.ready = false;
        this.items = JSON.parse(JSON.stringify(this.allExampleItems));
        var selectedGames = [...this.selectedGames];
        console.log(selectedGames);
        var status = false;
        var cyberRoomStatus = false;
        var scenarioStatus = false;
        var sqlInjectionStatus = false;
        if (
          selectedGames.includes("cyber-room") &&
          selectedGames.includes("scenario") &&
          selectedGames.includes("sql-injection")
        ) {
          this.items = this.items.filter((exampleItem) => {
            status = false;
            cyberRoomStatus = false;
            scenarioStatus = false;
            sqlInjectionStatus = false;
            if (exampleItem.user && exampleItem.user.scores) {
              exampleItem.user.scores.forEach((game) => {
                if (status) return;
                if (game.type === "cyber-room" && game.endAt) {
                  cyberRoomStatus = true;
                }
                if (game.type === "scenario" && game.endAt) {
                  scenarioStatus = true;
                }
                if (game.type === "sql-injection" && game.endAt) {
                  sqlInjectionStatus = true;
                }
              });
            }
            if (cyberRoomStatus && scenarioStatus && sqlInjectionStatus) {
              status = true;
            }
            return !status;
          });
          console.log(this.items);
        } else if (
          selectedGames.includes("scenario") &&
          selectedGames.includes("sql-injection")
        ) {
          this.items = this.items.filter((exampleItem) => {
            status = false;
            scenarioStatus = false;
            sqlInjectionStatus = false;
            if (exampleItem.user && exampleItem.user.scores) {
              exampleItem.user.scores.forEach((game) => {
                if (status) return;
                if (game.type === "scenario" && game.endAt) {
                  scenarioStatus = true;
                }
                if (game.type === "sql-injection" && game.endAt) {
                  sqlInjectionStatus = true;
                }
              });
            }
            if (scenarioStatus && sqlInjectionStatus) {
              status = true;
            }
            return !status;
          });
          console.log(this.items);
        } else if (
          selectedGames.includes("cyber-room") &&
          selectedGames.includes("sql-injection")
        ) {
          console.log("cyber-room&sql-injection");
          this.items = this.items.filter((exampleItem) => {
            status = false;
            cyberRoomStatus = false;
            sqlInjectionStatus = false;
            if (exampleItem.user && exampleItem.user.scores) {
              exampleItem.user.scores.forEach((game) => {
                if (status) return;
                if (game.type === "cyber-room" && game.endAt) {
                  cyberRoomStatus = true;
                }
                if (game.type === "sql-injection" && game.endAt) {
                  sqlInjectionStatus = true;
                }
              });
            }
            if (cyberRoomStatus && sqlInjectionStatus) {
              status = true;
            }
            return !status;
          });
          console.log(this.items);
        } else if (
          selectedGames.includes("cyber-room") &&
          selectedGames.includes("scenario")
        ) {
          this.items = this.items.filter((exampleItem) => {
            status = false;
            cyberRoomStatus = false;
            scenarioStatus = false;
            if (exampleItem.user && exampleItem.user.scores) {
              exampleItem.user.scores.forEach((game) => {
                if (status) return;
                if (game.type === "cyber-room" && game.endAt) {
                  cyberRoomStatus = true;
                }
                if (game.type === "scenario" && game.endAt) {
                  scenarioStatus = true;
                }
              });
            }
            if (cyberRoomStatus && scenarioStatus) {
              status = true;
            }
            return !status;
          });
          console.log(this.items);
        } else if (selectedGames.includes("scenario")) {
          this.items = this.items.filter((exampleItem) => {
            status = false;
            scenarioStatus = false;
            if (exampleItem.user && exampleItem.user.scores) {
              exampleItem.user.scores.forEach((game) => {
                if (status) return;
                if (game.type === "scenario" && game.endAt) {
                  scenarioStatus = true;
                }
              });
            }
            if (scenarioStatus) {
              status = true;
            }
            return !status;
          });
          console.log(this.items);
        } else if (selectedGames.includes("cyber-room")) {
          this.items = this.items.filter((exampleItem) => {
            status = false;
            cyberRoomStatus = false;
            if (exampleItem.user && exampleItem.user.scores) {
              exampleItem.user.scores.forEach((game) => {
                if (status) return;
                if (game.type === "cyber-room" && game.endAt) {
                  cyberRoomStatus = true;
                }
              });
            }
            if (cyberRoomStatus) {
              status = true;
            }
            return !status;
          });
          console.log(this.items);
        } else if (selectedGames.includes("sql-injection")) {
          this.items = this.items.filter((exampleItem) => {
            status = false;
            sqlInjectionStatus = false;
            if (exampleItem.user && exampleItem.user.scores) {
              exampleItem.user.scores.forEach((game) => {
                if (status) return;
                if (game.type === "sql-injection" && game.endAt) {
                  sqlInjectionStatus = true;
                }
              });
            }
            if (sqlInjectionStatus) {
              status = true;
            }
            return !status;
          });
          console.log(this.items);
        }
        this.ready = true;
      }, 200);
    },
    changeFilter() {
      clearTimeout(this.timeout);
      this.ready = true;
      this.timeout = setTimeout(() => {
        console.log("changeFilter");
        this.ready = false;
        // this.items = JSON.parse(JSON.stringify(this.allExampleItems));
        switch (this.status) {
          case "Registered Slack Users":
            this.items = this.items.filter((exampleItem) => {
              return (
                (exampleItem.slackUser.profile.display_name &&
                  exampleItem.slackUser.profile.display_name
                    .toLowerCase()
                    .includes(this.filterBy.toLowerCase())) ||
                (exampleItem.slackUser.profile.email &&
                  exampleItem.slackUser.profile.email
                    .toLowerCase()
                    .includes(this.filterBy.toLowerCase()))
              );
            });
            break;
          case "Unregistered Slack Users":
            this.items = this.items.filter((exampleItem) => {
              return (
                (exampleItem.profile.display_name &&
                  exampleItem.profile.display_name
                    .toLowerCase()
                    .includes(this.filterBy.toLowerCase())) ||
                (exampleItem.profile.real_name &&
                  exampleItem.profile.real_name
                    .toLowerCase()
                    .includes(this.filterBy.toLowerCase())) ||
                (exampleItem.name &&
                  exampleItem.name
                    .toLowerCase()
                    .includes(this.filterBy.toLowerCase())) ||
                (exampleItem.profile.email &&
                  exampleItem.profile.email
                    .toLowerCase()
                    .includes(this.filterBy.toLowerCase()))
              );
            });
            break;
          case "Registered Okta Users":
            this.items = this.items.filter((exampleItem) => {
              return (
                (exampleItem.oktaUser.profile.displayName &&
                  exampleItem.oktaUser.profile.displayName
                    .toLowerCase()
                    .includes(this.filterBy.toLowerCase())) ||
                (exampleItem.oktaUser.profile.email &&
                  exampleItem.oktaUser.profile.email
                    .toLowerCase()
                    .includes(this.filterBy.toLowerCase())) ||
                (exampleItem.user.division &&
                  exampleItem.user.division
                    .toLowerCase()
                    .includes(this.filterBy.toLowerCase())) ||
                (exampleItem.user.department &&
                  exampleItem.user.department
                    .toLowerCase()
                    .includes(this.filterBy.toLowerCase()))
              );
            });
            break;
          case "Unregistered Okta Users":
            this.items = this.items.filter((exampleItem) => {
              return (
                (exampleItem.profile.displayName &&
                  exampleItem.profile.displayName
                    .toLowerCase()
                    .includes(this.filterBy.toLowerCase())) ||
                (exampleItem.profile.email &&
                  exampleItem.profile.email
                    .toLowerCase()
                    .includes(this.filterBy.toLowerCase())) ||
                (exampleItem.profile.division &&
                  exampleItem.profile.division
                    .toLowerCase()
                    .includes(this.filterBy.toLowerCase())) ||
                (exampleItem.profile.department &&
                  exampleItem.profile.department
                    .toLowerCase()
                    .includes(this.filterBy.toLowerCase()))
              );
            });
            break;
          case "Dashboard":
            this.items = this.items.filter((exampleItem) => {
              return (
                (exampleItem.fullName &&
                  exampleItem.fullName
                    .toLowerCase()
                    .includes(this.filterBy.toLowerCase())) ||
                (exampleItem.email &&
                  exampleItem.email
                    .toLowerCase()
                    .includes(this.filterBy.toLowerCase())) ||
                (exampleItem.division &&
                  exampleItem.division
                    .toLowerCase()
                    .includes(this.filterBy.toLowerCase())) ||
                (exampleItem.department &&
                  exampleItem.department
                    .toLowerCase()
                    .includes(this.filterBy.toLowerCase())) ||
                (exampleItem.manager &&
                  exampleItem.manager
                    .toLowerCase()
                    .includes(this.filterBy.toLowerCase())) ||
                (exampleItem.Account_Type &&
                  exampleItem.Account_Type.toLowerCase().includes(
                    this.filterBy.toLowerCase()
                  )) ||
                (exampleItem.Entity &&
                  exampleItem.Entity.toLowerCase().includes(
                    this.filterBy.toLowerCase()
                  ))
              );
            });
            break;
        }
        if (!this.filterBy) {
          this.items = JSON.parse(JSON.stringify(this.allExampleItems));
          this.changeSelect();
          if (this.unpassed) {
            this.showUnpassedUsers();
          }
        }
        this.ready = true;
      }, 500);
    },
    showUnpassedUsers() {
      clearTimeout(this.timeout);
      this.ready = true;
      this.timeout = setTimeout(() => {
        console.log("showUnpassedUsers", this.unpassed);
        var status = false;
        var cyberRoomStatus = false;
        var scenarioStatus = false;
        var sqlInjectionStatus = false;
        this.items = JSON.parse(JSON.stringify(this.allExampleItems));
        if (!this.unpassed) return;
        if (this.status === "Dashboard") {
          this.items = this.items.filter((exampleItems) => {
            return exampleItems.passed === "false";
          });
        } else {
          this.items = this.items.filter((exampleItem) => {
            status = false;
            cyberRoomStatus = false;
            scenarioStatus = false;
            sqlInjectionStatus = false;
            if (exampleItem.user && exampleItem.user.scores) {
              exampleItem.user.scores.forEach((game) => {
                if (status) return;
                if (game.type === "cyber-room" && game.endAt) {
                  cyberRoomStatus = true;
                }
                if (game.type === "scenario" && game.endAt) {
                  scenarioStatus = true;
                }
                if (game.type === "sql-injection" && game.endAt) {
                  sqlInjectionStatus = true;
                }
              });
            }
            if (
              (cyberRoomStatus && sqlInjectionStatus) ||
              (scenarioStatus && sqlInjectionStatus)
            ) {
              status = true;
            }
            return !status;
          });
        }

        console.log(this.items);
        this.ready = true;
      }, 200);
    },
    showUnregisteredUsers() {
      clearTimeout(this.timeout);
      this.ready = true;
      this.timeout = setTimeout(() => {
        console.log("showUnregisteredUsers", this.unregistered);
        this.items = JSON.parse(JSON.stringify(this.allExampleItems));
        if (!this.unregistered) return;
        if (this.status === "Dashboard") {
          this.items = this.items.filter((exampleItems) => {
            return !exampleItems.totalScore;
          });
        }
        console.log(this.items);
        this.ready = true;
      }, 200);
    },
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    },
    async sendReminder() {
      if (this.loggedinUser.isAdmin) {
        var res = await this.$store.dispatch({
          type: "slackValidation",
        });
        if (!res.err) {
          if (!this.slackReminderMessage) {
            this.slackReminderMessage =
              "Slack Reminder Message (https://demo.new-ledge.com)";
          }
          var message = this.slackReminderMessage;
          if (this.status === "Dashboard") {
            var emails = [];
            this.excludesEmails = [];
            await this.items.forEach((item) => {
              if (item.exclude) {
                this.excludesEmails.push(item);
              } else {
                emails.push(item.email);
              }
            });
            emails = [...emails];
            var emailsLength = emails.length;
            var loopCount = emailsLength / 50;
            var ids = [];
            var notFoundEmails = [];
            for (let i = 0; i < loopCount; i++) {
              var emailSplice = emails.splice(0, 50);
              var idsSlice = await this.$store.dispatch({
                type: "getSlackUsersIddByEmail",
                emails: emailSplice,
              });
              ids = ids.concat(idsSlice.ids);
              notFoundEmails = notFoundEmails.concat(idsSlice.notFoundEmails);
            }
            // var findUserIds = ids.filter((id) => {
            //   return !id.error;
            // });
            this.notFoundEmails = [...notFoundEmails];
            console.log(JSON.stringify(ids));
            console.log(JSON.stringify(notFoundEmails));
            console.log(JSON.stringify(this.excludesEmails));
            var findUserIds = ids;
            if (findUserIds.length) {
              this.$dialog
                .confirm(
                  `${this.excludesEmails.length} users are excluded, you're about to send a message to ${findUserIds.length} employees`,
                  {
                    loader: true,
                  }
                )
                .then(async (dialog) => {
                  var results = await this.$store.dispatch({
                    type: "sendSlackMessage",
                    channels: findUserIds,
                    text: message,
                  });
                  console.log(results);
                  dialog.close();
                  this.$message({
                    showClose: true,
                    message: `Your message has been successfully sent!`,
                    type: "success",
                    duration: 1500,
                  });
                })
                .catch(() => {});
            } else {
              this.$message({
                showClose: true,
                message: `Cant find any users in Slack`,
                type: "warning",
                duration: 1500,
              });
            }
            var csv = this.notFoundEmails;
            var status = "okta emails not found in slack";
            jsonexport(csv, function (err, csv) {
              if (err) return console.error(err);
              var hiddenElement = document.createElement("a");
              hiddenElement.href =
                "data:text/csv;charset=utf-8," + encodeURI(csv);
              hiddenElement.target = "_blank";

              //provide the name for the CSV file to be downloaded
              hiddenElement.download = status;
              hiddenElement.click();
            });
          } else if (this.status.includes("Okta")) {
            emails = [];
            this.excludesEmails = [];
            await this.items.forEach((item) => {
              if (item.exclude) {
                this.excludesEmails.push(item);
              } else if (item.oktaUser) {
                emails.push(item.oktaUser.profile.email);
              } else {
                emails.push(item.profile.email);
              }
            });
            emails = [...emails];
            emailsLength = emails.length;
            loopCount = emailsLength / 50;
            ids = [];
            notFoundEmails = [];
            for (let i = 0; i < loopCount; i++) {
              emailSplice = emails.splice(0, 50);
              idsSlice = await this.$store.dispatch({
                type: "getSlackUsersIddByEmail",
                emails: emailSplice,
              });
              ids = ids.concat(idsSlice.ids);
              notFoundEmails = notFoundEmails.concat(idsSlice.notFoundEmails);
            }
            // var findUserIds = ids.filter((id) => {
            //   return !id.error;
            // });
            this.notFoundEmails = [...notFoundEmails];
            console.log(JSON.stringify(ids));
            console.log(JSON.stringify(notFoundEmails));
            console.log(JSON.stringify(this.excludesEmails));
            findUserIds = ids;
            if (findUserIds.length) {
              this.$dialog
                .confirm(
                  `${this.excludesEmails.length} users are excluded, you're about to send a message to ${findUserIds.length} employees`,
                  {
                    loader: true,
                  }
                )
                .then(async (dialog) => {
                  var results = await this.$store.dispatch({
                    type: "sendSlackMessage",
                    channels: findUserIds,
                    text: message,
                  });
                  console.log(results);
                  dialog.close();
                  this.$message({
                    showClose: true,
                    message: `Your message has been successfully sent!`,
                    type: "success",
                    duration: 1500,
                  });
                })
                .catch(() => {});
            } else {
              this.$message({
                showClose: true,
                message: `Cant find any users in Slack`,
                type: "warning",
                duration: 1500,
              });
            }
            csv = this.notFoundEmails;
            status = "okta emails not found in slack";
            jsonexport(csv, function (err, csv) {
              if (err) return console.error(err);
              var hiddenElement = document.createElement("a");
              hiddenElement.href =
                "data:text/csv;charset=utf-8," + encodeURI(csv);
              hiddenElement.target = "_blank";

              //provide the name for the CSV file to be downloaded
              hiddenElement.download = status;
              hiddenElement.click();
            });
          } else {
            var usersIds = [];
            this.excludesEmails = [];
            this.items.forEach(async (item) => {
              if (item.exclude) {
                this.excludesEmails.push(item);
              } else if (item.slackUser) {
                usersIds.push(item.slackUser.id);
              } else {
                usersIds.push(item.id);
              }
            });
            console.log(JSON.stringify(this.excludesEmails));
            this.$dialog
              .confirm(
                `You're about to send a message to ${usersIds.length} employees`,
                {
                  loader: true,
                }
              )
              .then(async (dialog) => {
                var results = await this.$store.dispatch({
                  type: "sendSlackMessage",
                  channels: usersIds,
                  text: message,
                });
                console.log(results);
                dialog.close();
                this.$message({
                  showClose: true,
                  message: `Your message has been successfully sent!`,
                  type: "success",
                  duration: 1500,
                });
              })
              .catch(() => {});
          }
        } else {
          this.$message({
            showClose: true,
            message: `There is no connection with Slack, please contact with your manager`,
            type: "warning",
            duration: 3000,
          });
        }
        this.slackReminderMessage = null;
      } else {
        this.$message({
          showClose: true,
          message: `You didn't have the right permission`,
          type: "warning",
          duration: 1500,
        });
      }
    },
  },
  mounted() {
    // document.querySelectorAll(".pagination").forEach((pag) => {
    //   pag.classList.add("clean-list");
    // });
  },
  created() {
    this.allExampleItems = JSON.parse(JSON.stringify(this.items));
    this.ready = true;
  },
};
</script>

<style lang="scss" scoped>
$primary_color: #00f5af;
.layout {
  position: relative;
  &.card {
    min-height: 250px;
    border: 1px $primary_color solid;
    width: 100%;
    margin: 5px;
    padding: 10px;
    border-radius: 8px;
    .card-body {
      flex: 1;
      width: 100%;
      min-height: 208px;
      margin-bottom: 16px;
      & .item {
        margin-bottom: 20px;
        font-size: 18px;
      }
    }
  }
  .export-data {
    display: flex;
    flex-direction: row-reverse;
    position: absolute;
    right: 30px;
    top: 30px;
    z-index: 2;
  }
}

/deep/ {
  .el-textarea .el-textarea__inner {
    background-color: transparent;
    color: $primary_color;
    &::placeholder {
      color: $primary_color;
      text-align: center;
      font-size: 16px;
    }
  }
  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-block-start: 0;
    margin-top: 0;
    padding: 0;
    & .page-item {
      margin-top: 25px;
      list-style-type: none;
      padding: 0;
      & .page-link {
        border: $primary_color 2px solid;
        font-family: regular;
        padding: 0.5rem 1rem;
        border-radius: 5rem;
        margin: 0 0.25rem;
        transition: 0.3s;
        background: black;
        color: white;
        font-size: 1rem;
        &:hover {
          cursor: pointer;
          background: $primary_color;
          border-color: rgba(255, 255, 255, 0.25);
        }
      }
      &.active {
        & .page-link {
          background: $primary_color;
          color: black;
          border-color: transparent;
          &:hover {
            background: darken($primary_color, 10%);
          }
        }
      }
      &.disabled {
        & .page-link {
          color: #a09e9e;
          cursor: not-allowed;
        }
      }
    }
  }
  .el-input {
    display: flex;
    align-items: center;
    justify-content: center;
    & .el-input__inner {
      width: 250px;
      background-color: transparent;
      color: $primary_color;
      border: none;
      border-bottom: $primary_color 1px solid;
      text-align: center;
      border-radius: 0;
      &::placeholder {
        color: $primary_color;
        text-align: center;
      }
    }
  }

  .el-select-dropdown {
    &.is-multiple .el-select-dropdown__item.selected {
      color: $primary_color;
    }
    .el-scrollbar
      .el-select-dropdown__wrap
      .el-select-dropdown__list
      .el-select-dropdown__item
      span {
      color: $primary_color;
    }
  }

  .el-select {
    margin-bottom: 16px;
    & .el-select__tags .el-tag.el-tag--info {
      background-color: transparent;
      border-color: $primary_color;
      color: $primary_color;
      & .el-tag__close {
        color: black;
        background-color: white;
        &:hover {
          color: white;
          background-color: $primary_color;
        }
      }
    }
  }
  .icons-status .el-checkbox {
    margin: 0;
  }
  .el-checkbox {
    margin: 10px;
    & .el-checkbox__label {
      font-size: 16px;
      color: $primary_color;
    }
    & .el-checkbox__input.is-focus .el-checkbox__inner {
      border: white;
    }
  }
  .el-checkbox.is-checked {
    & .el-checkbox__input.is-checked .el-checkbox__inner {
      color: $primary_color;
      background-color: $primary_color;
      border: white;
    }
    & .el-checkbox__label {
      font-size: 16px;
      color: $primary_color;
    }
  }
}
</style>
