<template>
  <section v-if="user" class="user-details flex column center">
    <h2 class="text-center">User Profile</h2>
    <!-- <div class="btns flex center wrap">
      <button
        class="button"
        :class="{ 'is-active': this.showChangePassword }"
        @click="() => (this.showChangePassword = true)"
      >
        Change password
      </button>
      <button
        class="button"
        :class="{ 'is-active': !this.showChangePassword }"
        @click="() => (this.showChangePassword = false)"
      >
        Edit user details
      </button>
    </div> -->
    <div v-if="!showChangePassword" class="details">
      <form @submit.prevent="updateUser" class="flex column center">
        <el-input
          v-model="fullName"
          onfocus="this.placeholder = ''"
          onblur="this.placeholder = 'Nickname'"
          placeholder="Nickname"
          clearable
          suffix-icon="el-icon-edit"
        ></el-input>
        <!-- <el-input
          v-model="user.email"
          onfocus="this.placeholder = ''"
          onblur="this.placeholder = 'Email'"
          placeholder="Email"
          clearable
          suffix-icon="far fa-envelope"
        ></el-input> -->
        <el-input
          v-model="company"
          onfocus="this.placeholder = ''"
          onblur="this.placeholder = 'Company'"
          placeholder="Company"
          clearable
          suffix-icon="far fa-building"
        ></el-input>
        <!-- <el-date-picker
          v-if="loggedinUser.isAdmin"
          v-model="dates"
          type="datetimerange"
          range-separator="To"
          start-placeholder="Start date"
          end-placeholder="End date"
        >
        </el-date-picker> -->
        <el-checkbox v-if="loggedinUser.isAdmin" v-model="user.isAdmin"
          >Is Admin?</el-checkbox
        >
        <!-- <section class="upload-img flex column center">
          <p>Upload main company logo:</p>
          <template v-if="!isLoading">
            <label for="imgUploader">
              <i class="fas fa-cloud-upload-alt hover-pointer"></i>
            </label>
            <input
              type="file"
              name="img-uploader"
              id="imgUploader"
              @change="onUploadImg"
            />
          </template>
          <img
            class="loader"
            v-else
            src="https://i.pinimg.com/originals/65/ba/48/65ba488626025cff82f091336fbf94bb.gif"
            alt=""
          />
          <div v-if="compImg" class="img-list">
            <section class="imgs flex center">
              <img class="border-radius mrg5" :src="compImg" alt="img..." />
            </section>
          </div>
        </section>
        <section class="upload-img flex column center">
          <p>Upload second company logo:</p>
          <template v-if="!isLoadingSecond">
            <label for="secondImgUploader">
              <i class="fas fa-cloud-upload-alt hover-pointer"></i>
            </label>
            <input
              type="file"
              name="img-uploader"
              id="secondImgUploader"
              @change="onUploadSecondImg"
            />
          </template>
          <img
            class="loader"
            v-else
            src="https://i.pinimg.com/originals/65/ba/48/65ba488626025cff82f091336fbf94bb.gif"
            alt=""
          />
          <div v-if="secondCompImg" class="img-list">
            <section class="imgs flex center">
              <img
                class="border-radius mrg5"
                :src="secondCompImg"
                alt="img..."
              />
            </section>
          </div>
        </section> -->
        <button type="button" class="button" @click.stop="updateUser">
          Update User
        </button>
      </form>
    </div>
    <!-- <div v-else class="changePssword">
      <form @submit.prevent="changePassword" class="flex column center">
        <el-input
          v-model="newPassword"
          onfocus="this.placeholder = ''"
          onblur="this.placeholder = 'New password'"
          placeholder="New password"
          clearable
          suffix-icon="fas fa-key"
        ></el-input>
        <el-input
          v-model="replayNewPassword"
          onfocus="this.placeholder = ''"
          onblur="this.placeholder = 'Confirm new password'"
          placeholder="Confirm new password"
          clearable
          suffix-icon="fas fa-key"
        ></el-input>
        <button type="button" class="button" @click.stop="changePassword">
          Change password
        </button>
        <p v-if="err">{{ err }}</p>
      </form>
    </div> -->
  </section>
</template>

<script>
export default {
  name: "user-details",
  data() {
    return {
      user: {},
      showChangePassword: false,
      isLoading: false,
      fullName: null,
      company: null,
      compImg: null,
      isLoadingSecond: false,
      secondCompImg: null,
      newPassword: null,
      replayNewPassword: null,
      err: null,
      dates: null,
    };
  },
  methods: {
    changeLogo() {
      var someImg = document.querySelector(".company-logo img");
      if (!someImg) return;
      console.log(someImg);
      console.log(someImg.naturalWidth);
      console.log(someImg.naturalHeight);
      if (someImg.naturalWidth > someImg.naturalHeight) {
        console.log("landscape");
        // this.$parent.typeImg = 'landscape'
        //it's a landscape
      } else if (someImg.naturalWidth < someImg.naturalHeight) {
        // this.$parent.typeImg = 'portrait'
        console.log("portrait");
        someImg.style.maxWidth = "50%";
        someImg.style.maxHeight = "100%";
        //it's a portrait
      } else {
        // this.$parent.typeImg = 'square'
        console.log("squre");
        someImg.style.maxWidth = "50%";
        someImg.style.maxHeight = "100%";
        //image width and height are equal, therefore it is square.
      }
    },
    async changePassword() {
      console.log("changePassword");
      if (this.newPassword === this.replayNewPassword && this.newPassword) {
        var res = await this.$store.dispatch({
          type: "updatePassword",
          userCred: {
            _id: JSON.parse(JSON.stringify(this.user.id)),
            password: JSON.parse(JSON.stringify(this.newPassword)),
          },
        });
        if (!res.err) {
          await this.$message({
            showClose: true,
            message: `Change password sucessfully!`,
            type: "success",
            duration: 1500,
          });
          this.newPassword = null;
          this.replayNewPassword = null;
        } else {
          this.$message({
            showClose: true,
            message: `Something was wrong, please try again.`,
            type: "warning",
            duration: 1500,
          });
          return;
        }
      } else {
        this.err = "passwords not equals";
        setTimeout(() => {
          this.err = null;
        }, 5000);
      }
    },
    async updateUser() {
      if (this.dates.length) {
        this.user.startDate = new Date(this.dates[0]).getTime();
        this.user.endDate = new Date(this.dates[1]).getTime();
      }
      var done = true;
      if (this.fullName) {
        this.user.fullName = this.fullName;
        var resName = await this.$store.dispatch({
          type: "updateUser",
          userId: this.user.id,
          updateKey: "fullName",
          updateValue: this.fullName,
        });
        if (!resName.type) done = false;
      }
      if (this.company && done) {
        this.user.company = this.company;
        var resComp = await this.$store.dispatch({
          type: "updateUser",
          userId: this.user.id,
          updateKey: "company",
          updateValue: this.company,
        });
        if (!resComp.type) done = false;
      }
      if (this.compImg && done) {
        this.user.compImg = this.compImg;
        var resCompImg = await this.$store.dispatch({
          type: "updateUser",
          userId: this.user.id,
          updateKey: "compImg",
          updateValue: this.compImg,
        });
        if (!resCompImg.type) done = false;
      }
      if (this.secondCompImg && done) {
        this.user.secondCompImg = this.secondCompImg;
        var resSecondCompImg = await this.$store.dispatch({
          type: "updateUser",
          userId: this.user.id,
          updateKey: "secondCompImg",
          updateValue: this.secondCompImg,
        });
        if (!resSecondCompImg.type) done = false;
      }
      // console.log(this.user);
      // var user = JSON.parse(JSON.stringify(this.user))
      // console.log(user);
      // for (const key in user) {
      //   if(!user[key] || key === 'id' || key === 'AuthId') return
      //   console.log(key);
      //   console.log(user[key]);
      //   var res = await this.$store.dispatch({
      //     type: "updateUser",
      //     userId: user.id,
      //     updateKey: key,
      //     updateValue: user[key],
      //   });
      //   console.log(res);
      //   // if (res.err) {
      //   //   this.$message({
      //   //     showClose: true,
      //   //     message: `Something was wrong, please try again.`,
      //   //     type: "warning",
      //   //     duration: 1500,
      //   //   });
      //   //   return;
      //   // }
      // }
      if (done) {
        await this.$message({
          showClose: true,
          message: `${this.user.fullName} update sucessfully!`,
          type: "success",
          duration: 1500,
        });
        this.changeLogo();
      } else {
        this.$message({
          showClose: true,
          message: `Something was wrong, please try again.`,
          type: "warning",
          duration: 1500,
        });
        return;
      }
    },
    async onUploadImg(ev) {
      console.log("check first");
      this.isLoading = true;
      var file = ev.target.files[0];
      console.log(file);
      if (file.type && !file.type.startsWith("image/")) {
        console.log("File is not an image.", file.type, file);
        return;
      }
      const reader = new FileReader();
      reader.addEventListener("load", (event) => {
        console.log(event.target.result);
        this.compImg = event.target.result;
        // img.src = event.target.result;
      });
      reader.readAsDataURL(file);
      this.isLoading = false;
    },
    async onUploadSecondImg(ev) {
      console.log("check second");
      this.isLoadingSecond = true;
      var file = ev.target.files[0];
      console.log(file);
      if (file.type && !file.type.startsWith("image/")) {
        console.log("File is not an image.", file.type, file);
        return;
      }
      const reader = new FileReader();
      reader.addEventListener("load", (event) => {
        console.log(event.target.result);
        this.secondCompImg = event.target.result;
        // img.src = event.target.result;
      });
      reader.readAsDataURL(file);
      this.isLoadingSecond = false;
    },
  },
  computed: {
    loggedinUser() {
      return this.$store.getters.loggedinUser;
    },
  },
  async created() {
    if (!this.loggedinUser) {
      this.$message({
        showClose: true,
        message: `Login first!`,
        type: "warning",
        duration: 1500,
      });
      window.location =
        "https://demo-newledge.auth.us-east-2.amazoncognito.com/login?client_id=3ijg2b3k5i7epp88779v9l7p1q&response_type=token&scope=phone+email+openid+aws.cognito.signin.user.admin+profile&redirect_uri=https://demo.new-ledge.com/";
      return;
    }
    const _id = this.$route.params.userId;
    if(_id != this.loggedinUser.AuthId && !this.loggedinUser.isAdmin) {
      this.$message({
        showClose: true,
        message: `Not allowed!`,
        type: "warning",
        duration: 1500,
      });
      this.$router.back();
      return;
    }
    var user = await this.$store.dispatch({
      type: "getUserById",
      _id,
    });
    if (user.err) {
      this.$router.back();
      return;
    }
    this.user = JSON.parse(JSON.stringify(user));
    this.compImg = user.compImg;
    this.secondCompImg = user.secondCompImg;
    this.dates = [user.startDate, user.endDate];
    this.fullName = this.user.fullName;
    this.company = this.user.company;
  },
};
</script>

<style lang="scss" scoped>
$primary_color: #00f5af;
/deep/ {
  .el-range-editor.el-input__inner {
    border: none;
    margin-left: 57px;
    &.el-date-editor .el-range__icon {
      color: #878787;
      position: absolute;
      right: 28px;
      font-size: 19px;
    }
    & .el-range-input {
      font-family: regular;
      border: none;
      background-color: transparent;
      border-bottom: 1px solid white;
      margin-bottom: 0.4375rem;
      border-radius: 0px;
      color: white;
      &::placeholder {
        color: white;
      }
    }
  }
}
.img-list img {
  width: 100px;
}
.upload-img {
  & i {
    font-size: 40px;
    color: #00f5af;
  }
}

#imgUploader {
  display: none;
}
#secondImgUploader {
  display: none;
}

form {
  max-width: 250px;
}
.btns {
  margin-bottom: 20px;
}
.button {
  border: $primary_color 2px solid;
  font-family: regular;
  padding: 0.5rem 1rem;
  border-radius: 5rem;
  margin: 0 0.25rem;
  transition: 0.3s;
  background: black;
  color: white;
  font-size: 1rem;
  &:hover {
    cursor: pointer;
    background: $primary_color;
    border-color: rgba(255, 255, 255, 0.25);
  }
  &.is-active {
    background: $primary_color;
    color: black;
    border-color: transparent;

    &:hover {
      background: darken($primary_color, 10%);
    }
  }
}
</style>