<template>
  <div class="daily-quiz flex column center">
    <quizGame :quizNew="videoQuiz" :gameId="gameId" twinGame="first-video-quiz"></quizGame>
    <section class="btns flex center mt16">
      <button
        class="button to-profile-btn mrg5"
        @click="() => this.$router.push('/')"
      >
        Go back to Profile
      </button>
      <!-- <button
        v-if="nextGame"
        class="button to-next-game-btn mrg5"
        @click="() => this.$router.push(`/${route}`)"
      >
        {{ nextGame }}
      </button> -->
    </section>
  </div>
</template>

<script>
import quizGame from "@/cmp/quiz-game";

export default {
  name: "daily-quiz-1",
  data() {
    return {
      videoQuiz: {
        questions: [
          {
            id: "daily-quiz-1.1",
            text: "'?איזו מהתשובות הבאות מתארות את הכלל הבסיסי לבחירת סיסמה: 'קלה לזכירה, אך קשה לפיצוח",
            responses: [
              {
                text: "MyComputer2022!",
                correct: true,
              },
              { text: "a12345" },
              { text: "Password2021" },
              { text: "Abc123" },
            ],
            message: null,
          },
          {
            id: "daily-quiz-1.2",
            text: "?האקרים חודרים למחשב ומצפינים את המידע והתיקיות ומשחררים אותם בתמורה לתשלום. התקפה זו נקראת",
            responses: [
              { text: "Crypto" },
              {
                text: "Ransomware",
                correct: true,
              },
              { text: "Vmware" },
              { text: "Moneylocked" },
            ],
            message: null,
          },
          {
            id: "daily-quiz-1.3",
            text: "?לבין אתר רגיל https:// מה ההבדל בין אתר המתחיל בכתובת",
            responses: [
              {
                text: "המידע המועבר באתר הוא מוצפן",
                correct: true,
              },
              { text: "האתר עומד בתקן אבטחת טכנולוגיית HTMLS" },
              { text: "האתר עומד בכללי הנגישות" },
              { text: "אתר ברזולוציה גבוהה" },
            ],
            message: null,
          },
        ],
      },
      gameId: null,
      nextGame: null,
      route: null,
    };
  },
  components: {
    quizGame,
  },
  computed: {
    loggedinUser() {
      return this.$store.getters.loggedinUser;
    },
  },
  mounted() {
    this.$parent.changeLogo();
  },
  async created() {
    this.gameId = await this.$store.dispatch({
      type: "startGame",
      id: this.loggedinUser.id,
      Type: this.$route.path.substring(1),
    });
    // var res = await this.$store.dispatch({
    //   type: "getNextGame",
    //   currGame: this.$route.path.substring(1),
    // });
    // if (!res.err) {
    //   switch (res) {
    //     case "cyber-room":
    //       this.nextGame = "Virtual Escape Room";
    //       break;
    //     case "scenario":
    //       this.nextGame = "Cyber Security Training";
    //       break;
    //     case "first-video-quiz":
    //       this.nextGame = "First video & short quiz";
    //       break;
    //     case "second-video-quiz":
    //       this.nextGame = "Second video & short quiz";
    //       break;
    //     default:
    //       this.nextGame = res;
    //       break;
    //   }
    //   this.route = res;
    // }
  },
};
</script>

<style lang="scss" scoped>
$primary_color: #00f5af;

.daily-quiz {
  position: relative;
  width: 100%;
  height: 100%;
  .hint {
    position: absolute;
    top: 33px;
    color: black;
  }
  .button {
    border: $primary_color 2px solid;
    font-family: regular;
    padding: 0.5rem 1rem;
    border-radius: 5rem;
    margin: 0 0.25rem;
    transition: 0.3s;
    background: black;
    color: white;
    font-size: 1rem;
    &:hover {
      cursor: pointer;
      background: $primary_color;
      border-color: rgba(255, 255, 255, 0.25);
    }
    &.is-active {
      background: $primary_color;
      color: black;
      border-color: transparent;

      &:hover {
        background: darken($primary_color, 10%);
      }
    }
  }
}
.vjs-custom-skin {
  height: 100% !important;
}

.vjs-custom-skin /deep/ .video-js {
  height: 100%;
  width: 100%;
  .vjs-big-play-button {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
