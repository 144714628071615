<template>
  <div class="minHeight-100">
    <main v-if="ready" class="profile flex column center">
      <!-- <h2>Home Page</h2> -->
      <div
        class="modal"
        :style="{
          position: 'absolute',
          top: `${modaltop}`,
          left: `${modalleft}`,
        }"
        v-show="showModal"
        ref="modal"
      >
        Just For Checking...
      </div>
      <div v-if="loggedinUser" class="details-user flex column center">
        <div
          class="avatar-img avatar mr10 mb10"
          :style="{
            backgroundImage:
              'url(' +
              require(`@/assets/img/avatars/${loggedinUser.img}`) +
              ')',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          }"
        >
          <i
            @click="showAvatars = !showAvatars"
            class="far fa-edit change-icon hover-pointer"
          ></i>
        </div>
        <div v-if="showAvatars" class="avatars flex center wrap">
          <div
            v-for="index in 14"
            :key="index"
            @click="(ev) => changeAvatar(ev)"
            class="avatar-img mr10 mb10 hover-pointer"
            :style="{
              backgroundImage:
                'url(' +
                require(`@/assets/img/avatars/shutterstock_1575446116_${index}.png`) +
                ')',
              backgroundPosition: 'center',
              backgroundSize: 'cover',
            }"
          />
        </div>
        <h3 v-if="loggedinUser && loggedinUser.fullName" class="mr16">
          Hi {{ loggedinUser.fullName }}
        </h3>
        <div
          v-if="loggedinUser.totalScore && loggedinUser.totalScore > 0"
          class="text-container"
        >
          <h3 class="mr16">
            Your score is {{ loggedinUser.totalScore.toFixed(0) }}
          </h3>

          <i
            @mouseover="(ev) => addClickEvent(ev)"
            @mouseleave="(ev) => addClickEvent(ev)"
            class="hint far fa-question-circle"
          ></i>
        </div>
        <div
          class="text text-center modal"
          :style="{
            position: 'absolute',
            top: `${modaltop}`,
            left: `${modalleft}`,
            width: `${modalWidth}px`,
          }"
          v-if="activeText"
        >
          This score is calculated from the first time you complete each task
        </div>
      </div>
      <h3 v-else>Hi guest, please login first</h3>
      <div class="sub-title-container text-center">
        <p class="sub-title1">Are you ready for the challenge?</p>
        <!--<p class="sub-title2">
          Complete the Secure Development & one 3D game of your choice (Cyber
          Security Training / Virtual Escape Room)
        </p> -->
        <!-- <p>Complete the 3D games to get the highest score and win cool prizes!</p> -->
      </div>

      <div class="cells flex center wrap text-center">
        <div class="scenario flex column center mrg5">
          <div
            @click="toPage('/scenario')"
            class="cell cell-scenario"
            @mouseover="(ev) => addClickEvent(ev)"
            @mouseleave="(ev) => addClickEvent(ev)"
            @mousemove="(ev) => addClickEvent(ev)"
          ></div>
          <div
            class="cell-text flex column center"
            @click="toPage('/scenario')"
          >
            <p>Protect your data</p>
            <p>
              play to keep
              <!-- {{
                loggedinUser
                  ? loggedinUser.company
                    ? loggedinUser.company.replace(/\w\S*/g, (w) =>
                        w.replace(/^\w/, (c) => c.toUpperCase())
                      )
                    : "company"
                  : "company"
              }} -->
              Company safe
            </p>
            <!-- <p>Cyber Security Training</p> -->
          </div>
        </div>
        <div class="cyber-room flex column center mrg5">
          <div
            @click="toPage('/cyber-room')"
            class="cell cell-cyber-room"
            @mouseover="(ev) => addClickEvent(ev)"
            @mouseleave="(ev) => addClickEvent(ev)"
            @mousemove="(ev) => addClickEvent(ev)"
          ></div>
          <div
            class="cell-text flex column center"
            @click="toPage('/cyber-room')"
          >
            <p>It's the little things you do</p>
            <p>enter the Virtual Escape Room</p>
            <p>and solve the challenges</p>
            <!-- <p>Virtual Escape Room</p> -->
          </div>
        </div>
        <div 
          v-if="loggedinUser && loggedinUser.isAdmin"
        class="cyber-bank flex column center mrg5">
          <div
            @click="toPage('/cyber-bank')"
            class="cell cell-cyber-bank"
            @mouseover="(ev) => addClickEvent(ev)"
            @mouseleave="(ev) => addClickEvent(ev)"
            @mousemove="(ev) => addClickEvent(ev)"
          ></div>
          <div
            class="cell-text flex column center"
            @click="toPage('/cyber-bank')"
          >
            <p>** UNDER CONSTRUCTION **</p>
            <p>enter the Virtual Bank</p>
            <p>and solve the challenges</p>
            <!-- <p>Virtual Escape Room</p> -->
          </div>
        </div>
        <div
          v-if="loggedinUser && loggedinUser.isAdmin"
          class="scenario-mobile flex column center mrg5"
        >
          <div
            @click="toPage('/scenario-mobile')"
            class="cell cell-scenario-mobile"
            @mouseover="(ev) => addClickEvent(ev)"
            @mouseleave="(ev) => addClickEvent(ev)"
            @mousemove="(ev) => addClickEvent(ev)"
          ></div>
          <div
            class="cell-text flex column center"
            @click="toPage('/scenario-mobile')"
          >
            <p>Mobile scenario</p>
            <p>View in landscape</p>
          </div>
        </div>
        <div
          v-if="loggedinUser && loggedinUser.isAdmin"
          class="cyber-room-mobile flex column center mrg5"
        >
          <div
            @click="toPage('/cyber-room-mobile')"
            class="cell cell-cyber-room-mobile"
            @mouseover="(ev) => addClickEvent(ev)"
            @mouseleave="(ev) => addClickEvent(ev)"
            @mousemove="(ev) => addClickEvent(ev)"
          ></div>
          <div
            class="cell-text flex column center"
            @click="toPage('/cyber-room-mobile')"
          >
            <p>Mobile escape room</p>
            <p>View in landscape</p>
          </div>
        </div>
        <div
          v-if="loggedinUser && loggedinUser.isAdmin"
          class="cyber-bank-mobile flex column center mrg5"
        >
          <div
            @click="toPage('/cyber-bank-mobile')"
            class="cell cell-cyber-bank-mobile"
            @mouseover="(ev) => addClickEvent(ev)"
            @mouseleave="(ev) => addClickEvent(ev)"
            @mousemove="(ev) => addClickEvent(ev)"
          ></div>
          <div
            class="cell-text flex column center"
            @click="toPage('/cyber-bank-mobile')"
          >
            <p>Mobile escape bank</p>
            <p>View in landscape</p>
          </div>
        </div>
        <div
          v-if="loggedinUser && loggedinUser.isAdmin"
          class="sql-injection-mobile flex column center mrg5"
        >
          <div
            @click="toPage('/sql-injection-mobile')"
            class="cell cell-sql-injection-mobile"
            @mouseover="(ev) => addClickEvent(ev)"
            @mouseleave="(ev) => addClickEvent(ev)"
            @mousemove="(ev) => addClickEvent(ev)"
          ></div>
          <div
            class="cell-text flex column center"
            @click="toPage('/sql-injection-mobile')"
          >
            <p>Mobile sql-injection</p>
            <p>View in landscape</p>
          </div>
        </div>
        <div
          v-if="loggedinUser && loggedinUser.isAdmin"
          class="long-quiz-mobile flex column center mrg5"
        >
          <div
            @click="toPage('/long-quiz-mobile')"
            class="cell cell-long-quiz-mobile"
            @mouseover="(ev) => addClickEvent(ev)"
            @mouseleave="(ev) => addClickEvent(ev)"
            @mousemove="(ev) => addClickEvent(ev)"
          ></div>
          <div
            class="cell-text flex column center"
            @click="toPage('/long-quiz-mobile')"
          >
            <p>Mobile Summary Quiz</p>
            <p>View in landscape</p>
          </div>
        </div>
        <div
          v-if="loggedinUser && loggedinUser.isAdmin"
          class="long-quiz flex column center mrg5"
        >
          <div class="status flex align-center width-100">
            <p class="done" v-if="longQuizCounter">סיימתי</p>
            <p class="inprogress" v-else-if="longQuizNotFinishedCounter">
              התחלתי
            </p>
            <!-- <div class="done flex column center">
              <p>Done</p>
              <i
                v-if="longQuizCounter"
                class="far fa-check-circle"
                style="color: green"
              ></i>
              <i v-else class="fas fa-times-circle" style="color: red"></i>
            </div>
            <div class="progress flex column center" v-if="!longQuizCounter">
              <p>In Progress</p>
              <i
                v-if="longQuizNotFinishedCounter"
                class="far fa-check-circle"
                style="color: green"
              ></i>
              <i v-else class="fas fa-times-circle" style="color: red"></i>
            </div> -->
          </div>
          <div
            @click="toPage('/long-quiz')"
            class="cell cell-long-quiz"
            @mouseover="(ev) => addClickEvent(ev)"
            @mouseleave="(ev) => addClickEvent(ev)"
            @mousemove="(ev) => addClickEvent(ev)"
          ></div>
          <div
            class="cell-text flex column center"
            @click="toPage('/long-quiz')"
          >
            <p>Summary Quiz</p>
          </div>
        </div>
        <div
          v-if="loggedinUser && loggedinUser.isAdmin"
          class="sql-injection flex column center mrg5"
        >
          <div class="status flex align-center width-100">
            <p class="done" v-if="sqlInjectionCounter">סיימתי</p>
            <p class="inprogress" v-else-if="sqlInjectionNotFinishedCounter">
              התחלתי
            </p>
            <!-- <div class="done flex column center">
              <p>Done</p>
              <i
                v-if="sqlInjectionCounter"
                class="far fa-check-circle"
                style="color: green"
              ></i>
              <i v-else class="fas fa-times-circle" style="color: red"></i>
            </div>
            <div class="progress flex column center" v-if="!sqlInjectionCounter">
              <p>In Progress</p>
              <i
                v-if="sqlInjectionNotFinishedCounter"
                class="far fa-check-circle"
                style="color: green"
              ></i>
              <i v-else class="fas fa-times-circle" style="color: red"></i>
            </div> -->
          </div>
          <div
            @click="toPage('/sql-injection')"
            class="cell cell-sql-injection"
            @mouseover="(ev) => addClickEvent(ev)"
            @mouseleave="(ev) => addClickEvent(ev)"
            @mousemove="(ev) => addClickEvent(ev)"
          ></div>
          <div
            class="cell-text flex column center"
            @click="toPage('/sql-injection')"
          >
            <p>Work in a secure mode and</p>
            <p>protect your code</p>
            <p>(Application Security for R&D)</p>
          </div>
        </div>
        <div
          v-if="loggedinUser && loggedinUser.isAdmin"
          class="cyber-room heb flex column center mrg5"
        >
          <div class="status flex align-center width-100">
            <p class="done" v-if="cyberRoomCounter">סיימתי</p>
            <p class="inprogress" v-else-if="cyberRoomNotFinishedCounter">
              התחלתי
            </p>
            <!-- <div class="done flex column center">
              <p>Done</p>
              <i
                v-if="cyberRoomCounter"
                class="far fa-check-circle"
                style="color: green"
              ></i>
              <i v-else class="fas fa-times-circle" style="color: red"></i>
            </div>
            <div class="progress flex column center" v-if="!cyberRoomCounter">
              <p>In Progress</p>
              <i
                v-if="cyberRoomNotFinishedCounter"
                class="far fa-check-circle"
                style="color: green"
              ></i>
              <i v-else class="fas fa-times-circle" style="color: red"></i>
            </div> -->
          </div>
          <div
            @click="toPage('/cyber-room/heb')"
            class="cell cell-cyber-room"
            @mouseover="(ev) => addClickEvent(ev)"
            @mouseleave="(ev) => addClickEvent(ev)"
            @mousemove="(ev) => addClickEvent(ev)"
          ></div>
          <div
            class="cell-text flex column center"
            @click="toPage('/cyber-room/heb')"
          >
            <p>It's the little things you do</p>
            <p>enter the Virtual Escape Room</p>
            <p>and solve the challenges</p>
            <p>Hebrew</p>
            <!-- <p>Virtual Escape Room</p> -->
          </div>
        </div>
        <div
          v-if="loggedinUser && loggedinUser.isAdmin"
          class="scenario heb flex column center mrg5"
        >
          <div class="status flex align-center space-around width-100">
            <p class="done" v-if="scenarioCounter">סיימתי</p>
            <p class="inprogress" v-else-if="scenarioNotFinishedCounter">
              התחלתי
            </p>
            <!-- <div class="done flex column center">
              <i
                v-if="scenarioCounter"
                class="far fa-check-circle"
                style="color: green"
              ></i>
              <i v-else class="fas fa-times-circle" style="color: red"></i>
            </div>
            <div
              class="progress flex column center"
              v-if="!scenarioCounter"
            >
              <p>In Progress</p>
              <i
                v-if="scenarioNotFinishedCounter"
                class="far fa-check-circle"
                style="color: green"
              ></i>
              <i v-else class="fas fa-times-circle" style="color: red"></i>
            </div> -->
          </div>
          <div
            @click="toPage('/scenario/heb')"
            class="cell cell-scenario"
            @mouseover="(ev) => addClickEvent(ev)"
            @mouseleave="(ev) => addClickEvent(ev)"
            @mousemove="(ev) => addClickEvent(ev)"
          ></div>
          <div
            class="cell-text flex column center"
            @click="toPage('/scenario/heb')"
          >
            <p>Protect your data</p>
            <p>
              play to keep
              <!-- {{
                loggedinUser
                  ? loggedinUser.company
                    ? loggedinUser.company.replace(/\w\S*/g, (w) =>
                        w.replace(/^\w/, (c) => c.toUpperCase())
                      )
                    : "company"
                  : "company"
              }} -->
              Company safe
            </p>
            <p>Hebrew</p>
            <!-- <p>Cyber Security Training</p> -->
          </div>
        </div>
        <div
          v-if="loggedinUser && loggedinUser.isAdmin"
          class="first-video-quiz flex column center mrg5"
        >
          <div class="status flex align-center space-around width-100">
            <p class="done" v-if="firstVideoQuizCounter">סיימתי</p>
            <p class="inprogress" v-else-if="firstVideoQuizNotFinishedCounter">
              התחלתי
            </p>
            <!-- <div class="done flex column center">
              <p>Done</p>
              <i
                v-if="firstVideoQuizCounter"
                class="far fa-check-circle"
                style="color: green"
              ></i>
              <i v-else class="fas fa-times-circle" style="color: red"></i>
            </div>
            <div
              class="progress flex column center"
              v-if="!firstVideoQuizCounter"
            >
              <p>In Progress</p>
              <i
                v-if="firstVideoQuizNotFinishedCounter"
                class="far fa-check-circle"
                style="color: green"
              ></i>
              <i v-else class="fas fa-times-circle" style="color: red"></i>
            </div> -->
          </div>
          <div
            @click="toPage('/first-video-quiz')"
            class="cell cell-first-video-quiz"
            @mouseover="(ev) => addClickEvent(ev)"
            @mouseleave="(ev) => addClickEvent(ev)"
            @mousemove="(ev) => addClickEvent(ev)"
          ></div>
          <div class="cell-text flex column center">
            <p>Daily challenge</p>
            <p>ready for the video challenge?</p>
          </div>
        </div>
        <div
          v-if="loggedinUser && loggedinUser.isAdmin"
          class="daily-quiz-1 flex column center mrg5"
        >
          <div class="status flex align-center space-around width-100">
            <p class="done" v-if="dailyQuiz1Counter">סיימתי</p>
            <p class="inprogress" v-else-if="dailyQuiz1NotFinishedCounter">
              התחלתי
            </p>
            <!-- <div class="done flex column center">
              <p>Done</p>
              <i
                v-if="dailyQuiz1Counter"
                class="far fa-check-circle"
                style="color: green"
              ></i>
              <i v-else class="fas fa-times-circle" style="color: red"></i>
            </div>
            <div class="progress flex column center" v-if="!dailyQuiz1Counter">
              <p>In Progress</p>
              <i
                v-if="dailyQuiz1NotFinishedCounter"
                class="far fa-check-circle"
                style="color: green"
              ></i>
              <i v-else class="fas fa-times-circle" style="color: red"></i>
            </div> -->
          </div>
          <div
            @click="toPage('/daily-quiz-1')"
            class="cell cell-daily-quiz-1"
            @mouseover="(ev) => addClickEvent(ev)"
            @mouseleave="(ev) => addClickEvent(ev)"
            @mousemove="(ev) => addClickEvent(ev)"
          ></div>
          <div
            class="cell-text flex column center"
            @click="toPage('/daily-quiz-1')"
          >
            <p>Daily quiz</p>
            <p>answer correctly and quickly</p>
            <p>gain more</p>
          </div>
        </div>
        <div
          v-if="loggedinUser && loggedinUser.isAdmin"
          class="dev flex column center mrg5"
        >
          <div
            @click="toPage('/dev')"
            class="cell cell-dev"
            @mouseover="(ev) => addClickEvent(ev)"
            @mouseleave="(ev) => addClickEvent(ev)"
            @mousemove="(ev) => addClickEvent(ev)"
          ></div>
          <div class="cell-text flex column center" @click="toPage('/dev')">
            <p>Development Enviroment</p>
          </div>
        </div>
        <!-- <div
          v-if="loggedinUser && loggedinUser.isAdmin"
          class="navigator flex column center mrg5"
        >
          <div @click="toPage('/navigator')" class="cell cell-navigator"></div>
          <div
            class="cell-text flex column center"
            @click="toPage('/navigator')"
          >
            <p>Navigator</p>
          </div>
        </div> -->
      </div>
      <div
        v-if="loggedinUser && loggedinUser.isAdmin"
        class="hero-text text-center"
      >
        <h2>
          You don't need to be a superhero, to keep
          <!-- {{
          loggedinUser
            ? loggedinUser.company
              ? loggedinUser.company.replace(/\w\S*/g, (w) =>
                  w.replace(/^\w/, (c) => c.toUpperCase())
                )
              : "company"
            : "company"
        }} -->

          Company safe
        </h2>
        <!-- <h2>Train Your Team Effectively</h2> -->
      </div>
    </main>
    <div v-else class="loading-container flex column center height-100">
      <img :src="require(`@/assets/img/loading.gif`)" alt="" srcset="" />
      <p>Loading...</p>
    </div>
  </div>
</template>
 
<script>
import NetworkSpeed from "network-speed";
import Axios from "axios";
var axios = Axios.create({
  withCredentials: true,
});
import { utilService } from "@/service/utils/util.service.js";

export default {
  name: "profile",
  data() {
    return {
      signupCred: {},
      showAvatars: false,
      downloadSpeed: null,
      avg: null,
      baseUrl:
        "https://nir-webgl.s3.eu-central-1.amazonaws.com/CS_ENG/videos/1080/",
      assets: [
        "Background_Coffee_Machine.mp4",
        "Background_London_4K.mp4",
        "Background_Office.mp4",
        "Background_Sci - fi_Center.mp4",
        "Background_Sci - fi_Center3.mp4",
        "Background_Sci - fi.mp4",
        "Laptop_960.mp4",
        "Opening_Background.mp4",
        "Quiz_Background.mp4",
        "Tunnel_Background.mp4",
      ],
      showModal: false,
      activeText: false,
      modaltop: 0,
      modalleft: 0,
      ready: false,
      cyberRoomCounter: 0,
      cyberRoomNotFinishedCounter: 0,
      scenarioCounter: 0,
      scenarioNotFinishedCounter: 0,
      longQuizCounter: 0,
      longQuizNotFinishedCounter: 0,
      firstVideoQuizCounter: 0,
      firstVideoQuizNotFinishedCounter: 0,
      secondVideoQuizCounter: 0,
      secondVideoQuizNotFinishedCounter: 0,
      dailyQuiz1Counter: 0,
      dailyQuiz1NotFinishedCounter: 0,
      dailyQuiz2Counter: 0,
      dailyQuiz2NotFinishedCounter: 0,
      dailyQuiz3Counter: 0,
      dailyQuiz3NotFinishedCounter: 0,
      dailyQuiz4Counter: 0,
      dailyQuiz4NotFinishedCounter: 0,
      dailyQuiz5Counter: 0,
      dailyQuiz5NotFinishedCounter: 0,
      sqlInjectionCounter: 0,
      sqlInjectionNotFinishedCounter: 0,
    };
  },
  computed: {
    loggedinUser() {
      return this.$store.getters.loggedinUser;
    },
  },
  methods: {
    async getNetworkDownloadSpeed() {
      var speeds = [];
      for (let i = 0; i < 30; i++) {
        // const baseUrl = "https://eu.httpbin.org/stream-bytes/500000";
        const baseUrl =
          "https://graylinegroup.com/wp-content/uploads/2018/08/Give.jpg";
        //  const baseUrl = "https://cybertraining.amdocs.com/image/unity-logo-light.png";
        const fileSizeInBytes = 22000;
        // const fileSizeInBytes = 500000;
        const testNetworkSpeed = new NetworkSpeed();
        const speed = await testNetworkSpeed.checkDownloadSpeed(
          baseUrl,
          fileSizeInBytes
        );
        speeds.push(speed.mbps);
      }
      var sum = 0;
      speeds.forEach((speed) => {
        sum += +speed;
      });
      var avg = sum / speeds.length;
      this.avg = +avg.toFixed(2);
      console.log(this.avg);
    },
    toPage(path) {
      this.$router.push(path);
    },
    async changeAvatar(ev) {
      this.showAvatars = false;
      console.log(ev.target.style.backgroundImage.split("_")[2].split(".")[0]);
      var numImg = ev.target.style.backgroundImage.split("_")[2].split(".")[0];
      var img = `shutterstock_1575446116_${numImg}.png`;
      var user = JSON.parse(JSON.stringify(this.loggedinUser));
      user.img = img;
      var res = await this.$store.dispatch({
        type: "updateUser",
        userId: user.id,
        updateKey: "img",
        updateValue: user.img,
      });
      console.log(res);
    },
    async downloadCache(endpoint) {
      axios
        .get(`${this.baseUrl}${endpoint}`, {
          headers: {
            mode: "no-cors",
          },
        })
        .then(function (response) {
          console.log("response is : " + response.data);
        })
        .catch(function (error) {
          if (error.response) {
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log(error.message);
          }
          console.log(error.config);
        });
    },
    addClickEvent(e) {
      setTimeout(() => {
        if (!this.$refs["modal"]) return;
        var target = e.target.parentElement.className.split(" ")[0];
        if (target === "cyber-room") {
          if (e.target.parentElement.className.split(" ").includes("heb")) {
            this.$refs["modal"].innerText = "Virtual Escape Room - Hebrew";
          } else {
            this.$refs["modal"].innerText = "Virtual Escape Room";
          }
        } else if (target === "scenario") {
          if (e.target.parentElement.className.split(" ").includes("heb")) {
            this.$refs["modal"].innerText = "Cyber Security Training - Hebrew";
          } else {
            this.$refs["modal"].innerText = "Cyber Security Training";
          }
        } else if (target === "sql-injection")
          this.$refs["modal"].innerText = "Secure Development";
        else if (target === "long-quiz")
          this.$refs["modal"].innerText = "Summary Quiz";
        else if (target === "first-video-quiz")
          this.$refs["modal"].innerText = "First video & short quiz";
        else if (target === "second-video-quiz")
          this.$refs["modal"].innerText = "Second video & short quiz";
        else if (target === "story") this.$refs["modal"].innerText = "Story";
        else if (target === "daily-quiz-1")
          this.$refs["modal"].innerText = "daily-quiz-1";
        else if (target === "daily-quiz-2")
          this.$refs["modal"].innerText = "daily-quiz-2";
        else if (target === "daily-quiz-3")
          this.$refs["modal"].innerText = "daily-quiz-3";
        else if (target === "daily-quiz-4")
          this.$refs["modal"].innerText = "daily-quiz-4";
        else if (target === "daily-quiz-5")
          this.$refs["modal"].innerText = "daily-quiz-5";
        else if (target === "dev")
          this.$refs["modal"].innerText = "Development Enviroment";
        if (e.type === "mousemove") {
          this.modalleft = e.pageX + 0 + "px";
          this.modaltop = e.pageY - 30 + "px";
          // if(target === 'text-container') {
          //   this.modalleft = e.clientX + 10 + "px";
          //   this.modaltop = e.clientY - 100 + "px";
          // }
        } else if (e.type === "mouseenter") {
          this.showModal = true;
        } else if (e.type === "mouseleave") {
          this.showModal = false;
        }
        if (e.type === "mouseover") {
          if (this.activeText) {
            setTimeout(() => {
              this.activeText = false;
            }, 5000);
          } else {
            if (
              /Android|webOS|iPhone|iPad|Mac|Macintosh|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
              )
            ) {
              this.modalWidth = 140;
              this.modalleft = e.clientX - 50 + "px";
              this.modaltop = e.clientY - 140 + "px";
            } else {
              this.modalWidth = 200;
              this.modalleft = e.clientX + 5 + "px";
              this.modaltop = e.clientY - 100 + "px";
            }
            if (target === "text-container") {
              this.activeText = true;
            } else {
              this.showModal = true;
            }
          }
        } else if (e.type === "mouseleave") {
          setTimeout(() => {
            this.activeText = false;
          }, 5000);
          this.showModal = false;
        }
      }, 10);
    },
    parseJwt(token) {
      var base64Url = token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      return JSON.parse(jsonPayload);
    },
    gamesPerformance() {
      if (!this.loggedinUser.scores || !this.loggedinUser.scores.length) return;
      this.loggedinUser.scores.forEach((game) => {
        if (!game.tasks) return;
        if (game.endAt && game.type === "cyber-room") this.cyberRoomCounter++;
        else if (game.type === "cyber-room") this.cyberRoomNotFinishedCounter++;
        if (game.endAt && game.type === "scenario") this.scenarioCounter++;
        else if (game.type === "scenario") this.scenarioNotFinishedCounter++;
        if (game.endAt && game.type === "sql-injection")
          this.sqlInjectionCounter++;
        else if (game.type === "sql-injection")
          this.sqlInjectionNotFinishedCounter++;
        if (game.endAt && game.type === "long-Quiz") this.longQuizCounter++;
        else if (game.type === "long-Quiz") this.longQuizNotFinishedCounter++;
        if (game.endAt && game.type === "first-video-quiz")
          this.firstVideoQuizCounter++;
        else if (game.type === "first-video-quiz")
          this.firstVideoQuizNotFinishedCounter++;
        if (game.endAt && game.type === "second-video-quiz")
          this.secondVideoQuizCounter++;
        else if (game.type === "second-video-quiz")
          this.secondVideoQuizNotFinishedCounter++;
        if (game.endAt && game.type === "daily-quiz-1")
          this.dailyQuiz1Counter++;
        else if (game.type === "daily-quiz-1")
          this.dailyQuiz1NotFinishedCounter++;
        if (game.endAt && game.type === "daily-quiz-2")
          this.dailyQuiz2Counter++;
        else if (game.type === "daily-quiz-2")
          this.dailyQuiz2NotFinishedCounter++;
        if (game.endAt && game.type === "daily-quiz-3")
          this.dailyQuiz3Counter++;
        else if (game.type === "daily-quiz-3")
          this.dailyQuiz3NotFinishedCounter++;
        if (game.endAt && game.type === "daily-quiz-4")
          this.dailyQuiz4Counter++;
        else if (game.type === "daily-quiz-4")
          this.dailyQuiz4NotFinishedCounter++;
        if (game.endAt && game.type === "daily-quiz-5")
          this.dailyQuiz5Counter++;
        else if (game.type === "daily-quiz-5")
          this.dailyQuiz5NotFinishedCounter++;
      });
    },
  },
  // mounted() {
  //   var someImg = document.querySelector(".company-logo img");
  //   if (!someImg) return;
  //   console.log(someImg);
  //   console.log(someImg.naturalWidth);
  //   console.log(someImg.naturalHeight);
  //   if (someImg.naturalWidth > someImg.naturalHeight) {
  //     console.log("landscape");
  //     // this.$parent.typeImg = 'landscape'
  //     //it's a landscape
  //   } else if (someImg.naturalWidth < someImg.naturalHeight) {
  //     // this.$parent.typeImg = 'portrait'
  //     console.log("portrait");
  //     someImg.style.maxWidth = "50%";
  //     someImg.style.maxHeight = "100%";
  //     //it's a portrait
  //   } else {
  //     // this.$parent.typeImg = 'square'
  //     console.log("squre");
  //     someImg.style.maxWidth = "50%";
  //     someImg.style.maxHeight = "100%";
  //     //image width and height are equal, therefore it is square.
  //   }
  // },
  async created() {
    if (!this.loggedinUser) {
      this.$parent.changeLogo();
      const token = this.$route.params.idToken;
      if (!token) {
        window.location =
          "https://demo-newledge.auth.us-east-2.amazoncognito.com/login?client_id=3ijg2b3k5i7epp88779v9l7p1q&response_type=token&scope=phone+email+openid+aws.cognito.signin.user.admin+profile&redirect_uri=https://demo.new-ledge.com/";
        // window.location =
        //   "https://demo-newledge.auth.us-east-2.amazoncognito.com/login?client_id=3ijg2b3k5i7epp88779v9l7p1q&response_type=token&scope=phone+email+openid+aws.cognito.signin.user.admin+profile&redirect_uri=http://localhost:8080/";
      }
      // var hash = token.substring(token.length - (token.length - 10));

      var params = token.split("&");
      console.log(params);
      sessionStorage.setItem(
        "idToken",
        params[0].includes("id_token")
          ? params[0].substring(params[0].length - (params[0].length - 9))
          : params[0].substring(params[0].length - (params[0].length - 13))
      );
      sessionStorage.setItem(
        "accessToken",
        params[1].includes("access_token")
          ? params[1].substring(params[1].length - (params[1].length - 13))
          : params[1].substring(params[1].length - (params[1].length - 9))
      );
      var hash = sessionStorage.getItem("idToken");
      // var hash = params[1].substring(params[1].length - (params[1].length - 9));
      await this.$store.dispatch({
        type: "setAuthorizedToken",
        authorizedToken: hash,
      });
      var cognitoUser = this.parseJwt(hash);
      if (!cognitoUser.email) {
        hash = sessionStorage.getItem("accessToken");
        // hash = params[0].substring(params[0].length - (params[0].length - 9));
        await this.$store.dispatch({
          type: "setAuthorizedToken",
          authorizedToken: hash,
        });
        cognitoUser = this.parseJwt(hash);
      }
      await this.$store.dispatch({
        type: "setCognitoUser",
        userCred: cognitoUser,
      });
      var user = await this.$store.dispatch({
        type: "getUserById",
        _id: cognitoUser.sub,
      });
      console.log(user);
      if (!user.err) {
        user = await this.$store.dispatch({
          type: "setUser",
          userCred: user,
        });
        this.ready = true;
      } else {
        user = {};
        var numImg = Math.floor(Math.random() * 14) + 1;
        user.img = `shutterstock_1575446116_${numImg}.png`;
        user.AuthId = cognitoUser.sub;
        console.log(cognitoUser);
        console.log(cognitoUser.email);
        console.log(cognitoUser.name);
        console.log(cognitoUser.family_name);
        if (!cognitoUser.email) {
          var username = cognitoUser.username;
          var arr = username.split("_");
          var provider = arr[0];
          var email = arr[1];
          var arr2 = email.split("@");
          var firstName = arr2[0].split(".")[0];
          var lastName = arr2[0].split(".")[1];
          var fullName = `${firstName} ${lastName}`;
          user.email = email;
          user.fullName = fullName;
          user.company = provider;
        } else {
          user.email = cognitoUser.email;
          if (cognitoUser.family_name) {
            user.fullName = cognitoUser.name + " " + cognitoUser.family_name;
          } else {
            user.fullName = cognitoUser.name;
          }
        }
        user.id = await utilService.makeId(36);
        console.log(user);
        var res = await this.$store.dispatch({
          type: "addUser",
          userCred: user,
        });
        console.log(res);
        await this.$store.dispatch({
          type: "setUser",
          userCred: user,
        });
        this.ready = true;
      }
      this.$parent.changeLogo();
      this.$router.push("/");
    } else {
      setTimeout(() => {
        this.$parent.changeLogo();
      }, 100);
      user = await this.$store.dispatch({
        type: "getUserById",
        _id: this.loggedinUser.AuthId,
      });
      await this.$store.dispatch({
        type: "setUser",
        userCred: user,
      });
      // await this.$store.dispatch({ type: "loadUsers" });
      this.ready = true;
    }
    this.gamesPerformance();
    // if (!this.loggedinUser) {
    //   this.$message({
    //     showClose: true,
    //     message: `Login first!`,
    //     type: "warning",
    //     duration: 1500,
    //   });
    //   window.location = "https://demo-newledge.auth.us-east-2.amazoncognito.com/login?client_id=3ijg2b3k5i7epp88779v9l7p1q&response_type=token&scope=phone+email+openid+aws.cognito.signin.user.admin+profile&redirect_uri=https://demo.new-ledge.com/";
    //   return;
    // }
    // await this.getNetworkDownloadSpeed();
    // document
    //   .querySelector(".scenario")
    //   .addEventListener("mouseover", (event) => {
    //     console.log("mouseover");
    //     this.showModal = true;
    //     console.log(event);
    //     // this.$refs["modal"].style.left = 0;
    //     // this.$refs["modal"].style.top = 0;
    //     console.log(event.screenX);
    //     console.log(event.screenY);
    //     this.$refs["modal"].style.left = event.screenX;
    //     this.$refs["modal"].style.top = event.screenY;
    //   });
    // document.querySelector(".scenario").addEventListener("mouseout", () => {
    //   console.log("mouseout");
    //   this.showModal = false;
    // });
    // if (this.$route.params.email) {
    //   if (this.loggedinUser) {
    //     this.$store.dispatch({ type: "logout" });
    //   }
    //   var email = this.$route.params.email;
    //   var arr = email.split("@");
    //   var arr2 = arr[0].split(".");
    //   var fullName = arr2[0];
    //   if (arr2[1]) fullName = arr2[0] + " " + arr2[1];
    //   // var fullName = arr[0] + " " + arr2[0];
    //   // var arr = email.split("@");
    //   // var arr2 = arr[0].split(".");
    //   // var fullName = arr2[0] + " " + arr2[1];
    //   console.log(fullName);
    //   this.signupCred.fullName = fullName;
    //   this.signupCred.email = email;
    //   var numImg = Math.floor(Math.random() * 14) + 1;
    //   this.signupCred.img = `shutterstock_1575446116_${numImg}.png`;
    //   var res = await this.$store.dispatch({
    //     type: "login",
    //     userCred: this.signupCred,
    //   });
    //   console.log(res);
    //   if (res.err) {
    //     if (this.signupCred.fullName && this.signupCred.email) {
    //       await this.$store.dispatch({
    //         type: "signup",
    //         userCred: this.signupCred,
    //       });
    //       this.$message({
    //         showClose: true,
    //         message: `${this.signupCred.fullName} signup sucessfully!`,
    //         type: "success",
    //         duration: 1500,
    //       });
    //     } else {
    //       console.table("this.signupCred", this.signupCred);
    //       this.$message({
    //         showClose: true,
    //         message: `Some input is incorrect`,
    //         type: "warning",
    //         duration: 1500,
    //       });
    //     }
    //   }
    // var user = JSON.parse(JSON.stringify(this.loggedinUser));
    // if (!user.downloadSpeed) user.downloadSpeed = [];
    // var speedTest = {};
    // speedTest.timestamp = Date.now();
    // speedTest.downloadSpeed = JSON.parse(JSON.stringify(this.avg));
    // user.downloadSpeed.push(speedTest);
    // await this.$store.dispatch({ type: "updateUser", user });
    // }
  },
};
</script>

<style lang="scss" scoped>
$primary_color: #00f5af;

.text-container {
  position: relative;
  .hint {
    position: absolute;
    top: -7px;
    right: -4px;
    color: $primary_color;
  }
}
.sub-title-container {
  .sub-title2 {
    font-size: 18px;
  }
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  // border-radius: 12px;
  // padding: 9px 18px;
  // width: 200px;
  // height: 100px;
  z-index: 9;
  text-align: center;
  border: $primary_color 2px solid;
  font-family: regular;
  padding: 0.5rem 1rem;
  border-radius: 5rem;
  margin: 0 0.25rem;
  background: black;
  color: white;
  font-size: 1rem;
}
.profile {
  margin-top: 30px;
}
.hero-text {
  // background: linear-gradient(to right, #fda204, #f60473);
  // -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;

  color: white;
  // color: #07eda8;
  // margin-top: 15px;
}
.change-icon {
  color: black;
  width: 25px;
  margin: 5px 7px;
  opacity: 0.3;
  &:hover {
    opacity: 1;
  }
}
.avatar {
  &:hover + img {
    opacity: 1;
  }
}
.avatars {
  margin-top: 10px;
}
.avatar-img {
  width: 85px;
  height: 85px;
  border-radius: 50%;
  border: 2pt solid #07eda8;
  // border-radius: 12pt;
  box-shadow: 0 0 5px #07eda8;
  &.big {
    width: 150px;
    height: 150px;
  }
}
.cells {
  color: #00f5af;
  font-size: 16pt;
  text-transform: capitalize;
  & > * {
    position: relative;
    .status {
      // font-size: 14px;
      justify-content: end;
      position: absolute;
      top: -15px;
      right: 80px;
      & p {
        margin: 0;
        &.inprogress {
          color: white;
        }
      }
    }
  }
  h4 {
    font-size: 18px;
  }
  & .cell-text {
    height: 100px;
    text-transform: initial;
    & > * {
      font-size: 16px;
      margin-bottom: 5px;
    }
  }
  .cell {
    border: 2pt solid #00f5af;
    // border-radius: 12pt;
    box-shadow: 0 0 5px #00f5af;
    width: 220px;
    height: 120px;
    margin: 10px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    &.cell-scenario {
      background-image: url(../assets/img/scenario.png);
    }
    &.cell-cyber-room {
      background-image: url(../assets/img/escape-room.png);
    }
    &.cell-cyber-bank {
      background-image: url(../assets/img/escape-bank.png);
    }
    &.cell-cyber-bank-mobile {
      background-image: url(../assets/img/escape-bank-mobile.png);
    }
    &.cell-scenario-mobile {
      background-image: url(../assets/img/scenario-mobile.png);
    }
    &.cell-cyber-room-mobile {
      background-image: url(../assets/img/cyber-room-mobile.png);
    }
    &.cell-long-quiz-mobile {
      background-image: url(../assets/img/long-quiz-mobile.png);
    }
    &.cell-sql-injection-mobile {
      background-image: url(../assets/img/sql-injection-mobile.png);
    }
    &.cell-sql-injection {
      background-image: url(../assets/img/sql-injection.png);
    }
    &.cell-long-quiz {
      background-image: url(../assets/img/long-quiz.png);
    }
    &.cell-first-video-quiz {
      background-image: url(../assets/img/first-video-quiz.png);
    }
    &.cell-second-video-quiz {
      background-image: url(../assets/img/second-video-quiz.png);
    }
    &.cell-daily-quiz-1 {
      background-image: url(../assets/img/daily-quiz-1.png);
    }
    &.cell-daily-quiz-2 {
      background-image: url(../assets/img/daily-quiz-2.png);
    }
    &.cell-daily-quiz-3 {
      background-image: url(../assets/img/daily-quiz-3.png);
    }
    &.cell-daily-quiz-4 {
      background-image: url(../assets/img/daily-quiz-4.png);
    }
    &.cell-daily-quiz-5 {
      background-image: url(../assets/img/daily-quiz-5.png);
    }
    &.cell-dev {
      background-image: url(../assets/img/dev.png);
    }
    &.cell-navigator {
      background-image: url(../assets/img/sql-injection.png);
    }
    &:hover {
      box-shadow: 0 0 8px #00f5af;
    }
  }
  & > *:hover {
    text-shadow: 0 0 8px #00f5af;
    cursor: pointer;
  }
}
</style>