<template>
  <footer class="footer full main-container text-center flex center">
    <!-- <h3>Experience the difference</h3>
      <h3 class="mrg0">Newledge&copy;2021</h3> -->
    <!-- <span>providing this information confirms that you agree to receive information and offering from newledge</span> -->
    <div class="section-logo flex center mb10">
      <div
        v-if="loggedinUser && loggedinUser.secondCompImg"
        class="second-logo logo"
      >
        <img :src="loggedinUser.secondCompImg" alt="" class="logo-img" />
      </div>
      <div class="flex center" v-else>
        <div class="newledge-logo logo">
          <img src="@/assets/img/logofooter.png" alt="" class="logo-img" />
        </div>
        <h3 class="mrg0">Learn. Different.</h3>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "app-footer",
  computed: {
    loggedinUser() {
      return this.$store.getters.loggedinUser;
    },
  },
  // mounted() {
  //   var someImg = document.querySelector(".second-logo img");
  //   console.log(someImg);
  //   console.log(someImg.naturalWidth);
  //   console.log(someImg.naturalHeight);
  //   if (someImg.naturalWidth > someImg.naturalHeight) {
  //     console.log("landscape");
  //     someImg.style.maxHeight = "90px";
  //     // this.$parent.typeImg = 'landscape'
  //     //it's a landscape
  //   } else if (someImg.naturalWidth < someImg.naturalHeight) {
  //     // this.$parent.typeImg = 'portrait'
  //     console.log("portrait");
  //     someImg.style.maxWidth = "100%";
  //     someImg.style.maxHeight = "90px";
  //     //it's a portrait
  //   } else {
  //     // this.$parent.typeImg = 'square'
  //     console.log("squre");
  //     someImg.style.maxWidth = "100%";
  //     someImg.style.maxHeight = "90px";
  //     //image width and height are equal, therefore it is square.
  //   }
  // },
};
</script>

<style lang="scss" scoped>
footer {
  position: relative;
}
</style>