<template>
  <section class="addUser flex column center">
    <h3 class="text-center">Add User</h3>
    <form @submit.prevent="addUser" class="flex column center">
      <el-input
        v-model="addUserCred.fullName"
        onfocus="this.placeholder = ''"
        onblur="this.placeholder = 'Username'"
        placeholder="Username"
        clearable
        suffix-icon="el-icon-edit"
      ></el-input>
      <el-input
        v-model="addUserCred.password"
        onfocus="this.placeholder = ''"
        onblur="this.placeholder = 'Password'"
        show-password
        placeholder="Password"
        clearable
        suffix-icon="fas fa-key"
      ></el-input>
      <el-input
        v-model="addUserCred.email"
        onfocus="this.placeholder = ''"
        onblur="this.placeholder = 'Email'"
        placeholder="Email"
        clearable
        suffix-icon="far fa-envelope"
      ></el-input>
      <el-input
        v-model="addUserCred.company"
        onfocus="this.placeholder = ''"
        onblur="this.placeholder = 'Company'"
        placeholder="Company"
        clearable
        suffix-icon="far fa-building"
      ></el-input>
      <el-date-picker
        v-model="dates"
        type="datetimerange"
        range-separator="To"
        start-placeholder="Start date"
        end-placeholder="End date"
      >
      </el-date-picker>
      <el-checkbox v-model="addUserCred.isAdmin">Is Admin?</el-checkbox>
      <section class="upload-img flex column center">
        <p>Upload main company logo:</p>
        <template v-if="!isLoading">
          <label for="imgUploader">
            <i class="fas fa-cloud-upload-alt hover-pointer"></i>
          </label>
          <input
            type="file"
            name="img-uploader"
            id="imgUploader"
            @change="onUploadImg"
          />
        </template>
        <img
          class="loader"
          v-else
          src="https://i.pinimg.com/originals/65/ba/48/65ba488626025cff82f091336fbf94bb.gif"
          alt=""
        />
        <div v-if="compImg" class="img-list">
          <section class="imgs flex center">
            <img class="border-radius mrg5" :src="compImg" alt="img..." />
          </section>
        </div>
      </section>
      <section class="upload-img flex column center">
        <p>Upload second company logo:</p>
        <template v-if="!isLoadingSecond">
          <label for="secondImgUploader">
            <i class="fas fa-cloud-upload-alt hover-pointer"></i>
          </label>
          <input
            type="file"
            name="img-uploader"
            id="secondImgUploader"
            @change="onUploadSecondImg"
          />
        </template>
        <img
          class="loader"
          v-else
          src="https://i.pinimg.com/originals/65/ba/48/65ba488626025cff82f091336fbf94bb.gif"
          alt=""
        />
        <div v-if="secondCompImg" class="img-list">
          <section class="imgs flex center">
            <img class="border-radius mrg5" :src="secondCompImg" alt="img..." />
          </section>
        </div>
      </section>
      <button type="button" class="button" @click.stop="addUser">
        Add User
      </button>
    </form>
  </section>
</template>

<script>
export default {
  name: "addUser",
  data() {
    return {
      addUserCred: { compImg: null, secondCompImg: null },
      isLoading: false,
      isLoadingSecond: false,
      compImg: null,
      secondCompImg: null,
      dates: null,
    };
  },
  computed: {
    loggedinUser() {
      return this.$store.getters.loggedinUser;
    },
  },
  methods: {
    async onUploadImg(ev) {
      this.isLoading = true;
      var file = ev.target.files[0];
      console.log(file);
      if (file.type && !file.type.startsWith("image/")) {
        console.log("File is not an image.", file.type, file);
        return;
      }
      const reader = new FileReader();
      reader.addEventListener("load", (event) => {
        console.log(event.target.result);
        this.compImg = event.target.result;
        // img.src = event.target.result;
      });
      reader.readAsDataURL(file);
      this.isLoading = false;
    },
    async onUploadSecondImg(ev) {
      this.isLoadingSecond = true;
      var file = ev.target.files[0];
      console.log(file);
      if (file.type && !file.type.startsWith("image/")) {
        console.log("File is not an image.", file.type, file);
        return;
      }
      const reader = new FileReader();
      reader.addEventListener("load", (event) => {
        console.log(event.target.result);
        this.secondCompImg = event.target.result;
        // img.src = event.target.result;
      });
      reader.readAsDataURL(file);
      this.isLoadingSecond = false;
    },
    async addUser() {
      this.addUserCred.compImg = this.compImg;
      this.addUserCred.secondCompImg = this.secondCompImg;
      if(this.dates != null) {
        this.addUserCred.startDate = new Date(this.dates[0]).getTime();
        this.addUserCred.endDate = new Date(this.dates[1]).getTime();
      }
      if (
        this.addUserCred.fullName &&
        this.addUserCred.password &&
        this.addUserCred.email
      ) {
        var numImg = Math.floor(Math.random() * 14) + 1;
        this.addUserCred.img = `shutterstock_1575446116_${numImg}.png`;
        console.log(this.addUserCred);
        var res = await this.$store.dispatch({
          type: "addUser",
          userCred: JSON.parse(JSON.stringify(this.addUserCred)),
        });
        if (!res.err) {
          await this.$message({
            showClose: true,
            message: `${JSON.parse(JSON.stringify(this.addUserCred.fullName))} created sucessfully!`,
            type: "success",
            duration: 1500,
          });
          this.addUserCred = {};
          // this.$router.push("/");
        } else {
          this.$message({
            showClose: true,
            message: `Something was wrong, please try again.`,
            type: "warning",
            duration: 1500,
          });
          return;
        }
      } else {
        console.table("this.addUserCred", this.addUserCred);
        this.$message({
          showClose: true,
          message: `Some input is incorrect`,
          type: "warning",
          duration: 1500,
        });
      }
    },
  },
  created() {
    if (!this.loggedinUser) {
      this.$message({
        showClose: true,
        message: `Login first!`,
        type: "warning",
        duration: 1500,
      });
      window.location = "https://demo-newledge.auth.us-east-2.amazoncognito.com/login?client_id=3ijg2b3k5i7epp88779v9l7p1q&response_type=token&scope=phone+email+openid+aws.cognito.signin.user.admin+profile&redirect_uri=https://demo.new-ledge.com/";
      return;
    }
    if (!this.loggedinUser.isAdmin) {
      this.$message({
        showClose: true,
        message: `Not allowed!`,
        type: "warning",
        duration: 1500,
      });
      window.location = "https://demo-newledge.auth.us-east-2.amazoncognito.com/login?client_id=3ijg2b3k5i7epp88779v9l7p1q&response_type=token&scope=phone+email+openid+aws.cognito.signin.user.admin+profile&redirect_uri=https://demo.new-ledge.com/";
      return;
    }
  },
};
</script>

<style lang="scss" scoped>
$primary_color: #00f5af;
/deep/ {
  .el-range-editor.el-input__inner {
    border: none;
    margin-left: 57px;
    &.el-date-editor .el-range__icon {
      color: #878787;
      position: absolute;
      right: 28px;
      font-size: 19px;
    }
    & .el-range-input {
      font-family: regular;
      border: none;
      background-color: transparent;
      border-bottom: 1px solid white;
      margin-bottom: 0.4375rem;
      border-radius: 0px;
      color: white;
      &::placeholder {
        color: white;
      }
    }
  }
}
form {
  max-width: 250px;
}
.upload-img {
  & i {
    font-size: 40px;
    color: #00f5af;
  }
}
.main-color {
  color: $primary_color;
}
.login .singup {
  width: 500px;
  border: 1px solid #cccccc;
  background-color: #ffffff;
  margin: auto;
  margin-top: 20px;
  padding: 20px;
}
#imgUploader {
  display: none;
}
#secondImgUploader {
  display: none;
}
form {
  margin-bottom: 16px;
  position: relative;
}
.show-addUser-btn {
  color: #07eda8;
}
// .login-form {
//   margin-top: 60px;
// }
.button {
  border: $primary_color 2px solid;
  font-family: regular;
  padding: 0.5rem 1rem;
  border-radius: 5rem;
  margin: 0 0.25rem;
  transition: 0.3s;
  background: black;
  color: white;
  font-size: 1rem;
  &:hover {
    cursor: pointer;
    background: $primary_color;
    border-color: rgba(255, 255, 255, 0.25);
  }
  &.is-active {
    background: $primary_color;
    color: black;
    border-color: transparent;

    &:hover {
      background: darken($primary_color, 10%);
    }
  }
}
.forgot-pass-btn {
  position: absolute;
  right: 0;
  font-size: 12px;
  color: #00f5af;
}
/* h2 {
  margin-top: 10px;
  padding: 5px;
  border: solid 2px black;
  background-color: #69bd42;
  width: 300px;
} */
</style>