import httpService from './http-service.js'

export const gameService = {
    startGame,
    endGame,
    postTaskCompletion,
    postWrongAnswer,
    getNextGame
}

async function getNextGame(currGame) {
    var data = {
        "currGame": currGame
    }
    return httpService.patch('getnextgame', data)
}

// Start game
async function startGame(id, type) {
    console.log('Start game : Id ' + id + ',type ' + type);
    var data = {
        "userId": id,
        "score": {
            "type": type
        }
    }

    return httpService.patch('user/startgame', data)
}

async function postTaskCompletion(id, gameId, type, taskName, taskTime, threat, errors) {
    var data = {
        "gameId": gameId,
        "userId": id,
        "task": {
            "mission": taskName,
            "threat": threat,
            "type": type,
            "time": taskTime,
            "Errors": errors
        }
    }
    return httpService.patch('user/addscorewithparams', data)
}

//WrongAnswer
async function postWrongAnswer(id, gameId, type, taskName) {
    var data = {
        "userId": id,
        "gameId": gameId,
        "type": type,
        "mission": taskName,
    }
    return httpService.patch('user/addwronganswer', data)
}

//Endgame
async function endGame(id, gameId) {
    console.log('End game : Id ' + id + ',gameId ' + gameId);
    var data = {
        "userId": id,
        "gameId": gameId
    }

    return httpService.patch('user/endgame', data)
}