<template>
  <div class="video-quiz flex column center">
    <video-player
      v-if="showVideo"
      ref="videoPlayer"
      class="vjs-custom-skin"
      :options="playerOptions"
      @play="onPlayerPlay($event)"
      @ready="onPlayerReady($event)"
      @ended="onPlayerEnd($event)"
    >
    </video-player>
    <quizGame :quizNew="videoQuiz" :gameId="gameId" v-else></quizGame>
    <section class="btns flex center mt16">
      <button
        class="button to-profile-btn mrg5"
        @click="() => this.$router.push('/')"
      >
        Go back to Profile
      </button>
      <!-- <button
        v-if="nextGame"
        class="button to-next-game-btn mrg5"
        @click="() => this.$router.push(`/${route}`)"
      >
        {{ nextGame }}
      </button> -->
    </section>
  </div>
</template>

<script>
import quizGame from "@/cmp/quiz-game";

export default {
  name: "first-video-quiz",
  data() {
    return {
      videoQuiz: {
        questions: [
          {
            id: "first-video-quiz.1",
            text: "?אתה חייב לצאת לאסוף את הילדה מהגן ולא סיימת את הדוח שהמנהל שלך ביקש לישיבה מחר בצהריים, לכן החלטת להמשיך לעבוד עליו מהמחשב הפרטי בביתך. כיצד תפעל",
            responses: [
              {
                text: "אתחבר לרשת הארגונית מרחוק ואמשיך לעבוד",
                correct: true,
              },
              { text: "אעביר את הקבצים דרך תיבת המייל הפרטית שלי" },
              { text: "אעלה את הקבצים לכונן חיצוני נייד שיש ברשותי" },
              {
                text: "אסור להעביר חומר מהרשת למחשב הפרטי, לכן אצלם את המסך באמצעות הנייד ואעזר בזה בעבודה מהמחשב הפרטי",
              },
            ],
            message: null,
          },
          {
            id: "first-video-quiz.2",
            text: ".כיצד תנהג בסיטואציה הבאה: אדם שאינך מכיר הטוען כי שכח את כרטיס הכניסה, מבקש שתכניס אותו לבניין",
            responses: [
              {
                text: ".אפנה אותו לגורם הביטחון המוסמך לכך",
                correct: true,
              },
              {
                text: ".אשאל אותו באיזו מחלקה וצוות הוא עובד, במידה ואכיר את האנשים אכניס אותו",
              },
              { text: "?!אכניס אותו, זה קורה לכולם, מי לא שכח את הכרטיס בבית" },
              { text: ".אבקש ממנו להתקשר לחבר צוות על מנת לוודא שמכירים אותו" },
            ],
            message: null,
          },
          {
            id: "first-video-quiz.3",
            text: "קיבלת מייל מצוות התמיכה הטכנית של החברה, המבקש את סיסמתך אחרת המשתמש שלך יינעל באופן מיידי",
            responses: [
              {
                text: "צוות התמיכה לעולם לא יבקש את הסיסמה שלי",
                correct: true,
              },
              { text: "אמסור את הסיסמה בהקדם כדי לשמור על הרציפות העסקית" },
              { text: "אבקש אישור מהמנהל הישיר שלי" },
              { text: "אתן את הסיסמה שלי ואשנה אותה מיד לאחר מכן" },
            ],
            message: null,
          },
          {
            id: "first-video-quiz.4",
            text: "?התיישבת לקפה בפינת הקפה בעבודה, ליד אדם שאינך מכיר. על אילו נושאים אינכם יכולים לדבר",
            responses: [
              {
                text: ".אין לחשוף כל מידע רגיש או מסווג הקשור לארגון",
                correct: true,
              },
              {
                text: ".הוא יושב בפינת הקפה בעבודה, לכן מדובר בעובד חברה שמותר לשתף אותו בכל נושא",
              },
              {
                text: ".אשאל לתפקידו ובהתאם לתשובתו אדע מהו המידע שאוכל לשתף עימו",
              },
              {
                text: ".מותר לדבר על הכל, אבל לא אחשוף את פרטי הגישה שלי למערכת",
              },
            ],
            message: null,
          },
          {
            id: "first-video-quiz.5",
            text: "?לאחר שקבעת סיסמה חדשה למחשב, מה אסור לעשות",
            responses: [
              { text: "לרשום אותה במקום גלוי ולשתף אחרים בסיסמה החדשה" },
              {
                text: "לשנות את כלל הסיסמאות שלי לאותה הסיסמה, כדי שיהיה לי קל יותר לזכור",
              },
              {
                text: "להשאיר את המחשב פתוח גם אם אני עוזב את השולחן, כך שלא אצטרך להזין אותה מחדש",
              },
              {
                text: "כל התשובות נכונות",
                correct: true,
              },
            ],
            message: null,
          },
        ],
      },
      gameId: null,
      showVideo: true,
      playerOptions: {
        autoplay: true,
        controls: true,
        controlBar: {
          timeDivider: false,
          durationDisplay: false,
        },
        // poster: 'https://surmon-china.github.io/vue-quill-editor/static/images/surmon-5.jpg'
      },
      nextGame: null,
      route: null,
    };
  },
  components: {
    quizGame,
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    },
    loggedinUser() {
      return this.$store.getters.loggedinUser;
    },
  },
  methods: {
    onPlayerPlay(player) {
      console.log("player play!", player);
    },
    onPlayerReady(player) {
      console.log("player ready!", player);
      this.player.play();
    },
    async onPlayerEnd(player) {
      console.log("player end!", player);
      await this.$store.dispatch({
        type: "postTaskCompletion",
        id: this.loggedinUser.id,
        Type: this.$route.path.substring(1),
        gameId: this.gameId,
        taskName: "first-video",
        taskTime: 0,
        threat: "GeneralAwareness",
        errors: 0,
      });
      this.showVideo = false;
    },
    playVideo: function (source) {
      const video = {
        withCredentials: false,
        type: "application/x-mpegurl",
        src: source,
      };
      this.player.reset(); // in IE11 (mode IE10) direct usage of src() when <src> is already set, generated errors,
      this.player.src(video);
      // this.player.load()
      this.player.play();
    },
  },
  mounted() {
    this.$parent.changeLogo();
    const src =
      "https://djtia0ybt030k.cloudfront.net/out/v1/97d882d47bab4834b028bb696f0c26f1/35a9505c283846cdba26ec5bfcd237fc/b8bcb9eba73a4ceaa2f71a70dc4d8667/index.m3u8";
    this.playVideo(src);
  },
  async created() {
    this.gameId = await this.$store.dispatch({
      type: "startGame",
      id: this.loggedinUser.id,
      Type: this.$route.path.substring(1),
    });
    // var res = await this.$store.dispatch({
    //   type: "getNextGame",
    //   currGame: this.$route.path.substring(1),
    // });
    // if (!res.err) {
    //   switch (res) {
    //     case "cyber-room":
    //       this.nextGame = "Virtual Escape Room";
    //       break;
    //     case "scenario":
    //       this.nextGame = "Cyber Security Training";
    //       break;
    //     case "first-video-quiz":
    //       this.nextGame = "First video & short quiz";
    //       break;
    //     case "second-video-quiz":
    //       this.nextGame = "Second video & short quiz";
    //       break;
    //     default:
    //       this.nextGame = res;
    //       break;
    //   }
    //   this.route = res;
    // }
  },
};
</script>

<style lang="scss" scoped>
$primary_color: #00f5af;

.video-quiz {
  position: relative;
  width: 100%;
  height: 100%;
  .hint {
    position: absolute;
    top: 33px;
    color: black;
  }
  .button {
    border: $primary_color 2px solid;
    font-family: regular;
    padding: 0.5rem 1rem;
    border-radius: 5rem;
    margin: 0 0.25rem;
    transition: 0.3s;
    background: black;
    color: white;
    font-size: 1rem;
    &:hover {
      cursor: pointer;
      background: $primary_color;
      border-color: rgba(255, 255, 255, 0.25);
    }
    &.is-active {
      background: $primary_color;
      color: black;
      border-color: transparent;

      &:hover {
        background: darken($primary_color, 10%);
      }
    }
  }
}
.vjs-custom-skin {
  height: 100% !important;
  width: 100%;
}

.vjs-custom-skin /deep/ .video-js {
  height: 100%;
  width: 100%;
  .vjs-big-play-button {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
