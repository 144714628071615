// import router from '@/router'
var AmazonCognitoIdentity = require("amazon-cognito-identity-js");
// var moment = require('moment')

import { userStore } from '../store/module/user-store';
import Vue from 'vue';
import VuejsDialog from "vuejs-dialog"

Vue.use(VuejsDialog)


// const BASE_URL = process.env.NODE_ENV === 'production' ?
//     '/api/' :
//     '//localhost:3000/api/'

const BASE_URL = 'https://wiohwf2j7j.execute-api.us-east-2.amazonaws.com/prod/'



import Axios from 'axios';
var axios = Axios.create({
    withCredentials: false
});

export default {
    get(endpoint, data) {
        return ajax(endpoint, 'GET', data)
    },
    post(endpoint, data) {
        return ajax(endpoint, 'POST', data)
    },
    patch(endpoint, data) {
        return ajax(endpoint, 'PATCH', data)
    },
    put(endpoint, data) {
        return ajax(endpoint, 'PUT', data)
    },
    delete(endpoint, data) {
        return ajax(endpoint, 'DELETE', data)
    }
}

function expired401() {
    Vue.dialog.alert(`You were signed out of your account. Please press ‘Continue’ to sign in again.`).then(() => {
        setTimeout(() => {
            sessionStorage.clear()
            window.location = "https://demo-newledge.auth.us-east-2.amazoncognito.com/login?client_id=3ijg2b3k5i7epp88779v9l7p1q&response_type=token&scope=phone+email+openid+aws.cognito.signin.user.admin+profile&redirect_uri=https://demo.new-ledge.com/";
        }, 500)
    });
}

function isExpired() {
    if (!userStore.state.loggedinCognitoUser) {
        return
    }
    const IdToken = new AmazonCognitoIdentity.CognitoIdToken({
        IdToken: sessionStorage.getItem('idToken'),
    });
    const AccessToken = new AmazonCognitoIdentity.CognitoAccessToken({
        AccessToken: sessionStorage.getItem('accessToken'),
    });
    const tokenData = { IdToken, AccessToken };

    const session = new AmazonCognitoIdentity.CognitoUserSession(tokenData);
    console.log(session);
    const expiration = userStore.state.loggedinCognitoUser.exp - userStore.state.loggedinCognitoUser.iat
    console.log(expiration);
    console.log(session.clockDrift > expiration);
    if (session.clockDrift > expiration) {
        expired401()
    }
}


async function ajax(endpoint, method = 'get', data = null) {
    try {
        const res = await axios({
            url: `${BASE_URL}${endpoint}`,
            method,
            data,
            headers: {
                Authorization: userStore.state.authorizedToken
            }
        })
        console.log("ajax -> res.data", res.data)
        return res.data;
    } catch (err) {
        console.log(err);
        console.log(err.response);
        console.log(err.message);
        let errorForServer = {}
        errorForServer.err = err
        errorForServer.err_response = err.response ? err.reponse : err.message
        errorForServer.timestamp = Date.now();
        if (userStore.state.loggedinUser) {
            errorForServer.userId = userStore.state.loggedinUser.id
        }
        if (userStore.state.loggedinCognitoUser) {
            errorForServer.authId = userStore.state.loggedinCognitoUser.sub
        }
        await ajax('adderror', 'PATCH', errorForServer)
        isExpired()
        console.log(`Had issues ${method}ing to server`, err)
        throw err;
    }
}