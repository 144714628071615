import Vue from 'vue'
import App from './App'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './style/main.scss'
import locale from 'element-ui/lib/locale/lang/en'
import moment from 'moment';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueEllipseProgress from 'vue-ellipse-progress';
import VueBlobJsonCsv from 'vue-blob-json-csv';
import JwPagination from 'jw-vue-pagination';
import VuejsDialog from "vuejs-dialog"
import VideoPlayer from 'vue-videojs7'

Vue.use(VideoPlayer,
    /* {
  options: global default videojs options,
  events: global videojs videojs events
} */
)
Vue.use(VuejsDialog)
Vue.component('jw-pagination', JwPagination);
Vue.use(VueBlobJsonCsv)
Vue.use(VueEllipseProgress);
Vue.use(ElementUI, { locale });
moment().format();

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')