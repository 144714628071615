<template>
  <div class="quiz flex center">
    <!--container-->
    <div v-if="showBefore" class="flex column center message-before">
      <h2 class="text-center">
        רוצים ניקוד גבוה? פותרים מהר ונכון ומקבלים יותר נקודות
      </h2>
      <h2 class="text-center">!מוכנים? גו</h2>
      <a class="button" @click="() => (this.showBefore = false)"> הבא </a>
    </div>
    <section v-else class="container flex column center">
      <!--questionBox-->
      <div
        v-if="showModal && questionIndex >= quiz.questions.length"
        class="message top flex column align-center"
      >
        <div class="text-center mb10">
          <h3 class="contant">
            {{ quiz.questions[questionIndex - 1].message }}
          </h3>
          <button class="button skip" @click="closeModal()">
            הבא <i class="fa fa-refresh"></i>
          </button>
        </div>
      </div>
      <div class="questionBox">
        <!-- transition -->

        <transition
          :duration="{ enter: 500, leave: 300 }"
          enter-active-class="animated zoomIn"
          leave-active-class="animated zoomOut"
          mode="out-in"
        >
          <!--qusetionContainer-->
          <div
            class="questionContainer"
            v-if="questionIndex < quiz.questions.length"
            v-bind:key="questionIndex"
          >
            <header>
              <h1 class="title is-6">אליפות הסייבר בהראל</h1>
              <!--progress-->
              <div class="progressContainer">
                <progress
                  class="progress is-info is-small"
                  :value="(questionIndex / quiz.questions.length) * 100"
                  max="100"
                >
                  {{ (questionIndex / quiz.questions.length) * 100 }}%
                </progress>
                <p>{{ questionIndex }} / {{ quiz.questions.length }} הושלמו</p>
              </div>
              <!--/progress-->
            </header>
            <div
              v-if="questionIndex && showModal"
              class="message flex column align-center"
            >
              <div class="text-center mb10">
                <h3 class="contant">
                  {{ quiz.questions[questionIndex - 1].message }}
                </h3>
                <p>{{ questionIndex }} / {{ quiz.questions.length }} הושלמו</p>
                <button class="button skip" @click="closeModal()">
                  הבא <i class="fa fa-refresh"></i>
                </button>
              </div>
            </div>
            <!-- questionTitle -->
            <h2 class="titleContainer title">
              {{ quiz.questions[questionIndex].text }}
            </h2>
            <h3 v-if="showErr" class="err-message">תשובה שגויה, נסה שוב</h3>
            <h3 v-if="showCorrect" class="correct-message">תשובה נכונה</h3>
            <!-- quizOptions -->
            <div v-show="!load" class="optionContainer">
              <div
                class="option text-center"
                v-for="(response, index) in quiz.questions[questionIndex]
                  .responses"
                @click="(event) => selectOption(event, index)"
                :class="
                  userResponses[questionIndex] == index
                    ? `option${index} is-selecteded`
                    : `option${index}`
                "
                :key="index"
              >
                <span :class="`spanOption${index}`"> .{{ index + 1 }}</span>
                <span :class="`spanOption${index}`">{{ response.text }}</span>
              </div>
            </div>
            <div v-show="load" class="loading-container flex center">
              <div
                class="loading"
                :style="{
                  backgroundImage:
                    'url(' + require(`@/assets/img/loading.gif`) + ')',
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                }"
              ></div>
            </div>

            <!--quizFooter: navigation and progress-->

            <!--/quizFooter-->
          </div>
          <!--/questionContainer-->

          <!--quizCompletedResult-->
          <div
            v-if="questionIndex >= quiz.questions.length"
            v-bind:key="questionIndex"
            class="quizCompleted has-text-centered"
          >
            <!-- quizCompletedIcon: Achievement Icon -->
            <span class="icon">
              <i
                class="fa"
                :class="score() > 50 ? 'fa-check' : 'fa-times-circle'"
              ></i>
            </span>

            <!--resultTitleBlock-->
            <h2 class="title">
              {{
                score() > 75
                  ? "!ביצוע מדהים"
                  : score() > 50
                  ? "!ביצוע טוב"
                  : "בהצלחה בפעם הבאה"
              }}
            </h2>
            <!-- <p class="subtitle">{{ score() }} :ציון סופי</p> -->
            <br />
            <div class="flex center">
              <h2>?את האתגר היומי כבר עשיתם</h2>
              <a class="button" @click="() => this.$router.push(`/${twinGame}`)"
                >קחו אותי לאתגר<i class="fa fa-refresh"></i
              ></a>
            </div>
            <!--/resultTitleBlock-->
          </div>
          <!--/quizCompetedResult-->
        </transition>
      </div>
      <!--/questionBox-->
    </section>
    <!--/container-->
  </div>
</template>

<script>
var quiz = {
    user: "Dave",
    questions: [
      {
        text: "You’ve created a sensitive document and want to save it somewhere safe. Where should it be saved?",
        responses: [
          { text: "On your local hard drive" },
          { text: "On an unsecured cloud" },
          { text: "On your private and unencrypted USB flash drive" },
          {
            text: "In Document Center or a shared drive in the Company's network",
            correct: true,
          },
        ],
        message:
          "Storing Company's sensitive data in an unprotected location might expose it to security threats. Document Center is the most secured repository.",
      },
      {
        text: "Which password is considered a STRONG password that is difficult to guess and is according to Company's password policy?",
        responses: [
          { text: "!@#$%^" },
          { text: "LOVE2work@Company!", correct: true },
          { text: "ABCDE" },
          { text: "98765" },
        ],
        message:
          "Create a password based on information that only you know. To remember it easily consider using a passphrase from a book or song you like and include letters, numbers and special characters. Always use different passwords for each of your accounts.",
      },
      {
        text: "Which of the following is considered sensitive information?",
        responses: [
          { text: "Monday Mail, HR training, technical training" },
          { text: "Training material, announcements to all employees" },
          {
            text: "Product development documents, documents related to PMIs (post-merger integration), contracts",
            correct: true,
          },
          { text: "Legal and Finance templates" },
        ],
        message:
          "Sensitive information should be accessible to a limited group of employees on a “Need to Know” basis. Exposing sensitive information to unauthorized entities might cause business damages to the company.",
      },
      {
        text: "When you receive an email that has a link or attachment in it, what should you do?",
        responses: [
          {
            text: "If you don’t recognize the sender or if the content seems suspicious, report it to Company's Cyber Security Center (ACSC) by clicking the “Report Suspicious Email” button in Outlook (the icon with the fish)",
            correct: true,
          },
          {
            text: "Nothing, it’s not your responsibility. The security systems will handle it",
          },
          {
            text: "Open the link or attachment to check if it’s malicious",
          },
          { text: "Send it to a colleague to check if it’s safe to open it" },
        ],
        message:
          "A suspicious email might be a phishing or malicious email. It could expose sensitive information and cause malware contamination and business damage, as well as personal, financial and private damage.",
      },
      {
        text: "Which guidelines should you follow when sharing Company's and customer data with a third party?",
        responses: [
          {
            text: "Send the data on a “Need to Know” basis and encrypt it if it’s Highly Sensitive (the third party must have an agreement with Company)",
            correct: true,
          },
          {
            text: "There are no special guidelines for sending information to a third party",
          },
          {
            text: "Sharing information with a third party should only be done from a private email account",
          },
          {
            text: "Emails containing Highly Sensitive information sent to a third party should be signed with the sender’s name only",
          },
        ],
        message:
          "Company and customer Highly Sensitive information should have minimal exposure and maximum control. Emailing a customer “Highly Sensitive” documents requires special authorization.",
      },
      {
        text: "If you want to install a new application for work purposes:",
        responses: [
          { text: "Download it from the internet" },
          {
            text: "Download it from the internet and if the anti-virus doesn’t block it you can use it",
          },
          {
            text: "Search for it in Company's Software Catalog (ASC) or in GO Store. If it is not there, get approval by opening an ASA online form",
            correct: true,
          },
          {
            text: "Make sure it’s freeware, because only freeware can be installed",
          },
        ],
        message:
          "An application needed for work purposes can be downloaded from Company's Software Catalog (ASC) or from GO Store.",
      },
      {
        text: "Which of the following statements regarding the use of a Company laptop is CORRECT?",
        responses: [
          {
            text: "It’s completely safe to leave your laptop in your locked car trunk",
          },
          {
            text: "You can leave your laptop unattended in a public place as long as your screen is locked and company's Cyber Security Center (ACSC) was notified in advance",
          },
          {
            text: "Never leave your laptop unattended after working hours",
            correct: true,
          },
          { text: "You may leave your laptop unattended in public places" },
        ],
        message:
          "Laptops can be stolen very easily. While traveling or staying in busy public places, pay extra attention to your laptop and don’t leave it unattended.",
      },
      {
        text: "Are you allowed to store a customer's highly sensitive data on your local hard drive?",
        responses: [
          {
            text: "Absolutely not",
            correct: true,
          },
          {
            text: "Yes, as long as you use a desktop and not a laptop",
          },
          { text: "Yes, if the hard drive is not shared" },
          { text: "Yes, as long as you use a laptop and not a desktop" },
        ],
        message:
          "Company and customer highly sensitive data is considered our most critical information asset and must be well protected to prevent leakage to unauthorized entities. It should never be stored on a local hard drive.",
      },
      {
        text: "How can you avoid becoming the victim of fraud phishing emails?",
        responses: [
          {
            text: "You can click on the links as long as you don’t provide any personal information",
          },
          {
            text: "Don’t open links or attachments sent from an unfamiliar or unreliable email address and when you are not 100% sure it is safe",
            correct: true,
          },
          { text: "You can click the links but avoid opening attachments" },
          { text: "You can open attachments but not links" },
        ],
        message:
          "To avoid phishing attempts, don’t cooperate with impostors and don’t click any link or open any email attachments. One type of phishing is called Spear Phishing. It’s a phishing attack that targets a specific victim instead of casting a wide net, hoping that one in a million will fall for the scam.",
      },
      {
        text: "When developing applications and software:",
        responses: [
          { text: "Use high level security standards", correct: true },
          {
            text: "You don’t need to add high level security standards to your work methodologies",
          },
          {
            text: "Applications don’t require any security. For software, add security measures to the development process",
          },
          {
            text: "High level security standards are only required in the testing environment",
          },
        ],
        message:
          "Add high level security standards to your work methodologies to ensure information security standards are integrated into your applications and software.",
      },
      {
        text: "When there is a need to use external internet storage or cloud services:",
        responses: [
          { text: "Use it without approval so as not to delay work" },
          {
            text: "Use and upload data only if the documents are confidential",
          },
          {
            text: "Get the appropriate security approvals first",
            correct: true,
          },
          { text: "Only upload encrypted documents" },
        ],
        message:
          "Storing information in external internet storage services and cloud services is convenient but is exposed to security and data breach risks, so uploading company information to them is forbidden.",
      },
      {
        text: "Is it allowed to share sensitive work-related data on Gmail, WhatsApp, LinkedIn, Facebook or Twitter?",
        responses: [
          { text: "Yes, as long as you use 2 factor authentication" },
          { text: "No", correct: true },
          { text: "No, unless required by the customer" },
          { text: "Yes" },
        ],
        message:
          "Gmail, WhatsApp, LinkedIn and other social networking media are unsecure platforms and sensitive Company's data shared through them might be exposed to unauthorized entities.",
      },
      {
        text: "Which of the following statements for using your email is CORRECT?",
        responses: [
          { text: "It’s ok to send rude //not insulting emails" },
          {
            text: "Using your personal email for work purposes is forbidden",
            correct: true,
          },
          {
            text: "You don’t have to include a personal signature in an email",
          },
          { text: "All the statements are correct" },
        ],
        message:
          "It’s forbidden to use a personal email address for work purposes because it creates security risks.",
      },
      {
        text: "During the day you had to work on sensitive printed documents. What should you do before you go home?",
        responses: [
          { text: "Lock them in a cabinet or shred them", correct: true },
          { text: "Take them home to throw away" },
          {
            text: "Leave them on the desk with a note that includes your name and employee ID",
          },
          {
            text: "Throw them in the kitchenette’s paper bin because it goes for recycling anyway",
          },
        ],
        message:
          "Company's “clear desk” policy states that before leaving at the end of the day, move all your sensitive printed documents from your desk or elsewhere to a locked cabinet or drawer.",
      },
    ],
  },
  userResponseSkelaton = Array(quiz.questions.length).fill(null);
import Vue from "vue";
// import socketService from "@/service/socket-service.js";

export default {
  name: "quiz",
  props: ["quizNew", "gameId", "twinGame"],
  data() {
    return {
      path: null,
      id: null,
      start: null,
      quiz: this.quizNew,
      questionIndex: 0,
      userResponses: userResponseSkelaton,
      isActive: false,
      timer: 0,
      interval: null,
      showModal: false,
      timeoutModal: null,
      wrongAns: {},
      showBefore: true,
      load: false,
      showErr: false,
      showCorrect: false
    };
  },
  filters: {
    charIndex: function (i) {
      return String.fromCharCode(97 + i);
    },
  },
  computed: {
    loggedinUser() {
      return this.$store.getters.loggedinUser;
    },
  },
  methods: {
    backTo() {
      // this.$parent.readyIframe = true
      this.$parent.showIframe();
    },
    getRandom(arr, n) {
      var result = new Array(n),
        len = arr.length,
        taken = new Array(len);
      if (n > len)
        throw new RangeError("getRandom: more elements taken than available");
      while (n--) {
        var x = Math.floor(Math.random() * len);
        result[n] = arr[x in taken ? taken[x] : x];
        taken[x] = --len in taken ? taken[len] : len;
      }
      return result;
    },
    closeModal() {
      this.showModal = false;
      clearTimeout(this.timeoutModal);
    },
    restart: async function () {
      this.questionIndex = 0;
      this.userResponses = Array(this.quiz.questions.length).fill(null);
      this.timer = 0;
      this.wrongAns = {};
      this.quiz.questions = await this.getRandom(this.quiz.questions, 2);
      userResponseSkelaton = Array(this.quiz.questions.length).fill(null);
      this.userResponses = userResponseSkelaton;

      this.interval = setInterval(() => {
        this.timer++;
      }, 1000);
    },
    selectOption: function (event, index) {
      this.load = true;
      Vue.set(this.userResponses, this.questionIndex, index);
      console.log(event);
      if (!event.target.className.includes("span")) {
        console.log(event.target.className.split(" ")[2].charAt(6));
        var currOption = event.target.className.split(" ")[2].charAt(6);
      } else {
        console.log(event.target.parentElement);
        console.log(
          event.target.parentElement.className.split(" ")[2].charAt(6)
        );
        currOption = event.target.parentElement.className
          .split(" ")[2]
          .charAt(6);
      }
      console.log(event.target.className.charAt(6));
      localStorage.setItem("currOption", currOption);
      // var currOption = localStorage.getItem("currOption");
      // if(timeout) clearTimeout(timeout)
      document.querySelector(`.option${currOption}`).style.backgroundColor =
        "black";
      document.querySelector(`.option${currOption}`).style.border =
        "#00f5af 1px solid";

      this.next();
      // console.log(this.userResponses);
    },
    next: async function () {
      if (
        this.quiz.questions[this.questionIndex].responses[
          this.userResponses[this.questionIndex]
        ].correct
      ) {
        this.clearInterval();
        await this.$store.dispatch({
          type: "postTaskCompletion",
          id: this.loggedinUser.id,
          Type: this.$route.path.substring(1),
          gameId: this.gameId,
          taskName: this.quiz.questions[this.questionIndex].id,
          taskTime: JSON.parse(JSON.stringify(this.timer)),
          threat: "GeneralAwareness",
          errors:
            this.wrongAns[this.questionIndex] > 0
              ? this.wrongAns[this.questionIndex]
              : 0,
        });
        this.timer = 0;
        this.startInterval();
        console.log("correct");
        this.load = false;
        this.showErr = false;
        this.showCorrect = true;
      } else {
        var currOption = localStorage.getItem("currOption");
        if (timeout) {
          clearTimeout(timeout);
          document.querySelector(`.option${currOption}`).style.backgroundColor =
            "black";
          document.querySelector(`.option${currOption}`).style.border =
            "#00f5af 1px solid";
        }

        if (!this.wrongAns[this.questionIndex])
          this.wrongAns[this.questionIndex] = 0;
        this.wrongAns[this.questionIndex]++;
        await this.$store.dispatch({
          type: "postWrongAnswer",
          id: this.loggedinUser.id,
          Type: this.$route.path.substring(1),
          gameId: this.gameId,
          taskName: this.quiz.questions[this.questionIndex].id,
        });
        console.log("wrong");
        // var currOption = localStorage.getItem('currOption')
        console.log(currOption);
        console.log(
          document.querySelector(`.option${currOption}`).style.backgroundColor
        );
        this.load = false;
        this.showErr = true;
        document.querySelector(`.option${currOption}`).style.backgroundColor =
          "red";
        var timeout = setTimeout(() => {
          if (!document.querySelector(`.option${currOption}`)) return;
          document.querySelector(`.option${currOption}`).style.backgroundColor =
            "black";
          document.querySelector(`.option${currOption}`).style.border =
            "#00f5af 1px solid";
          this.showErr = false;
        }, 4000);
        return;
      }
      if (this.showCorrect) {
        setTimeout(async() => {
          this.showCorrect = false;
          if (this.questionIndex < this.quiz.questions.length)
            this.questionIndex++;
          if (this.questionIndex >= this.quiz.questions.length) {
            clearInterval(this.interval);
            console.log("stop in timer", this.timer);
            console.log(this.score());
            console.log(this.$route.path.substring(1));
            console.log(+((this.score() / this.timer) * 15).toFixed(0));
            await this.$store.dispatch({
              type: "endGame",
              id: this.loggedinUser.id,
              gameId: this.gameId,
            });
          }
        }, 4000);
      } else {
        if (this.questionIndex < this.quiz.questions.length)
          this.questionIndex++;
        if (this.questionIndex >= this.quiz.questions.length) {
          clearInterval(this.interval);
          console.log("stop in timer", this.timer);
          console.log(this.score());
          console.log(this.$route.path.substring(1));
          console.log(+((this.score() / this.timer) * 15).toFixed(0));
          await this.$store.dispatch({
            type: "endGame",
            id: this.loggedinUser.id,
            gameId: this.gameId,
          });
        }
      }
      // this.showModal = true;
      // this.timeoutModal = setTimeout(() => {
      //   this.showModal = false;
      // }, 20000);
    },

    prev: function () {
      if (this.quiz.questions.length > 0) this.questionIndex--;
    },
    // Return "true" count in userResponses
    score: function () {
      var score = 0;
      console.log(this.userResponses);
      console.log(this.quiz.questions[0].responses[this.userResponses[0]]);
      score = (100 * this.userResponses.length) / this.userResponses.length;
      //   score =
      //     (100 * this.userResponses.length - 40 * this.wrongAns) /
      //     this.userResponses.length;
      return score;
    },
    clearInterval() {
      clearInterval(this.interval);
    },
    startInterval() {
      this.interval = setInterval(() => {
        this.timer++;
      }, 1000);
    },
  },
  async created() {
    this.quiz.questions = await this.getRandom(
      this.quiz.questions,
      this.quiz.questions.length
    );
    userResponseSkelaton = Array(this.quiz.questions.length).fill(null);
    this.userResponses = userResponseSkelaton;
    this.start = Date.now() + "";
    this.path = this.$route.path.substring(1) + "Quiz";
    this.startInterval();
  },
};
</script>

<style lang="scss" scoped>
$trans_duration: 0.3s;
$primary_color: #00f5af;

@import url("https://fonts.googleapis.com/css?family=Montserrat:400,400i,700");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700");

.err-message {
  color: red;
}

.correct-message {
  color: greenyellow;
}

.quiz {
  width: 100%;
  height: 100%;
}
.message-before {
  top: 100px;
  z-index: 9;
  height: 53%;
  width: 80%;
  border-radius: 12px;
  border: $primary_color 1px solid;
}

.message {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: black;
  top: 117px;
  &.top {
    top: 20px;
    z-index: 9;
    height: 75%;
    width: 80%;
    border-radius: 12px;
    border: $primary_color 1px solid;
    & > div {
      margin-top: 50px;
      max-width: 80%;
    }
  }
  & > div {
    margin-top: 10px;
    max-width: 80%;
  }
  .contant {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    padding: 9px 18px;
    transition: 0.3s;
    background-color: #00f5afcf;
    color: white;
    border: $primary_color 1px solid;
  }
}

body {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;

  height: 100vh;

  background: #cfd8dc;

  /* mocking native UI */
  cursor: default !important; /* remove text selection cursor */
  user-select: none; /* remove text selection */
  user-drag: none; /* disbale element dragging */

  display: flex;
  align-items: center;
  justify-content: center;
}

.fa-check {
  color: greenyellow;
}
.fa-times-circle {
  color: red;
}
.button {
  transition: $trans_duration;
}
.title,
.subtitle {
  font-family: Montserrat, sans-serif;
  font-weight: normal;
}
.animated {
  transition-duration: $trans_duration/2;
}

.container {
  margin: 0 0.5rem;
  color: white;
  width: 80%;
}

.questionBox {
  //   max-width: 30rem;
  width: 100%;
  //   min-height: 30rem;
  height: 100%;
  background: black;
  //   background: #fafafa;
  position: relative;
  display: flex;

  border-radius: 0.5rem;
  overflow: hidden;
  border: 2px solid #00f5b0ea;
  //   box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);

  header {
    background: black;
    // background: rgba(0, 0, 0, 0.025);
    padding: 1.5rem;
    text-align: center;
    border-bottom: 1px solid #00f5b082;
    // border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    h1 {
      font-weight: bold;
      margin-bottom: 1rem !important;
    }
    .progressContainer {
      width: 60%;
      margin: 0 auto;
      > progress {
        margin: 0;
        border-radius: 5rem;
        overflow: hidden;
        border: none;

        color: $primary_color;
        &::-moz-progress-bar {
          background: $primary_color;
        }
        &::-webkit-progress-value {
          background: $primary_color;
        }
      }
      > p {
        margin: 0;
        margin-top: 0.5rem;
      }
    }
  }
  .titleContainer,
  .err-message, 
  .correct-message {
    text-align: center;
    margin: 0 auto;
    padding: 1.5rem;
  }

  .quizForm {
    display: block;
    white-space: normal;

    height: 100%;
    width: 100%;

    .quizFormContainer {
      height: 100%;
      margin: 15px 18px;

      .field-label {
        text-align: left;
        margin-bottom: 0.5rem;
      }
    }
  }
  .quizCompleted {
    width: 100%;
    padding: 1rem;
    text-align: center;

    > .icon {
      color: #ff5252;
      font-size: 5rem;

      .is-active {
        color: #00e676;
      }
    }
  }
  .questionContainer {
    white-space: normal;

    height: 100%;
    width: 100%;

    .optionContainer {
      margin-top: 12px;
      flex-grow: 1;
      .option {
        border-radius: 290486px;
        padding: 9px 18px;
        margin: 0 18px;
        margin-bottom: 12px;
        transition: $trans_duration;
        cursor: pointer;
        background-color: rgb(0, 0, 0);
        color: white;
        // color: rgba(0, 0, 0, 0.85);
        border: #00f5af 1px solid;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row-reverse;
        &.is-selecteded {
          border-color: rgba(black, 0.25);
          background-color: #00f5af;
          //   background-color: white;
        }
        &:hover {
          background-color: #00f5b0ea;
          //   background-color: rgba(0, 0, 0, 0.1);
        }
        &:active {
          transform: scaleX(0.9);
        }
      }
    }

    .loading-container {
      height: 100px;
      & .loading {
        width: 100px;
        height: 100px;
      }
    }

    .questionFooter {
      background: rgba(0, 0, 0, 0.025);
      border-top: 1px solid #00f5b082;
      width: 100%;
      align-self: flex-end;

      .pagination {
        //padding: 10px 15px;
        margin: 15px 25px;
      }
    }
  }
}
.pagination {
  display: flex;
  justify-content: space-between;
}
.button {
  padding: 0.5rem 1rem;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 5rem;
  margin: 0 0.25rem;

  transition: 0.3s;
  border: $primary_color 2px solid;

  &:hover {
    cursor: pointer;
    background: $primary_color;
    border-color: rgba(255, 255, 255, 0.25);
  }
  &.is-active {
    background: $primary_color;
    color: black;
    border-color: transparent;

    &:hover {
      background: darken($primary_color, 10%);
    }
  }
}
.skip {
  width: 110px;
  margin-top: 25px;
  background-color: transparent;
  color: white;
  font-size: 16px;
  font-family: "regular";
}

@media screen and (min-width: 769px) {
  .questionBox {
    align-items: center;
    justify-content: center;

    .questionContainer {
      display: flex;
      flex-direction: column;
    }
  }
}

@media screen and (max-width: 768px) {
  .sidebar {
    height: auto !important;
    border-radius: 6px 6px 0px 0px;
  }
}
</style>