<template>
  <div class="height-100">
    <section v-if="ready" class="leaderboard flex column center heigth-100">
      <h2 class="main-title">Leaderboard</h2>
      <div v-if="users.length" class="leaderboard leaderboard-desktop flex column center">
        <section class="top flex align-center space-between">
          <h2 class="text-center">Leaderboard</h2>
          <h2>Top 5</h2>
        </section>
        <section class="top5 flex">
          <ul
            class="first-contact flex column mrg0 pad0"
            v-if="firstUser && firstUser.length"
          >
            <li class="mrg16" v-for="(user, idx) in firstUser" :key="user.id">
              <div
                class="avatar-img big mr10 mb10"
                v-if="user.img"
                :style="{
                  backgroundImage:
                    'url(' + require(`@/assets/img/avatars/${user.img}`) + ')',
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                }"
              />
              <img
                class="avatar-img big mr10 mb10"
                v-else
                src="@/assets/img/avatars/shutterstock_1575446116_1.png"
                alt=""
                srcset=""
              />
              <h3>
                {{ idx + 1 }}. {{ user.fullName }} -
                {{ user.totalScore.toFixed(0) }} score
              </h3>
            </li>
          </ul>
          <ul
            class="flex column four-contact"
            v-if="fourUsers && fourUsers.length"
          >
            <li
              class="flex center mb10 wrap"
              v-for="(user, idx) in fourUsers"
              :key="user.id"
            >
              <div
                class="avatar-img mr10"
                v-if="user.img"
                :style="{
                  backgroundImage:
                    'url(' + require(`@/assets/img/avatars/${user.img}`) + ')',
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                }"
              />
              <img
                class="avatar-img mr10"
                v-else
                src="@/assets/img/avatars/shutterstock_1575446116_1.png"
                alt=""
                srcset=""
              />
              <h3 class="mrg0" style="width: 275px">
                {{ idx + 2 }}. {{ user.fullName }} -
                {{ user.totalScore.toFixed(0) }} score
              </h3>
            </li>
          </ul>
        </section>

        <section class="top flex align-center space-between">
          <h2 class="text-center">Leaderboard</h2>
          <h2>Top 20</h2>
        </section>
        <ul
          class="top20 flex center wrap"
          v-if="twentyUsers && twentyUsers.length"
        >
          <li
            class="flex column center mb10"
            v-for="(user, idx) in twentyUsers"
            :key="user.id"
          >
            <div
              class="avatar-img mr10"
              v-if="user.img"
              :style="{
                backgroundImage:
                  'url(' + require(`@/assets/img/avatars/${user.img}`) + ')',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
              }"
            />
            <img
              class="avatar-img mr10"
              v-else
              src="@/assets/img/avatars/shutterstock_1575446116_1.png"
              alt=""
              srcset=""
            />
            <h3>
              {{ idx + 6 }}. {{ user.fullName }} -
              {{ user.totalScore.toFixed(0) }} score
            </h3>
          </li>
        </ul>
      </div>
      <div v-if="users.length" class="leaderboard leaderboard-mobile flex column center">
        <ul class="flex column four-contact" v-if="users && users.length">
          <li
            class="flex center mb10"
            v-for="(user, idx) in users"
            :key="user.id"
          >
            <div
              class="avatar-img mr10"
              v-if="user.img"
              :style="{
                backgroundImage:
                  'url(' + require(`@/assets/img/avatars/${user.img}`) + ')',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
              }"
            />
            <img
              class="avatar-img mr10"
              v-else
              src="@/assets/img/avatars/shutterstock_1575446116_1.png"
              alt=""
              srcset=""
            />
            <h3 class="mrg0">
              {{ idx + 1 }}. {{ user.fullName }} -
              {{ user.totalScore.toFixed(0) }} score
            </h3>
          </li>
        </ul>
      </div>
      <div v-else class="height-100 flex column center">
        <h2>Leaderboard</h2>
        <h3>There is no data for display</h3>
      </div>
    </section>
    <div v-else class="loading-container flex column center height-100">
      <img :src="require(`@/assets/img/loading.gif`)" alt="" srcset="" />
      <p>Loading...</p>
    </div>
  </div>
</template>

<script>
// import socketService from "@/service/socket-service.js";

export default {
  name: "leaderboard",
  data() {
    return {
      users: [],
      firstUser: [],
      fourUsers: [],
      twentyUsers: [],
      interval: null,
      ready: true,
    };
  },
  methods: {
    async getUsers() {
      var usersStore = [...this.$store.getters.users];
      if (!usersStore.length) return;
      var usersToDisplay = JSON.parse(JSON.stringify(usersStore));
      usersToDisplay = usersToDisplay.filter((user) => {
        return user.totalScore;
      });
      if (!usersToDisplay.length) return;
      await usersToDisplay.sort(function (a, b) {
        return b.totalScore - a.totalScore;
      });
      this.users = [];
      this.firstUser = [];
      this.fourUsers = [];
      this.twentyUsers = [];
      this.users = JSON.parse(JSON.stringify(usersToDisplay));
      this.firstUser.push(usersToDisplay.shift());
      this.fourUsers = usersToDisplay.splice(0, 4);
      this.twentyUsers = usersToDisplay.splice(0, 15);
    },
  },
  computed: {
    loggedinUser() {
      return this.$store.getters.loggedinUser;
    },
    scenarioUsers() {
      var users = this.users.filter((user) => {
        return user.scores && user.scores.scenario;
      });
      return users;
    },
    cyberRoomUsers() {
      var users = this.users.filter((user) => {
        return user.scores && user.scores["cyber-room"];
      });
      return users;
    },
  },
  destroyed() {
    clearInterval(this.interval);
  },
  async created() {
    if (!this.loggedinUser) {
      this.$message({
        showClose: true,
        message: `Login first!`,
        type: "warning",
        duration: 1500,
      });
      window.location =
        "https://demo-newledge.auth.us-east-2.amazoncognito.com/login?client_id=3ijg2b3k5i7epp88779v9l7p1q&response_type=token&scope=phone+email+openid+aws.cognito.signin.user.admin+profile&redirect_uri=https://demo.new-ledge.com/";
    } else {
      this.ready = false;
      await this.$store.dispatch({ type: "loadUsers" });
      this.getUsers();
      this.ready = true;
      // socketService.on("updatesUsers", async () => {
      //   console.log('updatesUsers');
      //   await this.$store.dispatch({ type: "loadUsers" });
      //   this.getUsers();
      // });
      this.interval = setInterval(async () => {
        await this.$store.dispatch({ type: "loadUsers" });
        this.getUsers();
      }, 5000);
    }
  },
};
</script>

<style lang="scss" scoped>
.leaderboard {
  width: 100%;
}
ul {
  list-style-type: none;
  align-items: start;
  margin-left: 0;
}
.avatar-img {
  width: 85px;
  height: 85px;
  border-radius: 50%;
  border: 2pt solid #07eda8;
  border-radius: 12pt;
  box-shadow: 0 0 5px #07eda8;
  &.big {
    width: 150px;
    height: 150px;
  }
}
.top {
  width: 80%;
  border-bottom: 2px solid;
  & > * {
    margin: 0;
  }
}
.top20 {
  & > li {
    margin: 10px;
  }
}
.top5 {
  width: 80%;
  & > :first-child {
    width: 40%;
  }
}
</style>