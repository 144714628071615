import { oktaService } from '../../service/okta-service.js'


export const oktaStore = {
    state: {
        oktaUsers: []
    },
    getters: {
        oktaUsers(state) {
            return state.oktaUsers;
        }
    },
    mutations: {
        setOktaUsers(state, { users }) {
            state.oktaUsers = users;
        },
        getOktaUsers(state) {
            return state.oktaUsers
        }
    },
    actions: {
        async loadOktaUsers(context) {
            const users = await oktaService.getOktaUsers();
            context.commit({ type: 'setOktaUsers', users })
        },
        checkRegistretionOktaUsers() {
            var unregisteredOktaUsers = []
            var registeredOktaUsers = []
            var users = [...this.state.userStore.users]
            var oktaUsers = [...this.state.oktaStore.oktaUsers]
            oktaUsers.forEach(async(oktaUser) => {
                var isRegister = false
                users.forEach(user => {
                    if (oktaUser.profile.email === user.email) {
                        registeredOktaUsers.push({ user, oktaUser })
                        isRegister = true
                        return
                    }
                })
                if (!isRegister) {
                    unregisteredOktaUsers.push(oktaUser)
                }
            })
            return { unregisteredOktaUsers, registeredOktaUsers }
        }
    }
}