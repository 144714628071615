import { slackService } from '../../service/slack-service.js'


export const slackStore = {
    state: {
        slackUsers: []
    },
    getters: {
        slackUsers(state) {
            return state.slackUsers;
        }
    },
    mutations: {
        setSlackUsers(state, { users }) {
            state.slackUsers = users;
        },
        getSlackUsers(state) {
            return state.slackUsers
        }
    },
    actions: {
        async loadSlackUsers(context) {
            const users = await slackService.getSlackUsers();
            context.commit({ type: 'setSlackUsers', users })
        },
        async sendSlackMessage(context, { channels, text }) {
            const res = await slackService.sendSlackMessage(channels, text);
            return res
        },
        async sendWorkspaceSlackMessage(context, { text }) {
            try {
                const users = [...this.state.slackStore.slackUsers]
                var usersIds = []
                users.forEach((user) => {
                    usersIds.push(user.id)
                })
                const res = await slackService.sendSlackMessage(usersIds, text);
                return res
            } catch (err) {
                console.log(err);
                return err
            }
        },
        checkRegistretionUsers() {
            var unregisteredUsers = []
            var registeredUsers = []
            var users = [...this.state.userStore.users]
            var slackUsers = [...this.state.slackStore.slackUsers]
            slackUsers.forEach(async(slackUser) => {
                var isRegister = false
                users.forEach(user => {
                    if (slackUser.profile.email === user.email) {
                        registeredUsers.push({ user, slackUser })
                        isRegister = true
                        return
                    }
                })
                if (!isRegister) {
                    unregisteredUsers.push(slackUser)
                }
            })
            return { unregisteredUsers, registeredUsers }
        },
        async getSlackUsersIddByEmail(context, { emails }) {
            var ids = []
            var notFoundEmails = []
            const resIds = await slackService.getSlackUsersIddByEmail(emails);
            resIds.forEach((id) => {
                if (!id.error) {
                    ids.push(id)
                } else {
                    notFoundEmails.push(id)
                }
            });
            return { ids, notFoundEmails }
            // const ids = await slackService.getSlackUsersIddByEmail(email);
            // return ids
        },
        async slackValidation() {
            try {
                return await slackService.slackValidation();
            } catch (err) {
                return { err }
            }
        }
    }
}