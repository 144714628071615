import httpService from './http-service.js'

export const slackService = {
    getSlackUsers,
    getSlackUsersIddByEmail,
    sendSlackMessage,
    slackValidation
}

function getSlackUsers() {
    return httpService.get('slackusers')
        // return httpService.slackGet('users.list', null, slackToken)
}

function getSlackUsersIddByEmail(email) {
    const body = {
        "email": email
    }
    return httpService.post('slackuseridbyemail', body)
        // return httpService.slackGet('users.list', null, slackToken)
}

function sendSlackMessage(channels, text) {
    const body = {
        "channel": channels,
        "text": text
    }
    return httpService.post('sendslackmessage', body)
        // return httpService.slackPost('chat.postMessage', body, slackToken)
}

function slackValidation() {
    return httpService.get('slackvalidation')
}