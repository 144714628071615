<template>
  <div class="height-100">
    <div v-if="ready" class="dashboard flex column center">
      <h2 v-if="loggedinUser && loggedinUser.isAdmin" class="text-center">
        Dashboard Admin
      </h2>
      <h2 v-else class="text-center">Dashboard</h2>
      <div class="options-top flex column center">
        <div class="left flex center">
          <p>Threshold:</p>
          <el-input
            class="threshold-input"
            v-model="minValue"
            @change="changeMinValue"
          ></el-input>
          <div class="icon flex column center">
            <i
              v-if="loggedinUser && loggedinUser.isAdmin"
              @click="() => downloadCSV(usersToDisplay)"
              class="hover-pointer fas fa-file-download export-data"
              style="
                font-size: 30px;
                margin: 10px;
                color: rgba(0, 245, 175, 0.85);
              "
            ></i>
            <i
              v-else
              @click="() => downloadCSV(loggedinUserCSV)"
              class="hover-pointer fas fa-file-download export-data"
              style="
                font-size: 30px;
                margin: 10px;
                color: rgba(0, 245, 175, 0.85);
              "
            ></i>
            <p v-if="showExportData">Export Data</p>
          </div>
        </div>
        <!-- <div class="flex center wrap">
          <el-select
            v-model="currDivision"
            @change="changeDivision"
            placeholder="Select division"
          >
            <el-option v-for="item in divisions" :key="item" :value="item">
            </el-option>
          </el-select>
          <el-select
            v-model="currDepartment"
            @change="changeDepartment"
            placeholder="Select department"
          >
            <el-option
              v-for="item in departments[currDivision]"
              :key="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div> -->
      </div>
      <div v-if="ready" class="flex center wrap">
        <div
          v-if="finalThreats.length && loggedinUser && loggedinUser.isAdmin"
          class="threats flex center wrap"
        >
          <circleProgress
            v-for="threat in finalThreats"
            :key="threat.name"
            :amount="threat.avg"
            :type="threat.name"
            :icon="threat.icon"
            :max="threat.maxAvg"
            :minValue="getMinValue"
          ></circleProgress>
        </div>
        <div
          v-if="
            finalThreatsUser.length && loggedinUser && !loggedinUser.isAdmin
          "
          class="threats flex center wrap"
        >
          <circleProgress
            v-for="threat in finalThreatsUser"
            :key="threat.name"
            :amount="threat.avg"
            :type="threat.name"
            :icon="threat.icon"
            :max="threat.maxAvg"
            :minValue="getMinValue"
          ></circleProgress>
        </div>
        <div
          v-if="!finalThreatsUser.length && !finalThreats.length"
          class="minHeight-100 flex column center mb16"
        >
          <h2>There is no data for display</h2>
        </div>
      </div>
      <div
        v-if="loggedinUser && loggedinUser.isAdmin"
        class="flex column center width-100 wrap"
      >
        <pagination-cmp
          :exampleItems="usersToDisplay"
          status="Dashboard"
        ></pagination-cmp>
      </div>
    </div>
    <div v-else class="loading-container flex column center height-100">
      <img :src="require(`@/assets/img/loading.gif`)" alt="" srcset="" />
      <p>Loading...</p>
    </div>
  </div>
</template>

<script>
import paginationCmp from "@/cmp/paginationCmp";
import circleProgress from "@/cmp/circle-progress";
const jsonexport = require("jsonexport");

export default {
  name: "dashboard",
  components: {
    paginationCmp,
    circleProgress,
  },
  data() {
    return {
      currDepartment: null,
      currDivision: null,
      divisions: ["All"],
      departments: {},
      usersByRole: {},
      unregisteredOktaUsers: [],
      registeredOktaUsers: [],
      unregisteredUsers: [],
      registeredUsers: [],
      slackReminderMessage: null,
      slackMessage: null,
      finalThreatsUser: [],
      loggedinUserCSV: [],
      ready: true,
      minValue: 80,
      finalThreats: [],
      usersToDisplay: [],
      showSyso: false,
      showExportData: false,
      radio: false,
      options: [
        {
          value: "Today",
          label: "Today",
        },
        {
          value: "Yesterday",
          label: "Yesterday",
        },
        {
          value: "7 days ago",
          label: "7 days ago",
        },
        {
          value: "30 days ago",
          label: "30 days ago",
        },
      ],
      value: "Today",
    };
  },
  computed: {
    users() {
      return this.$store.getters.users;
    },
    oktaUsers() {
      return this.$store.getters.oktaUsers;
    },
    slackUsers() {
      return this.$store.getters.slackUsers;
    },
    loggedinUser() {
      return this.$store.getters.loggedinUser;
    },
    getMinValue() {
      return this.minValue;
    },
  },
  methods: {
    downloadCSV(stats) {
      jsonexport(stats, function (err, csv) {
        if (err) return console.error(err);
        var hiddenElement = document.createElement("a");
        hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
        hiddenElement.target = "_blank";

        //provide the name for the CSV file to be downloaded
        if (stats.length === 1) {
          hiddenElement.download = "My DB";
        } else {
          hiddenElement.download = "Users DB";
        }
        hiddenElement.click();
      });
    },
    changeDivision() {
      if (this.currDivision === "All") this.getDashboard(usersByDivision);
      else {
        var usersByDivision = this.users.filter((user) => {
          return user.division === this.currDivision;
        });
        this.getDashboard(usersByDivision);
      }
      this.currDepartment = null;
    },
    changeDepartment() {
      if (this.currDepartment === "All") {
        var usersByDepartment = this.users.filter((user) => {
          return user.division === this.currDivision;
        });
        this.getDashboard(usersByDepartment);
      } else {
        usersByDepartment = this.users.filter((user) => {
          return (
            user.division === this.currDivision &&
            user.department === this.currDepartment
          );
        });
        this.getDashboard(usersByDepartment);
      }
    },
    flatten(obj, path = []) {
      return Object.keys(obj).reduce((result, prop) => {
        if (typeof obj[prop] !== "object") {
          result[path.concat(prop).join(".")] = obj[prop];
          return result;
        }
        return Object.assign(
          result,
          this.flatten(obj[prop] ? obj[prop] : obj, path.concat(prop), result)
        );
      }, {});
    },
    getDashboard(users) {
      if (!users) {
        users = this.$store.getters.users;
      }
      console.log(users);
      if (!users) return;
      var finalThreats = [];
      this.finalThreats = [];
      this.usersToDisplay = [];
      var usersThreats = [];
      var cyberRoomCounter = 0;
      var cyberRoomNotFinishedCounter = 0;
      var scenarioCounter = 0;
      var scenarioNotFinishedCounter = 0;
      var longQuizCounter = 0;
      var longQuizNotFinishedCounter = 0;
      var sqlInjectionCounter = 0;
      var sqlInjectionNotFinishedCounter = 0;
      var firstVideoQuizCounter = 0;
      var firstVideoQuizNotFinishedCounter = 0;
      var dailyQuiz1Counter = 0;
      var dailyQuiz1NotFinishedCounter = 0;
      users.forEach((user) => {
        cyberRoomCounter = 0;
        cyberRoomNotFinishedCounter = 0;
        scenarioCounter = 0;
        scenarioNotFinishedCounter = 0;
        longQuizCounter = 0;
        longQuizNotFinishedCounter = 0;
        sqlInjectionCounter = 0;
        sqlInjectionNotFinishedCounter = 0;
        firstVideoQuizCounter = 0;
        firstVideoQuizNotFinishedCounter = 0;
        dailyQuiz1Counter = 0;
        dailyQuiz1NotFinishedCounter = 0;
        // if (!user.scores && !user.quizScores) return;
        var threats = {};
        if (user.scores) {
          user.scores.forEach((game) => {
            if (!game.tasks) return;
            if (game.endAt && game.type === "cyber-room") cyberRoomCounter++;
            else if (game.type === "cyber-room") cyberRoomNotFinishedCounter++;
            if (game.endAt && game.type === "scenario") scenarioCounter++;
            else if (game.type === "scenario") scenarioNotFinishedCounter++;
            if (game.endAt && game.type === "long-Quiz") longQuizCounter++;
            else if (game.type === "long-Quiz") longQuizNotFinishedCounter++;
            if (game.endAt && game.type === "sql-injection")
              sqlInjectionCounter++;
            else if (game.type === "sql-injection")
              sqlInjectionNotFinishedCounter++;
            if (game.endAt && game.type === "first-video-quiz")
              firstVideoQuizCounter++;
            else if (game.type === "first-video-quiz")
              firstVideoQuizNotFinishedCounter++;
            if (game.endAt && game.type === "daily-quiz-1") dailyQuiz1Counter++;
            else if (game.type === "daily-quiz-1")
              dailyQuiz1NotFinishedCounter++;

            game.tasks.forEach((task) => {
              if (!threats[task.threat]) {
                threats[task.threat] = [];
                threats[task.threat].push(task);
              } else {
                threats[task.threat].push(task);
              }
              if (!finalThreats[task.threat]) {
                finalThreats[task.threat] = {};
                if (!finalThreats[task.threat].maxScore)
                  finalThreats[task.threat].maxScore = 0;
                if (
                  task.mission === "PC3Code" ||
                  task.mission === "Shredder" ||
                  task.mission === "QR"
                ) {
                  finalThreats[task.threat].maxScore = 75;
                } else if (
                  task.mission === "PC3" ||
                  task.mission === "FamilyBoard" ||
                  task.mission === "SciFi3" ||
                  task.mission === "Intro" ||
                  task.mission === "ServersRoom1"
                ) {
                  finalThreats[task.threat].maxScore = 160;
                } else if (
                  task.mission === "London" ||
                  task.mission === "Israel3" ||
                  task.mission === "DocksInTrash" ||
                  task.mission === "Israel2" ||
                  task.mission === "Israel1" ||
                  task.mission === "PhishingEmail" ||
                  task.mission === "ControlRoom1"
                ) {
                  finalThreats[task.threat].maxScore = 180;
                } else if (
                  task.type === "long-Quiz" ||
                  task.type === "first-video-quiz" ||
                  task.type === "daily-quiz-1" ||
                  task.mission === "SciFi35" ||
                  task.mission === "WorkerTag" ||
                  task.mission === "Door2" ||
                  task.mission === "ServersRoom2" ||
                  task.mission === "ServersRoom3"
                ) {
                  finalThreats[task.threat].maxScore = 260;
                } else if (task.mission === "Tunnel") {
                  finalThreats[task.threat].maxScore = 500;
                }
                {
                  // if (task.type === "cyber-room") {
                  //   if (task.idx === 1) {
                  //     finalThreats[task.threat].maxScore = 160;
                  //   } else {
                  //     finalThreats[task.threat].maxScore = 240;
                  //   }
                  // } else {
                  //   if (task.idx === 1) {
                  //     finalThreats[task.threat].maxScore = 290;
                  //   } else if (task.idx === 2) {
                  //     finalThreats[task.threat].maxScore = 140;
                  //   } else if (task.idx === 3) {
                  //     finalThreats[task.threat].maxScore = 200;
                  //   } else if (task.idx === 4) {
                  //     finalThreats[task.threat].maxScore = 100;
                  //   } else {
                  //     finalThreats[task.threat].maxScore = 500;
                  //   }
                  // }
                }
                finalThreats[task.threat].totalScore = task.score;
                finalThreats[task.threat].count = 1;
                if (task.threat === task.threat.toUpperCase()) {
                  finalThreats[task.threat].name = task.threat;
                } else {
                  finalThreats[task.threat].name = task.threat
                    .replace(/\w\S*/g, (w) =>
                      w.replace(/^\w/, (c) => c.toUpperCase())
                    )
                    .match(/[A-Z][a-z]+|[0-9]+/g)
                    .join(" ");
                }
                if (task.threat === "phishing") {
                  finalThreats[task.threat].icon = "far fa-id-card";
                } else if (task.threat === "vishing") {
                  finalThreats[task.threat].icon = "fas fa-microphone";
                } else if (task.threat === "smishing") {
                  finalThreats[task.threat].icon = "far fa-comment-alt";
                } else if (task.threat === "publicNetwork") {
                  // finalThreats[task.threat].name = "Public Network";
                  finalThreats[task.threat].icon = "fas fa-wifi";
                } else if (task.threat === "securePasswords") {
                  // finalThreats[task.threat].name = "Secure Passwords";
                  finalThreats[task.threat].icon = "fas fa-lock";
                } else if (task.threat === "authorizedSoftware") {
                  // finalThreats[task.threat].name = "Authorized Software";
                  finalThreats[task.threat].icon = "fas fa-shield-alt";
                } else if (task.threat === "physicalSecurity") {
                  // finalThreats[task.threat].name = "Physical Security";
                  finalThreats[task.threat].icon = "fas fa-briefcase";
                } else if (task.threat === "GeneralAwareness") {
                  // finalThreats[task.threat].name = "General Awerness";
                  finalThreats[task.threat].icon = "fas fa-cogs";
                } else if (task.threat === "none") {
                  delete finalThreats[task.threat];
                } else if (task.threat === "SOC") {
                  finalThreats[task.threat].icon = "fas fa-headset";
                } else finalThreats[task.threat].icon = "far fa-comment-alt";
              } else {
                if (
                  task.mission === "PC3Code" ||
                  task.mission === "Shredder" ||
                  task.mission === "QR"
                ) {
                  finalThreats[task.threat].maxScore += 75;
                } else if (
                  task.mission === "PC3" ||
                  task.mission === "FamilyBoard" ||
                  task.mission === "SciFi3" ||
                  task.mission === "Intro" ||
                  task.mission === "ServersRoom1"
                ) {
                  finalThreats[task.threat].maxScore += 160;
                } else if (
                  task.mission === "London" ||
                  task.mission === "Israel3" ||
                  task.mission === "DocksInTrash" ||
                  task.mission === "Israel2" ||
                  task.mission === "Israel1" ||
                  task.mission === "PhishingEmail" ||
                  task.mission === "ControlRoom1"
                ) {
                  finalThreats[task.threat].maxScore += 180;
                } else if (
                  task.type === "long-Quiz" ||
                  task.type === "first-video-quiz" ||
                  task.type === "daily-quiz-1" ||
                  task.mission === "SciFi35" ||
                  task.mission === "WorkerTag" ||
                  task.mission === "Door2" ||
                  task.mission === "ServersRoom2" ||
                  task.mission === "ServersRoom3"
                ) {
                  finalThreats[task.threat].maxScore += 260;
                } else if (task.mission === "Tunnel") {
                  finalThreats[task.threat].maxScore += 500;
                }
                {
                  // if (task.type === "cyber-room") {
                  //   if (task.idx === 1) {
                  //     finalThreats[task.threat].maxScore += 160;
                  //   } else {
                  //     finalThreats[task.threat].maxScore += 240;
                  //   }
                  // } else {
                  //   if (task.idx === 1) {
                  //     finalThreats[task.threat].maxScore += 290;
                  //   } else if (task.idx === 2) {
                  //     finalThreats[task.threat].maxScore += 140;
                  //   } else if (task.idx === 3) {
                  //     finalThreats[task.threat].maxScore += 200;
                  //   } else if (task.idx === 4) {
                  //     finalThreats[task.threat].maxScore += 100;
                  //   } else {
                  //     finalThreats[task.threat].maxScore += 500;
                  //   }
                  // }
                }
                finalThreats[task.threat].totalScore += task.score;
                finalThreats[task.threat].count++;
              }
            });
          });
        }
        var userThreats = {
          fullName: user.fullName,
          id: user.id,
          threats,
          email: user.email,
          quizScores: user.quizScores || null,
          totalScore: user.totalScore || null,
          cyberRoomCounter,
          cyberRoomNotFinishedCounter,
          scenarioCounter,
          scenarioNotFinishedCounter,
          longQuizCounter,
          longQuizNotFinishedCounter,
          sqlInjectionCounter,
          sqlInjectionNotFinishedCounter,
          firstVideoQuizCounter,
          firstVideoQuizNotFinishedCounter,
          dailyQuiz1Counter,
          dailyQuiz1NotFinishedCounter,
        };
        usersThreats.push(userThreats);
      });
      for (const threatKey in finalThreats) {
        finalThreats[threatKey].avg = (
          finalThreats[threatKey].totalScore / finalThreats[threatKey].count
        ).toFixed(0);
        finalThreats[threatKey].maxAvg = (
          finalThreats[threatKey].maxScore / finalThreats[threatKey].count
        ).toFixed(0);
        this.finalThreats.push(finalThreats[threatKey]);
      }
      console.log(this.finalThreats);
      console.log(usersThreats);
      usersThreats.forEach(async (user) => {
        var flattenUser = {};
        // flattenUser.id = user.id;
        flattenUser.fullName = user.fullName;
        flattenUser.email = user.email;
        flattenUser.totalScore = user.totalScore
          ? user.totalScore.toFixed(0)
          : 0;
        flattenUser.cyberRoomCounter = user.cyberRoomCounter;
        // flattenUser.cyberRoomNotFinishedCounter =
        //   user.cyberRoomNotFinishedCounter;
        flattenUser.scenarioCounter = user.scenarioCounter;
        // flattenUser.scenarioNotFinishedCounter =
        //   user.scenarioNotFinishedCounter;
        flattenUser.longQuizCounter = user.longQuizCounter;
        // flattenUser.longQuizNotFinishedCounter =
        //   user.longQuizNotFinishedCounter;
        flattenUser.sqlInjectionCounter = user.sqlInjectionCounter;
        // flattenUser.sqlInjectionNotFinishedCounter = user.sqlInjectionNotFinishedCounter;
        flattenUser.firstVideoQuizCounter = user.firstVideoQuizCounter;
        // flattenUser.firstVideoQuizNotFinishedCounter =
        //   user.firstVideoQuizNotFinishedCounter;
        flattenUser.dailyQuiz1Counter = user.dailyQuiz1Counter;
        // flattenUser.dailyQuiz1NotFinishedCounter =
        //   user.dailyQuiz1NotFinishedCounter;

        //  calc passed users
        if (
          (user.cyberRoomCounter > 0 || user.scenarioCounter > 0) &&
          (user.sqlInjectionCounter > 0 || user.longQuizCounter > 0)
        ) {
          flattenUser.passed = "true";
        } else {
          flattenUser.passed = "false";
        }
        flattenUser.isOktaActive = "false";

        {
          // for wrong answers

          var wrongAnswers = await this.$store.dispatch({
            type: "getWrongAnswerByParam",
            param: "userId",
            value: user.id,
          });
          if (!wrongAnswers.err && wrongAnswers.length) {
            wrongAnswers.forEach((wrongAnswer) => {
              if (
                !flattenUser[
                  wrongAnswer.type + "- Mission " + wrongAnswer.mission
                ]
              ) {
                flattenUser[
                  wrongAnswer.type + "- Mission " + wrongAnswer.mission
                ] = 1;
              } else {
                flattenUser[
                  wrongAnswer.type + "- Mission " + wrongAnswer.mission
                ]++;
              }
            });
          }
        }

        {
          // for threats details

          for (const threat in user.threats) {
            user.threats[threat].forEach((task) => {
              if (
                !flattenUser[
                  task.type +
                    " Threat:" +
                    threat +
                    " Stage:" +
                    task.stage +
                    " Mission:" +
                    task.mission
                ]
              ) {
                flattenUser[
                  task.type +
                    " Threat:" +
                    threat +
                    " Stage:" +
                    task.stage +
                    " Mission:" +
                    task.mission
                ] = {};
                if (
                  task.mission === "PC3Code" ||
                  task.mission === "Shredder" ||
                  task.mission === "QR"
                ) {
                  flattenUser[
                    task.type +
                      " Threat:" +
                      threat +
                      " Stage:" +
                      task.stage +
                      " Mission:" +
                      task.mission
                  ] = ((+task.score / 75) * 100).toFixed(0) + "%";
                } else if (
                  task.mission === "PC3" ||
                  task.mission === "FamilyBoard" ||
                  task.mission === "SciFi3" ||
                  task.mission === "Intro" ||
                  task.mission === "ServersRoom1"
                ) {
                  flattenUser[
                    task.type +
                      " Threat:" +
                      threat +
                      " Stage:" +
                      task.stage +
                      " Mission:" +
                      task.mission
                  ] = ((+task.score / 160) * 100).toFixed(0) + "%";
                } else if (
                  task.mission === "London" ||
                  task.mission === "Israel3" ||
                  task.mission === "DocksInTrash" ||
                  task.mission === "Israel2" ||
                  task.mission === "Israel1" ||
                  task.mission === "PhishingEmail" ||
                  task.mission === "ControlRoom1"
                ) {
                  flattenUser[
                    task.type +
                      " Threat:" +
                      threat +
                      " Stage:" +
                      task.stage +
                      " Mission:" +
                      task.mission
                  ] = ((+task.score / 180) * 100).toFixed(0) + "%";
                } else if (
                  task.type === "long-Quiz" ||
                  task.type === "first-video-quiz" ||
                  task.type === "daily-quiz-1" ||
                  task.mission === "SciFi35" ||
                  task.mission === "WorkerTag" ||
                  task.mission === "Door2" ||
                  task.mission === "ServersRoom2" ||
                  task.mission === "ServersRoom3"
                ) {
                  flattenUser[
                    task.type +
                      " Threat:" +
                      threat +
                      " Stage:" +
                      task.stage +
                      " Mission:" +
                      task.mission
                  ] = ((+task.score / 260) * 100).toFixed(0) + "%";
                } else if (task.mission === "Tunnel") {
                  flattenUser[
                    task.type +
                      " Threat:" +
                      threat +
                      " Stage:" +
                      task.stage +
                      " Mission:" +
                      task.mission
                  ] = ((+task.score / 500) * 100).toFixed(0) + "%";
                }
              } else {
                if (
                  task.mission === "PC3Code" ||
                  task.mission === "Shredder" ||
                  task.mission === "QR"
                ) {
                  var precent =
                    +flattenUser[
                      task.type +
                        " Threat:" +
                        threat +
                        " Stage:" +
                        task.stage +
                        " Mission:" +
                        task.mission
                    ].split("%")[0];
                  precent < (+task.score / 75) * 100
                    ? (flattenUser[
                        task.type +
                          " Threat:" +
                          threat +
                          " Stage:" +
                          task.stage +
                          " Mission:" +
                          task.mission
                      ] = ((+task.score / 75) * 100).toFixed(0) + "%")
                    : (flattenUser[
                        task.type +
                          " Threat:" +
                          threat +
                          " Stage:" +
                          task.stage +
                          " Mission:" +
                          task.mission
                      ] =
                        flattenUser[
                          task.type +
                            " Threat:" +
                            threat +
                            " Stage:" +
                            task.stage +
                            " Mission:" +
                            task.mission
                        ]);
                } else if (
                  task.mission === "PC3" ||
                  task.mission === "FamilyBoard" ||
                  task.mission === "SciFi3" ||
                  task.mission === "Intro" ||
                  task.mission === "ServersRoom1"
                ) {
                  precent =
                    +flattenUser[
                      task.type +
                        " Threat:" +
                        threat +
                        " Stage:" +
                        task.stage +
                        " Mission:" +
                        task.mission
                    ].split("%")[0];
                  precent < (+task.score / 160) * 100
                    ? (flattenUser[
                        task.type +
                          " Threat:" +
                          threat +
                          " Stage:" +
                          task.stage +
                          " Mission:" +
                          task.mission
                      ] = ((+task.score / 160) * 100).toFixed(0) + "%")
                    : (flattenUser[
                        task.type +
                          " Threat:" +
                          threat +
                          " Stage:" +
                          task.stage +
                          " Mission:" +
                          task.mission
                      ] =
                        flattenUser[
                          task.type +
                            " Threat:" +
                            threat +
                            " Stage:" +
                            task.stage +
                            " Mission:" +
                            task.mission
                        ]);
                } else if (
                  task.mission === "London" ||
                  task.mission === "Israel3" ||
                  task.mission === "DocksInTrash" ||
                  task.mission === "Israel2" ||
                  task.mission === "Israel1" ||
                  task.mission === "PhishingEmail" ||
                  task.mission === "ControlRoom1"
                ) {
                  precent =
                    +flattenUser[
                      task.type +
                        " Threat:" +
                        threat +
                        " Stage:" +
                        task.stage +
                        " Mission:" +
                        task.mission
                    ].split("%")[0];
                  precent < (+task.score / 180) * 100
                    ? (flattenUser[
                        task.type +
                          " Threat:" +
                          threat +
                          " Stage:" +
                          task.stage +
                          " Mission:" +
                          task.mission
                      ] = ((+task.score / 180) * 100).toFixed(0) + "%")
                    : (flattenUser[
                        task.type +
                          " Threat:" +
                          threat +
                          " Stage:" +
                          task.stage +
                          " Mission:" +
                          task.mission
                      ] =
                        flattenUser[
                          task.type +
                            " Threat:" +
                            threat +
                            " Stage:" +
                            task.stage +
                            " Mission:" +
                            task.mission
                        ]);
                } else if (
                  task.type === "long-Quiz" ||
                  task.type === "first-video-quiz" ||
                  task.type === "daily-quiz-1" ||
                  task.mission === "SciFi35" ||
                  task.mission === "WorkerTag" ||
                  task.mission === "Door2" ||
                  task.mission === "ServersRoom2" ||
                  task.mission === "ServersRoom3"
                ) {
                  precent =
                    +flattenUser[
                      task.type +
                        " Threat:" +
                        threat +
                        " Stage:" +
                        task.stage +
                        " Mission:" +
                        task.mission
                    ].split("%")[0];
                  precent < (+task.score / 260) * 100
                    ? (flattenUser[
                        task.type +
                          " Threat:" +
                          threat +
                          " Stage:" +
                          task.stage +
                          " Mission:" +
                          task.mission
                      ] = ((+task.score / 260) * 100).toFixed(0) + "%")
                    : (flattenUser[
                        task.type +
                          " Threat:" +
                          threat +
                          " Stage:" +
                          task.stage +
                          " Mission:" +
                          task.mission
                      ] =
                        flattenUser[
                          task.type +
                            " Threat:" +
                            threat +
                            " Stage:" +
                            task.stage +
                            " Mission:" +
                            task.mission
                        ]);
                } else if (task.mission === "Tunnel") {
                  precent =
                    +flattenUser[
                      task.type +
                        " Threat:" +
                        threat +
                        " Stage:" +
                        task.stage +
                        " Mission:" +
                        task.mission
                    ].split("%")[0];
                  precent < (+task.score / 500) * 100
                    ? (flattenUser[
                        task.type +
                          " Threat:" +
                          threat +
                          " Stage:" +
                          task.stage +
                          " Mission:" +
                          task.mission
                      ] = ((+task.score / 500) * 100).toFixed(0) + "%")
                    : (flattenUser[
                        task.type +
                          " Threat:" +
                          threat +
                          " Stage:" +
                          task.stage +
                          " Mission:" +
                          task.mission
                      ] =
                        flattenUser[
                          task.type +
                            " Threat:" +
                            threat +
                            " Stage:" +
                            task.stage +
                            " Mission:" +
                            task.mission
                        ]);
                }
              }
              // if (
              //   !flattenUser[
              //     task.type +
              //       " Threat:" +
              //       threat +
              //       " Stage:" +
              //       task.stage +
              //       " Mission:" +
              //       task.mission
              //   ]
              // ) {
              //   flattenUser[
              //     task.type +
              //       " Threat:" +
              //       threat +
              //       " Stage:" +
              //       task.stage +
              //       " Mission:" +
              //       task.mission
              //   ] = {};
              //   if (task.type === "cyber-room") {
              //     if (task.idx === 1) {
              //       flattenUser[
              //         task.type +
              //           " Threat:" +
              //           threat +
              //           " Stage:" +
              //           task.stage +
              //           " Mission:" +
              //           task.mission
              //       ] = ((+task.score / 160) * 100).toFixed(0) + "%";
              //     } else {
              //       flattenUser[
              //         task.type +
              //           " Threat:" +
              //           threat +
              //           " Stage:" +
              //           task.stage +
              //           " Mission:" +
              //           task.mission
              //       ] = ((+task.score / 240) * 100).toFixed(0) + "%";
              //     }
              //   } else {
              //     if (task.idx === 1) {
              //       flattenUser[
              //         task.type +
              //           " Threat:" +
              //           threat +
              //           " Stage:" +
              //           task.stage +
              //           " Mission:" +
              //           task.mission
              //       ] = ((+task.score / 290) * 100).toFixed(0) + "%";
              //     } else if (task.idx === 2) {
              //       flattenUser[
              //         task.type +
              //           " Threat:" +
              //           threat +
              //           " Stage:" +
              //           task.stage +
              //           " Mission:" +
              //           task.mission
              //       ] = ((+task.score / 140) * 100).toFixed(0) + "%";
              //     } else if (task.idx === 3) {
              //       flattenUser[
              //         task.type +
              //           " Threat:" +
              //           threat +
              //           " Stage:" +
              //           task.stage +
              //           " Mission:" +
              //           task.mission
              //       ] = ((+task.score / 200) * 100).toFixed(0) + "%";
              //     } else if (task.idx === 4) {
              //       flattenUser[
              //         task.type +
              //           " Threat:" +
              //           threat +
              //           " Stage:" +
              //           task.stage +
              //           " Mission:" +
              //           task.mission
              //       ] = ((+task.score / 100) * 100).toFixed(0) + "%";
              //     } else {
              //       flattenUser[
              //         task.type +
              //           " Threat:" +
              //           threat +
              //           " Stage:" +
              //           task.stage +
              //           " Mission:" +
              //           task.mission
              //       ] = ((+task.score / 500) * 100).toFixed(0) + "%";
              //     }
              //   }
              // } else {
              //   if (task.type === "cyber-room") {
              //     if (task.idx === 1) {
              //       var precent =
              //         +flattenUser[
              //           task.type +
              //             " Threat:" +
              //             threat +
              //             " Stage:" +
              //             task.stage +
              //             " Mission:" +
              //             task.mission
              //         ].split("%")[0];
              //       precent < (+task.score / 160) * 100
              //         ? (flattenUser[
              //             task.type +
              //               " Threat:" +
              //               threat +
              //               " Stage:" +
              //               task.stage +
              //               " Mission:" +
              //               task.mission
              //           ] = ((+task.score / 160) * 100).toFixed(0) + "%")
              //         : (flattenUser[
              //             task.type +
              //               " Threat:" +
              //               threat +
              //               " Stage:" +
              //               task.stage +
              //               " Mission:" +
              //               task.mission
              //           ] =
              //             flattenUser[
              //               task.type +
              //                 " Threat:" +
              //                 threat +
              //                 " Stage:" +
              //                 task.stage +
              //                 " Mission:" +
              //                 task.mission
              //             ]);
              //     } else {
              //       precent =
              //         +flattenUser[
              //           task.type +
              //             " Threat:" +
              //             threat +
              //             " Stage:" +
              //             task.stage +
              //             " Mission:" +
              //             task.mission
              //         ].split("%")[0];
              //       precent < (+task.score / 240) * 100
              //         ? (flattenUser[
              //             task.type +
              //               " Threat:" +
              //               threat +
              //               " Stage:" +
              //               task.stage +
              //               " Mission:" +
              //               task.mission
              //           ] = ((+task.score / 240) * 100).toFixed(0) + "%")
              //         : (flattenUser[
              //             task.type +
              //               " Threat:" +
              //               threat +
              //               " Stage:" +
              //               task.stage +
              //               " Mission:" +
              //               task.mission
              //           ] =
              //             flattenUser[
              //               task.type +
              //                 " Threat:" +
              //                 threat +
              //                 " Stage:" +
              //                 task.stage +
              //                 " Mission:" +
              //                 task.mission
              //             ]);
              //     }
              //   } else {
              //     if (task.idx === 1) {
              //       precent =
              //         +flattenUser[
              //           task.type +
              //             " Threat:" +
              //             threat +
              //             " Stage:" +
              //             task.stage +
              //             " Mission:" +
              //             task.mission
              //         ].split("%")[0];
              //       precent < (+task.score / 290) * 100
              //         ? (flattenUser[
              //             task.type +
              //               " Threat:" +
              //               threat +
              //               " Stage:" +
              //               task.stage +
              //               " Mission:" +
              //               task.mission
              //           ] = ((+task.score / 290) * 100).toFixed(0) + "%")
              //         : (flattenUser[
              //             task.type +
              //               " Threat:" +
              //               threat +
              //               " Stage:" +
              //               task.stage +
              //               " Mission:" +
              //               task.mission
              //           ] =
              //             flattenUser[
              //               task.type +
              //                 " Threat:" +
              //                 threat +
              //                 " Stage:" +
              //                 task.stage +
              //                 " Mission:" +
              //                 task.mission
              //             ]);
              //     } else if (task.idx === 2) {
              //       precent =
              //         +flattenUser[
              //           task.type +
              //             " Threat:" +
              //             threat +
              //             " Stage:" +
              //             task.stage +
              //             " Mission:" +
              //             task.mission
              //         ].split("%")[0];
              //       precent < (+task.score / 140) * 100
              //         ? (flattenUser[
              //             task.type +
              //               " Threat:" +
              //               threat +
              //               " Stage:" +
              //               task.stage +
              //               " Mission:" +
              //               task.mission
              //           ] = ((+task.score / 140) * 100).toFixed(0) + "%")
              //         : (flattenUser[
              //             task.type +
              //               " Threat:" +
              //               threat +
              //               " Stage:" +
              //               task.stage +
              //               " Mission:" +
              //               task.mission
              //           ] =
              //             flattenUser[
              //               task.type +
              //                 " Threat:" +
              //                 threat +
              //                 " Stage:" +
              //                 task.stage +
              //                 " Mission:" +
              //                 task.mission
              //             ]);
              //     } else if (task.idx === 3) {
              //       precent =
              //         +flattenUser[
              //           task.type +
              //             " Threat:" +
              //             threat +
              //             " Stage:" +
              //             task.stage +
              //             " Mission:" +
              //             task.mission
              //         ].split("%")[0];
              //       precent < (+task.score / 200) * 100
              //         ? (flattenUser[
              //             task.type +
              //               " Threat:" +
              //               threat +
              //               " Stage:" +
              //               task.stage +
              //               " Mission:" +
              //               task.mission
              //           ] = ((+task.score / 200) * 100).toFixed(0) + "%")
              //         : (flattenUser[
              //             task.type +
              //               " Threat:" +
              //               threat +
              //               " Stage:" +
              //               task.stage +
              //               " Mission:" +
              //               task.mission
              //           ] =
              //             flattenUser[
              //               task.type +
              //                 " Threat:" +
              //                 threat +
              //                 " Stage:" +
              //                 task.stage +
              //                 " Mission:" +
              //                 task.mission
              //             ]);
              //     } else if (task.idx === 4) {
              //       precent =
              //         +flattenUser[
              //           task.type +
              //             " Threat:" +
              //             threat +
              //             " Stage:" +
              //             task.stage +
              //             " Mission:" +
              //             task.mission
              //         ].split("%")[0];
              //       precent < (+task.score / 100) * 100
              //         ? (flattenUser[
              //             task.type +
              //               " Threat:" +
              //               threat +
              //               " Stage:" +
              //               task.stage +
              //               " Mission:" +
              //               task.mission
              //           ] = ((+task.score / 100) * 100).toFixed(0) + "%")
              //         : (flattenUser[
              //             task.type +
              //               " Threat:" +
              //               threat +
              //               " Stage:" +
              //               task.stage +
              //               " Mission:" +
              //               task.mission
              //           ] =
              //             flattenUser[
              //               task.type +
              //                 " Threat:" +
              //                 threat +
              //                 " Stage:" +
              //                 task.stage +
              //                 " Mission:" +
              //                 task.mission
              //             ]);
              //     } else {
              //       precent =
              //         +flattenUser[
              //           task.type +
              //             " Threat:" +
              //             threat +
              //             " Stage:" +
              //             task.stage +
              //             " Mission:" +
              //             task.mission
              //         ].split("%")[0];
              //       precent < (+task.score / 500) * 100
              //         ? (flattenUser[
              //             task.type +
              //               " Threat:" +
              //               threat +
              //               " Stage:" +
              //               task.stage +
              //               " Mission:" +
              //               task.mission
              //           ] = ((+task.score / 500) * 100).toFixed(0) + "%")
              //         : (flattenUser[
              //             task.type +
              //               " Threat:" +
              //               threat +
              //               " Stage:" +
              //               task.stage +
              //               " Mission:" +
              //               task.mission
              //           ] =
              //             flattenUser[
              //               task.type +
              //                 " Threat:" +
              //                 threat +
              //                 " Stage:" +
              //                 task.stage +
              //                 " Mission:" +
              //                 task.mission
              //             ]);
              //     }
              //   }
              // }
            });
          }
          // for quiz details
          // if (user.quizScores) {
          //   user.quizScores.forEach((quiz) => {
          //     if (quiz.done) {
          //       flattenUser[quiz.type + "(" + quiz.gameId + ")"] = quiz.score;
          //       if (!flattenUser[quiz.type + " Counter"])
          //         flattenUser[quiz.type + " Counter"] = 1;
          //       else flattenUser[quiz.type + " Counter"]++;
          //     } else {
          //       flattenUser[quiz.type + "(" + quiz.gameId + ")"] = "not finished";
          //     }
          //   });
          // }
        }
        console.log(flattenUser);
        if (this.loggedinUser.email === flattenUser.email) {
          this.loggedinUserCSV = [flattenUser];
        }
        this.usersToDisplay.push(flattenUser);
      });
      this.unregisteredOktaUsers.forEach((unregisteredOktaUser) => {
        let flattenUser = {};
        flattenUser.passed = "false";
        flattenUser.email = unregisteredOktaUser.profile.email;
        flattenUser.fullName = unregisteredOktaUser.profile.displayName
          ? unregisteredOktaUser.profile.displayName
          : unregisteredOktaUser.profile.firstName +
            " " +
            unregisteredOktaUser.profile.lastName;
        flattenUser.isOktaActive = "true";
        flattenUser.division = unregisteredOktaUser.profile.division;
        flattenUser.department = unregisteredOktaUser.profile.department;
        flattenUser.manager = unregisteredOktaUser.profile.manager;
        flattenUser.Account_Type = unregisteredOktaUser.profile.Account_Type;
        flattenUser.Entity = unregisteredOktaUser.profile.Entity;
        this.usersToDisplay.push(flattenUser);
      });
      this.registeredOktaUsers.forEach((registeredOktaUser) => {
        this.usersToDisplay.forEach((flattenUser, idx) => {
          if (flattenUser.email === registeredOktaUser.oktaUser.profile.email) {
            this.usersToDisplay[idx].isOktaActive = "true";
            this.usersToDisplay[idx].division =
              registeredOktaUser.oktaUser.profile.division;
            this.usersToDisplay[idx].department =
              registeredOktaUser.oktaUser.profile.department;
            this.usersToDisplay[idx].manager =
              registeredOktaUser.oktaUser.profile.manager;
            this.usersToDisplay[idx].Account_Type =
              registeredOktaUser.oktaUser.profile.Account_Type;
            this.usersToDisplay[idx].Entity =
              registeredOktaUser.oktaUser.profile.Entity;
          }
        });
      });
      console.log(this.usersToDisplay);
    },
    getDashboardUser() {
      var users = [this.loggedinUser];
      console.log(users);
      if (!users) return;
      var finalThreats = [];
      var usersThreats = [];
      users.forEach((user) => {
        // if (!user.scores) return;
        var threats = {};
        if (user.scores) {
          user.scores.forEach((game) => {
            if (!game.tasks) return;
            game.tasks.forEach((task) => {
              if (!threats[task.threat]) {
                threats[task.threat] = [];
                threats[task.threat].push(task);
              } else {
                threats[task.threat].push(task);
              }
              if (!finalThreats[task.threat]) {
                finalThreats[task.threat] = {};
                if (!finalThreats[task.threat].maxScore)
                  finalThreats[task.threat].maxScore = 0;
                if (
                  task.mission === "PC3Code" ||
                  task.mission === "Shredder" ||
                  task.mission === "QR"
                ) {
                  finalThreats[task.threat].maxScore = 75;
                } else if (
                  task.mission === "PC3" ||
                  task.mission === "FamilyBoard" ||
                  task.mission === "SciFi3" ||
                  task.mission === "Intro" ||
                  task.mission === "ServersRoom1"
                ) {
                  finalThreats[task.threat].maxScore = 160;
                } else if (
                  task.mission === "London" ||
                  task.mission === "Israel3" ||
                  task.mission === "DocksInTrash" ||
                  task.mission === "Israel2" ||
                  task.mission === "Israel1" ||
                  task.mission === "PhishingEmail" ||
                  task.mission === "ControlRoom1"
                ) {
                  finalThreats[task.threat].maxScore = 180;
                } else if (
                  task.type === "long-Quiz" ||
                  task.type === "first-video-quiz" ||
                  task.type === "daily-quiz-1" ||
                  task.mission === "SciFi35" ||
                  task.mission === "WorkerTag" ||
                  task.mission === "Door2" ||
                  task.mission === "ServersRoom2" ||
                  task.mission === "ServersRoom3"
                ) {
                  finalThreats[task.threat].maxScore = 260;
                } else if (task.mission === "Tunnel") {
                  finalThreats[task.threat].maxScore = 500;
                }
                finalThreats[task.threat].totalScore = task.score;
                finalThreats[task.threat].count = 1;
                if (task.threat === task.threat.toUpperCase()) {
                  finalThreats[task.threat].name = task.threat;
                } else {
                  finalThreats[task.threat].name = task.threat
                    .replace(/\w\S*/g, (w) =>
                      w.replace(/^\w/, (c) => c.toUpperCase())
                    )
                    .match(/[A-Z][a-z]+|[0-9]+/g)
                    .join(" ");
                }
                if (task.threat === "phishing") {
                  finalThreats[task.threat].icon = "far fa-id-card";
                } else if (task.threat === "vishing") {
                  finalThreats[task.threat].icon = "fas fa-microphone";
                } else if (task.threat === "smishing") {
                  finalThreats[task.threat].icon = "far fa-comment-alt";
                } else if (task.threat === "publicNetwork") {
                  // finalThreats[task.threat].name = "Public Network";
                  finalThreats[task.threat].icon = "fas fa-wifi";
                } else if (task.threat === "securePasswords") {
                  // finalThreats[task.threat].name = "Secure Passwords";
                  finalThreats[task.threat].icon = "fas fa-lock";
                } else if (task.threat === "authorizedSoftware") {
                  // finalThreats[task.threat].name = "Authorized Software";
                  finalThreats[task.threat].icon = "fas fa-shield-alt";
                } else if (task.threat === "physicalSecurity") {
                  // finalThreats[task.threat].name = "Physical Security";
                  finalThreats[task.threat].icon = "fas fa-briefcase";
                } else if (task.threat === "GeneralAwareness") {
                  // finalThreats[task.threat].name = "General Awerness";
                  finalThreats[task.threat].icon = "fas fa-cogs";
                } else if (task.threat === "none") {
                  delete finalThreats[task.threat];
                } else if (task.threat === "SOC") {
                  finalThreats[task.threat].icon = "fas fa-headset";
                } else finalThreats[task.threat].icon = "far fa-comment-alt";
              } else {
                if (
                  task.mission === "PC3Code" ||
                  task.mission === "Shredder" ||
                  task.mission === "QR"
                ) {
                  finalThreats[task.threat].maxScore += 75;
                } else if (
                  task.mission === "PC3" ||
                  task.mission === "FamilyBoard" ||
                  task.mission === "SciFi3" ||
                  task.mission === "Intro" ||
                  task.mission === "ServersRoom1"
                ) {
                  finalThreats[task.threat].maxScore += 160;
                } else if (
                  task.mission === "London" ||
                  task.mission === "Israel3" ||
                  task.mission === "DocksInTrash" ||
                  task.mission === "Israel2" ||
                  task.mission === "Israel1" ||
                  task.mission === "PhishingEmail" ||
                  task.mission === "ControlRoom1"
                ) {
                  finalThreats[task.threat].maxScore += 180;
                } else if (
                  task.type === "long-Quiz" ||
                  task.type === "first-video-quiz" ||
                  task.type === "daily-quiz-1" ||
                  task.mission === "SciFi35" ||
                  task.mission === "WorkerTag" ||
                  task.mission === "Door2" ||
                  task.mission === "ServersRoom2" ||
                  task.mission === "ServersRoom3"
                ) {
                  finalThreats[task.threat].maxScore += 260;
                } else if (task.mission === "Tunnel") {
                  finalThreats[task.threat].maxScore += 500;
                }
                finalThreats[task.threat].totalScore += task.score;
                finalThreats[task.threat].count++;
              }
            });
          });
        }
        var userThreats = {
          fullName: user.fullName,
          id: user.id,
          threats,
          email: user.email,
          quizScores: user.quizScores || null,
        };
        usersThreats.push(userThreats);
      });
      for (const threatKey in finalThreats) {
        finalThreats[threatKey].avg = (
          finalThreats[threatKey].totalScore / finalThreats[threatKey].count
        ).toFixed(0);
        finalThreats[threatKey].maxAvg = (
          finalThreats[threatKey].maxScore / finalThreats[threatKey].count
        ).toFixed(0);
        this.finalThreatsUser.push(finalThreats[threatKey]);
      }
      console.log(this.finalThreatsUser);
      console.log(usersThreats);
    },
    changeMinValue() {
      this.ready = false;
      setTimeout(() => (this.ready = true), 100);
    },
  },
  mounted() {
    var exportBtns = document.querySelectorAll(".export-data");
    exportBtns.forEach((btn) => {
      btn.addEventListener("mouseenter", () => {
        this.showExportData = true;
      });
      btn.addEventListener("mouseleave", () => {
        this.showExportData = false;
      });
    });
    // var selects = document.querySelectorAll('.el-select .el-input .el-input__inner')
    // selects.forEach(select=>{
    //   console.log(select);
    //   select.style.color = '#00f5af'
    // })
    // document.querySelector(".el-input__inner").style.backgroundColor =
    //   "transparent";
    // document.querySelector(".el-input__inner").style.border =
    //   "1px solid rgba(0, 245, 175)";
    // document.querySelector(".el-input__inner").style.color = "white";

    // document.querySelector(
    //   ".el-scrollbar__view.el-select-dropdown__list"
    // ).style.backgroundColor = "transparent";
    // document.querySelector(
    //   ".el-scrollbar__view.el-select-dropdown__list"
    // ).style.border = "1px solid rgba(0, 245, 175)";

    // console.log(document.querySelectorAll(".el-radio__label"));
    // document.querySelectorAll(".el-radio__label").forEach((radioLabel) => {
    //   console.log(radioLabel);
    //   radioLabel.classList.add("radio-label");
    // });
  },
  async created() {
    if (!this.loggedinUser) {
      this.$message({
        showClose: true,
        message: `Login first!`,
        type: "warning",
        duration: 1500,
      });
      window.location =
        "https://demo-newledge.auth.us-east-2.amazoncognito.com/login?client_id=3ijg2b3k5i7epp88779v9l7p1q&response_type=token&scope=phone+email+openid+aws.cognito.signin.user.admin+profile&redirect_uri=https://demo.new-ledge.com/";
    } else {
      this.ready = false;
      var user = await this.$store.dispatch({
        type: "getUserById",
        _id: this.loggedinUser.AuthId,
      });
      await this.$store.dispatch({
        type: "setUser",
        userCred: user,
      });
      await this.$store.dispatch({ type: "loadUsers" });
      if (this.loggedinUser.isAdmin) {
        if (!this.slackUsers || !this.slackUsers.length) {
          await this.$store.dispatch({
            type: "loadSlackUsers",
          });
        }
        var res = await this.$store.dispatch({
          type: "checkRegistretionUsers",
        });
        this.unregisteredUsers = res.unregisteredUsers;
        this.registeredUsers = res.registeredUsers;
        if (!this.oktaUsers || !this.oktaUsers.length) {
          await this.$store.dispatch({
            type: "loadOktaUsers",
          });
        }
        var resOkta = await this.$store.dispatch({
          type: "checkRegistretionOktaUsers",
        });
        console.log(resOkta);
        this.unregisteredOktaUsers = resOkta.unregisteredOktaUsers;
        this.registeredOktaUsers = resOkta.registeredOktaUsers;
        this.registeredOktaUsers.forEach(async (registeredOktaUser) => {
          if (!registeredOktaUser.user.division) {
            await this.$store.dispatch({
              type: "updateUser",
              userId: registeredOktaUser.user.id,
              updateKey: "division",
              updateValue: registeredOktaUser.oktaUser.profile.division,
            });
          }
          if (!registeredOktaUser.user.department) {
            await this.$store.dispatch({
              type: "updateUser",
              userId: registeredOktaUser.user.id,
              updateKey: "department",
              updateValue: registeredOktaUser.oktaUser.profile.department,
            });
          }
          if (!registeredOktaUser.user.entity) {
            await this.$store.dispatch({
              type: "updateUser",
              userId: registeredOktaUser.user.id,
              updateKey: "entity",
              updateValue: registeredOktaUser.oktaUser.profile.Entity,
            });
          }
        });
        await this.$store.dispatch({ type: "loadUsers" });
        // await this.$store.dispatch({
        //   type: "loadOktaUsers",
        // });
        var resOktaUpdate = await this.$store.dispatch({
          type: "checkRegistretionOktaUsers",
        });
        this.unregisteredOktaUsers = resOktaUpdate.unregisteredOktaUsers;
        this.registeredOktaUsers = resOktaUpdate.registeredOktaUsers;
        this.registeredOktaUsers.forEach((registeredOktaUser) => {
          if (
            !this.divisions.includes(
              registeredOktaUser.oktaUser.profile.division
            )
          ) {
            this.divisions.push(registeredOktaUser.oktaUser.profile.division);
          }
          if (!this.departments[registeredOktaUser.oktaUser.profile.division]) {
            this.departments[registeredOktaUser.oktaUser.profile.division] = [];
          }
          if (
            !this.departments[
              registeredOktaUser.oktaUser.profile.division
            ].includes("All")
          ) {
            this.departments[registeredOktaUser.oktaUser.profile.division].push(
              "All"
            );
          }
          if (
            !this.departments[
              registeredOktaUser.oktaUser.profile.division
            ].includes(registeredOktaUser.oktaUser.profile.department)
          ) {
            this.departments[registeredOktaUser.oktaUser.profile.division].push(
              registeredOktaUser.oktaUser.profile.department
            );
          }
          if (!this.usersByRole[registeredOktaUser.oktaUser.profile.division]) {
            this.usersByRole[registeredOktaUser.oktaUser.profile.division] = {};
          }
          if (
            !this.usersByRole[registeredOktaUser.oktaUser.profile.division][
              registeredOktaUser.oktaUser.profile.department
            ]
          ) {
            this.usersByRole[registeredOktaUser.oktaUser.profile.division][
              registeredOktaUser.oktaUser.profile.department
            ] = [];
          }
          this.usersByRole[registeredOktaUser.oktaUser.profile.division][
            registeredOktaUser.oktaUser.profile.department
          ].push(registeredOktaUser.user);
        });
      }
      this.getDashboard();
      this.getDashboardUser();
      this.ready = true;
    }
  },
};
</script>

<style lang="scss" scoped>
$primary_color: #00f5af;
.threats {
  width: 100%;
  & > * {
    margin: 5px;
  }
}

.left {
  p {
    margin-bottom: 0;
    margin-right: 5px;
  }
}

.threshold-input {
  width: 50px;
}
.registration-form {
  color: white;
  margin-bottom: 20px;
  & .user {
    padding: 5px;
    min-width: 150px;
  }
}

/deep/ {
  .el-input .el-input__inner {
    background-color: transparent;
    color: $primary_color;
    text-align: center;
    border: $primary_color 1px solid;
  }
  .el-textarea .el-textarea__inner {
    background-color: transparent;
    color: $primary_color;
    &::placeholder {
      color: $primary_color;
      text-align: center;
      font-size: 16px;
    }
  }
  .el-radio {
    color: white;
    &:hover {
      color: $primary_color !important;
    }
    .el-radio__input {
      margin-bottom: px(5);
      &.is-checked {
        .el-radio__inner {
          background-color: $primary_color;
        }
        + .el-radio__label {
          color: $primary_color !important;
        }
      }
    }
  }
  .el-select {
    margin: 10px;
    & .el-input {
      & .el-input__inner {
        color: $primary_color;
        border: $primary_color 1px solid;
        &::placeholder {
          color: $primary_color;
        }
        &:focus {
          border: $primary_color 1px solid;
        }
      }
    }
  }

  // .el-select-dropdown .el-scrollbar .el-select-dropdown__wrap .el-select-dropdown__list .el-select-dropdown__item span {
  //   color: $primary_color;
  // }
}
.demo-text {
  border: 1px solid red;
  padding: 10px;
}
.options-top {
  width: 100%;
  padding: 5px;
  margin-bottom: 30px;
  & .left {
    margin-left: 30px;
    .icon {
      position: relative;
      i {
        font-size: 30px;
        margin: 10px;
        color: rgba(0, 245, 175, 0.85);
      }
      p {
        color: rgba(0, 245, 175, 0.85);
        position: absolute;
        bottom: -40px;
        text-align: center;
      }
    }
  }
}

tbody th,
tbody td {
  padding: 0.5rem 0.625rem 0.625rem;
  text-align: center;
}
tbody tr,
thead {
  border-bottom: 1px solid $primary_color;
  margin-bottom: 5px;
}
.circles {
  width: 100%;
  & > * {
    margin: 5px;
  }
}

.category-circles {
  & > * {
    margin: 5px;
  }
}

.button {
  border: $primary_color 2px solid;
  font-family: regular;
  padding: 0.5rem 1rem;
  border-radius: 5rem;
  margin: 0 0.25rem;
  transition: 0.3s;
  background: black;
  color: black;
  font-size: 1rem;
  &:hover {
    cursor: pointer;
    background: $primary_color;
    border-color: rgba(255, 255, 255, 0.25);
  }
  &.is-active {
    background: $primary_color;
    color: white;
    border-color: transparent;

    &:hover {
      background: darken($primary_color, 10%);
    }
  }
}
</style>