<template>
  <header
    class="app-header full main-container mb10"
    :class="{ scrolly: scrollYposition > 20 }"
  >
    <div class="hedear-container flex align-center space-between">
      <div class="logo">
        <img src="@/assets/img/logo-newledge.png" alt="" class="logo-img" />
      </div>
      <navbar class="nav-bar-horizontal"></navbar>
      <nav-bar-vertical class="nav-bar-vertical"></nav-bar-vertical>
      <div class="company-logo logo">
        <img
          v-if="loggedinUser && loggedinUser.compImg"
          :src="loggedinUser.compImg"
          alt=""
          class="logo-img"
        />
        <img v-else src="../assets/img/logo.png" alt="" class="logo-img" />
      </div>
    </div>
  </header>
</template>

<script>
import navbar from "./element-ui/nav-bar";
import navBarVertical from "./element-ui/nav-bar-vertical.vue";

export default {
  name: "app-header",
  data() {
    return {
      scrollYposition: 0,
      typeImg: null,
    };
  },
  components: {
    navbar,
    navBarVertical,
  },
  computed: {
    loggedinUser() {
      return this.$store.getters.loggedinUser;
    },
  },
  mounted() {
    console.log("app-header mounted");
    this.$parent.changeLogo();
  },
  created() {
    document.addEventListener("scroll", this.getScroll);
  },
  destroyed() {
    Document.removeEventListener("scroll", this.getScroll);
  },
  methods: {
    getScroll() {
      this.scrollYposition = window.scrollY;
    },
  },
};
</script>

<style>
</style>
