import { userService } from '../../service/user-service.js'
import { gameService } from '../../service/game-service.js'

import axios from 'axios'

var localLoggedinUser = null;
if (sessionStorage.user) localLoggedinUser = JSON.parse(sessionStorage.user);
var localLoggedinCognitoUser = null;
if (sessionStorage.cognitoUser) localLoggedinCognitoUser = JSON.parse(sessionStorage.cognitoUser);
var localAuthorizedToken = null;
if (sessionStorage.authorizedToken) localAuthorizedToken = JSON.parse(sessionStorage.authorizedToken);

export const userStore = {
    state: {
        loggedinUser: localLoggedinUser,
        loggedinCognitoUser: localLoggedinCognitoUser,
        users: [],
        authorizedToken: localAuthorizedToken

    },
    getters: {
        authorizedToken(state) {
            return state.authorizedToken;
        },
        users(state) {
            return state.users;
        },
        getUsers(state) {
            return state.users
        },
        loggedinUser(state) {
            return state.loggedinUser
        },
        loggedinCognitoUser(state) {
            return state.loggedinCognitoUser
        }
    },
    mutations: {
        setAuthorizedToken(state, { authorizedToken }) {
            state.authorizedToken = authorizedToken;
            sessionStorage.authorizedToken = JSON.stringify(authorizedToken)
        },
        setCognitoUser(state, { user }) {
            state.loggedinCognitoUser = user;
            sessionStorage.cognitoUser = JSON.stringify(user)
        },
        setUser(state, { user }) {
            state.loggedinUser = user;
            sessionStorage.user = JSON.stringify(user)
        },
        setUsers(state, { users }) {
            state.users = users;
        },
        removeUser(state, { userId }) {
            state.users = state.users.filter(user => user._id !== userId)
        },
    },
    actions: {
        async login(context, { userCred }) {
            try {
                const user = await userService.login(userCred);
                console.log(user);
                context.commit({ type: 'setUser', user })
                return user;
            } catch (err) {
                return { err }
            }
        },
        async signup(context, { userCred }) {
            try {
                const user = await userService.signup(userCred)
                context.commit({ type: 'setUser', user })
                console.log("signup -> user", user)
                return user;
            } catch (err) {
                return { err }
            }
        },
        async setAuthorizedToken(context, { authorizedToken }) {
            context.commit({ type: 'setAuthorizedToken', authorizedToken })
            console.log(axios.defaults.headers);
        },
        async setCognitoUser(context, { userCred }) {
            context.commit({ type: 'setCognitoUser', user: userCred })
            return userCred
        },
        async setUser(context, { userCred }) {
            context.commit({ type: 'setUser', user: userCred })
            return userCred
        },
        async addUser(context, { userCred }) {
            try {
                const user = await userService.add(userCred)
                return user;
            } catch (err) {
                return { err }
            }
        },
        async updatePassword(context, { userCred }) {
            try {
                const user = await userService.updatePassword(userCred)
                return user;
            } catch (err) {
                return { err }
            }
        },
        async logout(context) {
            await userService.logout()
            context.commit({ type: 'setUsers', users: [] })
            context.commit({ type: 'setUser', user: null })
            context.commit({ type: 'setCognitoUser', user: null })
            console.log('logout');
            window.location = "https://demo-newledge.auth.us-east-2.amazoncognito.com/login?client_id=3ijg2b3k5i7epp88779v9l7p1q&response_type=token&scope=phone+email+openid+aws.cognito.signin.user.admin+profile&redirect_uri=https://demo.new-ledge.com/";
            return { type: true, err: null }
        },
        async loadUsers(context) {
            const res = await userService.getUsers();
            const users = res.users
            context.commit({ type: 'setUsers', users })
        },
        async removeUser(context, { userId }) {
            await userService.remove(userId);
            context.commit({ type: 'removeUser', userId })
        },
        async updateUser(context, { userId, updateKey, updateValue }) {
            console.log(userId, updateKey, updateValue);
            var res = await userService.update(userId, updateKey, updateValue);
            console.log(res);
            var newUser = JSON.parse(sessionStorage.user)
            newUser[updateKey] = updateValue
            context.commit({ type: 'setUser', user: newUser })
            return { type: true, newUser }
        },
        async getWrongAnswerByParam(context, { param, value }) {
            try {
                return await userService.getWrongAnswerByParam(param, value);
            } catch (err) {
                return { err }
            }
        },
        async getUserById(context, { _id }) {
            try {
                return await userService.getById(_id);
            } catch (err) {
                return { err }
            }
        },
        async getUserByEmail(context, { email }) {
            const users = this.state.userStore.users
            var user = users.find(user => {
                return user.email === email
            })
            return user
        },
        async getNextGame(context, { currGame }) {
            try {
                return await gameService.getNextGame(currGame);
            } catch (err) {
                return { err }
            }
        },
        async startGame(context, { id, Type }) {
            try {
                const res = await gameService.startGame(id, Type);
                return res.gameId
            } catch (err) {
                return { err }
            }
        },
        async endGame(context, { id, gameId }) {
            try {
                return await gameService.endGame(id, gameId);
            } catch (err) {
                return { err }
            }
        },
        async postTaskCompletion(context, { id, gameId, Type, taskName, taskTime, threat, errors }) {
            try {
                return await gameService.postTaskCompletion(id, gameId, Type, taskName, taskTime, threat, errors);
            } catch (err) {
                return { err }
            }
        },
        async postWrongAnswer(context, { id, gameId, Type, taskName }) {
            try {
                return await gameService.postWrongAnswer(id, gameId, Type, taskName);
            } catch (err) {
                return { err }
            }
        }
    }
}