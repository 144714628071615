<template>
  <div class="height-100">
    <div v-if="ready" class="admin-tools flex column center">
      <h2>Admin Tools</h2>
      <div class="first-slack-message flex column center mb16">
        <p>Send message for all your Slack Workspace</p>
        <div class="width-100 flex center">
          <el-input
            type="textarea"
            :rows="2"
            class="first-slack-message-input"
            v-model="slackMessage"
            placeholder="Enter your message"
          ></el-input>
          <button
            class="button first-slack-message-btn"
            @click="sendWorkspaceSlackMessage"
          >
            <i class="fas fa-paper-plane"></i>
          </button>
        </div>
      </div>
      <div class="btns flex center mb16">
        <button
          :class="{ 'is-active': this.slackRegistration }"
          class="button"
          @click="
            () => {
              this.slackRegistration = true;
            }
          "
        >
          Slack registration
        </button>
        <button
          :class="{ 'is-active': !this.slackRegistration }"
          class="button"
          @click="
            () => {
              this.slackRegistration = false;
            }
          "
        >
          Okta registration
        </button>
      </div>
      <div class="flex column center width-100">
        <div
          class="registration-form flex column align-center width-100"
          v-if="unregisteredUsers.length || registeredUsers.length"
          v-show="slackRegistration"
        >
          <h3>Registration By Slack Users</h3>
          <div class="flex column center width-100 wrap">
            <pagination-cmp
              :exampleItems="registeredUsers"
              status="Registered Slack Users"
            ></pagination-cmp>
            <pagination-cmp
              :exampleItems="unregisteredUsers"
              status="Unregistered Slack Users"
            ></pagination-cmp>
          </div>
        </div>
        <div
          class="registration-form flex column align-center width-100"
          v-if="unregisteredOktaUsers.length || registeredOktaUsers.length"
          v-show="!slackRegistration"
        >
          <h3>Registration By Okta Users</h3>
          <div class="flex column center width-100 wrap">
            <pagination-cmp
              :exampleItems="registeredOktaUsers"
              status="Registered Okta Users"
            ></pagination-cmp>
            <pagination-cmp
              :exampleItems="unregisteredOktaUsers"
              status="Unregistered Okta Users"
            ></pagination-cmp>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="loading-container flex column center height-100">
      <img :src="require(`@/assets/img/loading.gif`)" alt="" srcset="" />
      <p>Loading...</p>
    </div>
  </div>
</template>

<script>
import paginationCmp from "@/cmp/paginationCmp";

export default {
  data() {
    return {
      slackRegistration: false,
      unregisteredUsers: [],
      registeredUsers: [],
      unregisteredOktaUsers: [],
      registeredOktaUsers: [],
      slackReminderMessage: null,
      slackMessage: null,
      ready: true,
      showMessageUsers: [],
      items: []
    };
  },
  components: {
    paginationCmp,
  },
  methods: {
    getOktraUsersNotPassedOrNotRegistered() {
      var status = false;
      var cyberRoomStatus = false;
      var scenarioStatus = false;
      var sqlInjectionStatus = false;
      this.items = JSON.parse(JSON.stringify(this.registeredOktaUsers));
      this.items = this.items.filter((exampleItem) => {
        status = false;
        cyberRoomStatus = false;
        scenarioStatus = false;
        sqlInjectionStatus = false;
        if (exampleItem.user && exampleItem.user.scores) {
          exampleItem.user.scores.forEach((game) => {
            if (status) return;
            if (game.type === "cyber-room" && game.endAt) {
              cyberRoomStatus = true;
            }
            if (game.type === "scenario" && game.endAt) {
              scenarioStatus = true;
            }
            if (game.type === "sql-injection" && game.endAt) {
              sqlInjectionStatus = true;
            }
          });
        }
        if (
          (cyberRoomStatus && sqlInjectionStatus) ||
          (scenarioStatus && sqlInjectionStatus)
        ) {
          status = true;
        }
        return !status;
      });
      console.log(this.items);
      this.showMessageUsers = this.items.concat(this.unregisteredOktaUsers)
      console.log(this.showMessageUsers);
    },

    async sendWorkspaceSlackMessage() {
      if (this.loggedinUser.slackToken) {
        if (!this.slackMessage) {
          this.slackMessage = "Slack Message (https://demo.new-ledge.com)";
        }
        await this.$store.dispatch({
          type: "sendWorkspaceSlackMessage",
          text: this.slackMessage,
        });
        this.slackMessage = null;
      } else {
        this.$message({
          showClose: true,
          message: `You didn't have the right permission`,
          type: "warning",
          duration: 1500,
        });
      }
    },
    async sendReminder() {
      if (this.loggedinUser.slackToken) {
        if (!this.slackReminderMessage) {
          this.slackReminderMessage =
            "Slack Reminder Message (https://demo.new-ledge.com)";
        }
        var unregisteredUsersIds = [];
        this.unregisteredUsers.forEach(async (unregisteredUser) => {
          unregisteredUsersIds.push(unregisteredUser.id);
        });
        await this.$store.dispatch({
          type: "sendSlackMessage",
          channels: unregisteredUsersIds,
          text: this.slackReminderMessage,
        });
        this.slackReminderMessage = null;
      } else {
        this.$message({
          showClose: true,
          message: `You didn't have the right permission`,
          type: "warning",
          duration: 1500,
        });
      }
    },
  },
  computed: {
    oktaUsers() {
      return this.$store.getters.oktaUsers;
    },
    slackUsers() {
      return this.$store.getters.slackUsers;
    },
    loggedinUser() {
      return this.$store.getters.loggedinUser;
    },
  },
  async created() {
    this.ready = false;
    await this.$store.dispatch({ type: "loadUsers" });
    if (this.loggedinUser.isAdmin) {
      if (!this.oktaUsers.length) {
        await this.$store.dispatch({
          type: "loadOktaUsers",
        });
      }
      if (!this.slackUsers.length) {
        await this.$store.dispatch({
          type: "loadSlackUsers",
        });
      }
      var res = await this.$store.dispatch({
        type: "checkRegistretionUsers",
      });
      this.unregisteredUsers = res.unregisteredUsers;
      this.registeredUsers = res.registeredUsers;
      var resOkta = await this.$store.dispatch({
        type: "checkRegistretionOktaUsers",
      });
      this.unregisteredOktaUsers = resOkta.unregisteredOktaUsers;
      this.registeredOktaUsers = resOkta.registeredOktaUsers;
      this.getOktraUsersNotPassedOrNotRegistered()
    }
    this.ready = true;
  },
};
</script>

<style lang="scss" scoped>
$primary_color: #00f5af;

.first-slack-message {
  margin-bottom: 20px;
}

.registration-form {
  color: white;
  margin-bottom: 20px;
  & .user {
    padding: 5px;
    min-width: 150px;
  }
}
/deep/ {
  .el-textarea .el-textarea__inner {
    background-color: transparent;
    color: $primary_color;
    border: $primary_color 1px solid;
    &::placeholder {
      color: $primary_color;
      text-align: center;
      font-size: 16px;
    }
  }
  // .el-input {
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   & .el-input__inner {
  //     width: 250px;
  //     background-color: transparent;
  //     color: $primary_color;
  //     border: $primary_color 1px solid;
  //     text-align: center;
  //     &::placeholder {
  //       color: $primary_color;
  //       text-align: center;
  //     }
  //   }
  // }
}

.button {
  border: $primary_color 2px solid;
  font-family: regular;
  padding: 0.5rem 1rem;
  border-radius: 5rem;
  margin: 0 0.25rem;
  transition: 0.3s;
  background: black;
  color: white;
  font-size: 1rem;
  &:hover {
    cursor: pointer;
    background: $primary_color;
    border-color: rgba(255, 255, 255, 0.25);
  }
  &.is-active {
    background: $primary_color;
    color: black;
    border-color: transparent;

    &:hover {
      background: darken($primary_color, 10%);
    }
  }
}
</style>