<template>
  <div class="cyber-room-mobile flex column center">
    <quiz :quizNew="quizCyberRoom" v-if="!readyIframe"></quiz>
    <iframe
      v-show="readyIframe"
      ref="iframe2"
      @load="sendId"
      src="https://demo.new-ledge.com/scenarios/cyber-room-mobile/index.html"
      frameborder="0"
      allowfullscreen="true"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
    ></iframe>
    <button class="button to-profile-btn" @click="() => this.$router.push('/')">
      Go back to Profile
    </button>
  </div>
</template>

<script>
import quiz from "@/cmp/quiz";
// import { utilService } from "@/service/utils/util.service.js";

export default {
  name: "cyber-room-mobile",
  data() {
    return {
      id: null,
      done: null,
      readyIframe: false,
      quizCyberRoom: {
        questions: [
          {
            text: "You wish to share tasks and documents with your team via Whatsapp. Is it allowed?",
            responses: [
              { text: "No", correct: true },
              {
                text: "Yes",
              },
            ],
            message:
              "Company data can not be shared via WhatsApp or any other private channels such as private Gmail, Facebook, etc. Sharing company data can only be used via Company official and secured work channels (Emails, Internal Slack, Google Drive.)",
          },
          {
            text: "Can I upload Company or Customer data to a public repository (such as open source repositories - GitHub, Pastebin, Reddit)?",
            responses: [
              { text: "No", correct: true },
              {
                text: "Yes",
              },
            ],
            message:
              "Exposure to such data can risk our assets and agreements with the customer. You must remove it ASAP and report it to security and your manager immediately. The faster you remove it and report it, the faster the incident can be contained and damage minimized.",
          },
        ],
      },
    };
  },
  components: {
    quiz,
    // game,
  },
  computed: {
    loggedinUser() {
      return this.$store.getters.loggedinUser;
    },
    authorizedToken() {
      return this.$store.getters.authorizedToken;
    },
  },
  methods: {
    sendId() {
      this.$refs["iframe2"].contentWindow.postMessage(
        { id: this.loggedinUser.id, token: this.authorizedToken },
        "*"
      );
      setTimeout(() => {
        this.done = true;
        this.$parent.changeLogo();
      }, 15000);
    },
    showIframe() {
      this.readyIframe = true;
      this.$refs["iframe2"].style.opacity = 1;
      this.$refs["iframe2"].style.width = "100%";
      this.$refs["iframe2"].style.height = "100%";
    },
  },
  mounted() {
    // this.$refs["iframe2"].style.opacity = 1;
    // this.$refs["iframe2"].style.width = "1px";
    // this.$refs["iframe2"].style.height = "1px";
   // const lang = this.$route.params.lang;
   // if (lang) {
   //   console.log(this.$refs["iframe2"]);
    //  this.$refs["iframe2"].src =
    //    "https://demo.new-ledge.com/scenarios/virtual-escape-room-heb/index.html";
   // }
    this.readyIframe = true
    this.$parent.changeLogo();
  },
  async destroyed() {
    console.log("cyber-room-mobile destoyed");
  },
  async created() {
    if (!this.loggedinUser) {
      this.$message({
        showClose: true,
        message: `Login first!`,
        type: "warning",
        duration: 1500,
      });
      window.location =
        "https://demo-newledge.auth.us-east-2.amazoncognito.com/login?client_id=3ijg2b3k5i7epp88779v9l7p1q&response_type=token&scope=phone+email+openid+aws.cognito.signin.user.admin+profile&redirect_uri=https://demo.new-ledge.com/";
      return;
    }
  },
};
</script>

<style lang="scss" scoped>
$primary_color: #00f5af;

.cyber-room-mobile {
  position: relative;
  width: 100%;
  height: 100%;
  & iframe {
    width: 100%;
    height: 100%;
  }
  // .btn-show-iframe {
  //   position: absolute;
  //   top: 3px;
  //   left: 60px;
  //   @include for-wide-layout {
  //     top: -9px;
  //   }
  // }
  .to-profile-btn {
    margin-top: 16px !important;
  }
  .button {
    border: $primary_color 2px solid;
    font-family: regular;
    padding: 0.5rem 1rem;
    border-radius: 5rem;
    margin: 0 0.25rem;
    transition: 0.3s;
    background: black;
    color: white;
    font-size: 1rem;
    &:hover {
      cursor: pointer;
      background: $primary_color;
      border-color: rgba(255, 255, 255, 0.25);
    }
    &.is-active {
      background: $primary_color;
      color: black;
      border-color: transparent;

      &:hover {
        background: darken($primary_color, 10%);
      }
    }
  }
}
</style>