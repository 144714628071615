<template>
  <div class="video-quiz flex column center">
    <video-player
      v-if="showVideo"
      ref="videoPlayer"
      class="vjs-custom-skin"
      :options="playerOptions"
      @play="onPlayerPlay($event)"
      @ready="onPlayerReady($event)"
      @ended="onPlayerEnd($event)"
    >
    </video-player>
    <quizGame :quizNew="videoQuiz" :gameId="gameId" v-else></quizGame>
    <section class="btns flex center mt16">
      <button
        class="button to-profile-btn mrg5"
        @click="() => this.$router.push('/')"
      >
        Go back to Profile
      </button>
      <!-- <button
        v-if="nextGame"
        class="button to-next-game-btn mrg5"
        @click="() => this.$router.push(`/${route}`)"
      >
        {{ nextGame }}
      </button> -->
    </section>
  </div>
</template>

<script>
import quizGame from "@/cmp/quiz-game";

export default {
  name: "second-video-quiz",
  data() {
    return {
      videoQuiz: {
        questions: [
          {
            id: "second-video-quiz.1",
            text: "?אילו מהאפשרויות לפניך תואמת הודעת דוא''ל בטוחה",
            responses: [
              {
                text: "אני מכיר את השולח, ההודעה ממוענת אליי, אין נימת דחיפות, אין בקשה למסירת מידע רגיש",
                correct: true,
              },
              { text: "אני מכיר את השולח" },
              {
                text: "לא מכיר את השולח, ההודעה מכילה קישור ומבקשת ממני להזין פרטים איישים שלי",
              },
              { text: ":Fw או :Re -אני מכיר את השולח, והנושא מתחיל ב" },
            ],
            message: null,
          },
          {
            id: "second-video-quiz.2",
            text: "?האם מתקפת דיוג (פישינג) מתבצעת רק דרך הדוא''ל הארגוני",
            responses: [
              {
                text: "לא, גם דרך הודעות ביישומי העברות מסרים ( SMS/ WathsApp) דואר אלקטרוני פרטי אתרי אינטרנט  ושיחות טלפון",
                correct: true,
              },
              { text: "כן, ידוע שצריך להיזהר מפתיחת הודעות בדוא''ל הארגוני" },
              { text: "לאו דווקא, הדוא''ל האישי הוא זה שחשוף למתקפת פישינג" },
              { text: "לא, מתקפת פישינג מתבצעת גם דרך הודעות SMS" },
            ],
            message: null,
          },
          {
            id: "second-video-quiz.3",
            text: "?מי יכול להוות מטרה טובה לניסיון דיוג (פישינג)",
            responses: [
              {
                text: "כל עובד בחברה",
                correct: true,
              },
              { text: "אנשי IT וטכנולוגיה בעלי גישה למערכות שונות בחברה" },
              { text: "חברי ההנהלה הבכירה" },
              { text: "אנשי הכספים בעלי גישה למידע פיננסי בחברה" },
            ],
            message: null,
          },
          {
            id: "second-video-quiz.4",
            text: "?קיבלת הודעה המכילה קישור המבקש לעדכן פרטים לגבי משלוח הנמצא בדרכו אלייך. כיצד תנהג",
            responses: [
              {
                text: "אוודא באופן יזום מול חברת המשלוחים שמדובר במשלוח שלי, אאמת שאכן ההודעה נשלחה מחברת השילוח ויש צורך בעדכון פרטים",
                correct: true,
              },
              {
                text: "אלחץ על הקישור המצורף להודעה לעדכון הפרטים כדי לקבל את המשלוח",
              },
              {
                text: "לא הזמנתי משהו לאחרונה אבל אכנס לקישור, כי חשוב לעדכן את הכתובת הנכונה",
              },
              {
                text: "ראשית אלחץ על הקישור המצורף, ולאחר מכן אוודא שמדובר במשלוח שלי ואעדכן את הפרטים הנדרשים",
              },
            ],
            message: null,
          },
          {
            id: "second-video-quiz.5",
            text: "?לקראת סוף יום העבודה המחשב שלך נחסם וקיבלת הודעה כי מדובר בתוכנת כופר, במידה ולא תשלם לתוקפים הם יפרסמו מידע מזוייף אודותיך בפני מנהליך כיצד תנהג",
            responses: [
              { text: "אשלם, זהו הפתרון האפקטיבי ביותר נגד מתקפות מסוג זה" },
              {
                text: "אדווח על כך למוקד הסייבר בארגון ואפעל לפי הנחיותיהם",
                correct: true,
              },
              { text: "אכבה את המחשב" },
              { text: "אזמין טכנאי המתמחה במתקפות מסוג זה" },
            ],
            message: null,
          },
        ],
      },
      gameId: null,
      showVideo: true,
      playerOptions: {
        autoplay: true,
        controls: true,
        controlBar: {
          timeDivider: false,
          durationDisplay: false,
        },
        // poster: 'https://surmon-china.github.io/vue-quill-editor/static/images/surmon-5.jpg'
      },
      nextGame: null,
      route: null,
    };
  },
  components: {
    quizGame,
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    },
    loggedinUser() {
      return this.$store.getters.loggedinUser;
    },
  },
  methods: {
    onPlayerPlay(player) {
      console.log("player play!", player);
    },
    onPlayerReady(player) {
      console.log("player ready!", player);
      this.player.play();
    },
    async onPlayerEnd(player) {
      console.log("player end!", player);
      await this.$store.dispatch({
        type: "postTaskCompletion",
        id: this.loggedinUser.id,
        Type: this.$route.path.substring(1),
        gameId: this.gameId,
        taskName: "second-video",
        taskTime: 0,
        threat: "GeneralAwareness",
        errors: 0,
      });
      this.showVideo = false;
    },
    playVideo: function (source) {
      const video = {
        withCredentials: false,
        type: "application/x-mpegurl",
        src: source,
      };
      this.player.reset(); // in IE11 (mode IE10) direct usage of src() when <src> is already set, generated errors,
      this.player.src(video);
      // this.player.load()
      this.player.play();
    },
  },
  mounted() {
    this.$parent.changeLogo();
    const src =
      "https://djtia0ybt030k.cloudfront.net/out/v1/f65ed6e58ea242de953f18c107e0f89f/35a9505c283846cdba26ec5bfcd237fc/b8bcb9eba73a4ceaa2f71a70dc4d8667/index.m3u8";
    this.playVideo(src);
  },
  async created() {
    this.gameId = await this.$store.dispatch({
      type: "startGame",
      id: this.loggedinUser.id,
      Type: this.$route.path.substring(1),
    });
    // var res = await this.$store.dispatch({
    //   type: "getNextGame",
    //   currGame: this.$route.path.substring(1),
    // });
    // if (!res.err) {
    //   switch (res) {
    //     case "cyber-room":
    //       this.nextGame = "Virtual Escape Room";
    //       break;
    //     case "scenario":
    //       this.nextGame = "Cyber Security Training";
    //       break;
    //     case "first-video-quiz":
    //       this.nextGame = "First video & short quiz";
    //       break;
    //     case "second-video-quiz":
    //       this.nextGame = "Second video & short quiz";
    //       break;
    //     default:
    //       this.nextGame = res;
    //       break;
    //   }
    //   this.route = res;
    // }
  },
};
</script>

<style lang="scss" scoped>
$primary_color: #00f5af;

.video-quiz {
  position: relative;
  width: 100%;
  height: 100%;
  .hint {
    position: absolute;
    top: 33px;
    color: black;
  }
  .button {
    border: $primary_color 2px solid;
    font-family: regular;
    padding: 0.5rem 1rem;
    border-radius: 5rem;
    margin: 0 0.25rem;
    transition: 0.3s;
    background: black;
    color: white;
    font-size: 1rem;
    &:hover {
      cursor: pointer;
      background: $primary_color;
      border-color: rgba(255, 255, 255, 0.25);
    }
    &.is-active {
      background: $primary_color;
      color: black;
      border-color: transparent;

      &:hover {
        background: darken($primary_color, 10%);
      }
    }
  }
}
.vjs-custom-skin {
  height: 100% !important;
  width: 100%;
}

.vjs-custom-skin /deep/ .video-js {
  height: 100%;
  width: 100%;
  .vjs-big-play-button {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
