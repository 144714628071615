<template>
  <div class="scenario flex column center">
    <!-- <game v-if="!readyIframe"></game> -->
    <quiz :quizNew="quizScenario" v-if="!readyIframe"></quiz>
    <!-- <h3 v-if="!readyIframe" class="hint">Cyber Awareness Words</h3>
    <hangman v-if="!readyIframe"></hangman> -->

    <!-- <button
      class="btn-show-iframe button"
      v-if="done && !readyIframe"
      @click="showIframe"
    >
      Go To Scenario
    </button> -->
    <iframe
      v-show="readyIframe"
      ref="iframe3"
      @load="sendId"
      src="https://demo.new-ledge.com/scenarios/cyber-security/index.html"
      frameborder="0"
      allowfullscreen="true"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
    ></iframe>
    <button class="button to-profile-btn" @click="() => this.$router.push('/')">
      Go back to Profile
    </button>
  </div>
</template>

<script>
import quiz from "@/cmp/quiz";
// import { utilService } from "@/service/utils/util.service.js";

export default {
  components: {
    quiz,
  },
  name: "scenario",
  data() {
    return {
      id: null,
      done: null,
      readyIframe: false,
      quizScenario: {
        questions: [
          {
            text: "Is it allowed to share sensitive work-related data on Gmail, WhatsApp, LinkedIn, Facebook or Twitter?",
            responses: [
              { text: "No", correct: true },
              {
                text: "Yes",
              },
            ],
            message:
              "Gmail, WhatsApp, LinkedIn and other social networking media are unsecure platforms and sensitive Company data shared through them might be exposed to unauthorized entities.",
          },
          {
            text: "I need to create an excel, containing sensitive data. Should I protect the document?",
            responses: [
              { text: "No" },
              {
                text: "Yes",
                correct: true,
              },
            ],
            message:
              "First, classify your document sensitivity (In Google Docs you can select the classification using the drop-down option in the toolbar). Also, store it in a secured location and share it only on a need to know basis.",
          },
        ],
      },
    };
  },
  computed: {
    loggedinUser() {
      return this.$store.getters.loggedinUser;
    },
    authorizedToken() {
      return this.$store.getters.authorizedToken;
    },
  },
  methods: {
    showIframe() {
      this.readyIframe = true;
      // this.$refs["iframe3"].contentDocument.getElementById(
      //   "muted"
      // ).checked = false;
      // this.$refs["iframe3"].contentWindow.speaker[0].muted = false;
      // this.$refs["iframe3"].contentWindow.speaker[1].muted = false;
    },
    sendId() {
      this.$refs["iframe3"].contentWindow.postMessage(
        { id: this.loggedinUser.id, token: this.authorizedToken },
        "*"
      );
      setTimeout(() => {
        this.done = true;
      }, 15000);
    },
  },
  async destroyed() {
    console.log("scenario destoyed");
  },
  mounted() {
    const lang = this.$route.params.lang;
    if (lang) {
      this.$refs["iframe3"].src =
        "https://demo.new-ledge.com/scenarios/cyber-security-heb/index.html";
    }
    this.readyIframe = true
    this.$parent.changeLogo();
  },
  async created() {
    if (!this.loggedinUser) {
      this.$message({
        showClose: true,
        message: `Login first!`,
        type: "warning",
        duration: 1500,
      });
      window.location =
        "https://demo-newledge.auth.us-east-2.amazoncognito.com/login?client_id=3ijg2b3k5i7epp88779v9l7p1q&response_type=token&scope=phone+email+openid+aws.cognito.signin.user.admin+profile&redirect_uri=https://demo.new-ledge.com/";
      return;
    }
  },
};
</script>

<style lang="scss" scoped>
$primary_color: #00f5af;

.scenario {
  position: relative;
  width: 100%;
  height: 100%;
  & iframe {
    width: 100%;
    height: 100%;
  }
  // .btn-show-iframe {
  //   position: absolute;
  //   top: 10px;
  //   left: 15px;
  //   padding: 10px;
  // }
  .hint {
    position: absolute;
    top: 33px;
    color: black;
  }
  .to-profile-btn {
    margin-top: 16px !important;
  }
  .button {
    border: $primary_color 2px solid;
    font-family: regular;
    padding: 0.5rem 1rem;
    border-radius: 5rem;
    margin: 0 0.25rem;
    transition: 0.3s;
    background: black;
    color: white;
    font-size: 1rem;
    &:hover {
      cursor: pointer;
      background: $primary_color;
      border-color: rgba(255, 255, 255, 0.25);
    }
    &.is-active {
      background: $primary_color;
      color: black;
      border-color: transparent;

      &:hover {
        background: darken($primary_color, 10%);
      }
    }
  }
}
</style>