<template>
  <div id="app" class="main-container">
    <app-header></app-header>
    <main ref="appList" class="mb10">
      <router-view />
    </main>
    <app-footer></app-footer>
  </div>
</template>

<script>
import "@/style/main.scss";
import appHeader from "@/cmp/app-header";
import appFooter from "@/cmp/app-footer";

export default {
  name: "PortalApp",
  components: {
    appHeader,
    appFooter,
  },
  methods: {
    changeLogo() {
      var someImg = document.querySelector(".company-logo img");
      console.log("changeLogo", someImg);
      if (!someImg) return;
      // if (!someImg || someImg.naturalWidth <= 0 || someImg.naturalHeight <= 0) return;
      console.log(someImg);
      console.log(someImg.naturalWidth);
      console.log(someImg.naturalHeight);
      if (someImg.naturalWidth > someImg.naturalHeight) {
        console.log("landscape");
        someImg.style.maxWidth = "100%";
        someImg.style.maxHeight = "100%";
        // this.$parent.typeImg = 'landscape'
        //it's a landscape
      } else if (someImg.naturalWidth < someImg.naturalHeight) {
        // this.$parent.typeImg = 'portrait'
        console.log("portrait");
        someImg.style.maxWidth = "50%";
        someImg.style.maxHeight = "100%";
        //it's a portrait
      } else {
        // this.$parent.typeImg = 'square'
        console.log("squre");
        someImg.style.maxWidth = "50%";
        someImg.style.maxHeight = "100%";
        //image width and height are equal, therefore it is square.
      }
    },
  },
  // created() {
  //   socketService.setup();
  // },
  // destroyed() {
  //   socketService.terminate();
  // }
};
</script>

<style lang="scss">
$primary_color: #00f5af;

@font-face {
  font-family: regular;
  src: url(./assets/fonts/MarkPro/MarkPro-ExtraLight.ttf);
}
// @font-face {
//   font-family: regular;
//   src: url(./assets/fonts/VarelaRound/VarelaRound-Regular.ttf);
// }

// @font-face {
//   font-family: regular;
//   src: url(./assets/fonts/Hind/Hind-Light.ttf);
// }

.hero {
  background-image: url(./assets/img/hero.jpg);
}

@font-face {
  font-family: hindBold;
  src: url(./assets/fonts/Hind/Hind-Bold.ttf);
}

.dg-backdrop {
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5000;
}
.dg-container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5000;
}
.dg-content-cont--floating {
  position: absolute;
  top: 35%;
  -webkit-transform: translateY(-70%);
  transform: translateY(-70%);
  margin-top: 0;
}

.dg-content-cont {
  width: 100%;
  font-family: inherit;
}
.dg-container * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.dg-main-content {
  width: 98%;
  /* width: calc(98% - 30px); */
  max-width: 400px;
  padding: 15px;
  border-radius: 5px;
  margin: 25px auto;
  background-color: black;
  border: 2px solid white;
  color: $primary_color;
}
.dg-content-body {
  border-bottom: 2px solid $primary_color;
  padding-bottom: 15px;
}
.dg-content {
  font-size: 18px;
  line-height: 1.3em;
  text-align: center;
}
.dg-content-footer {
  position: relative;
  padding: 15px 0 0;
}
.dg-btn--cancel {
  color: #fefefe;
  background-color: $primary_color;
}

.dg-btn {
  display: inline-block;
  position: relative;
  min-width: 80px;
  padding: 6px 20px;
  border-radius: 4px;
  outline: 0;
  border: 2px solid transparent;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 16px;
  font-weight: 700;
}
.dg-pull-right {
  float: right;
}

.dg-btn--ok {
  color: $primary_color;
  background-color: #fefefe;
  border-color: $primary_color;
}
</style>
