<template>
  <div class="sql-injection flex column center">
    <iframe
      ref="iframe1"
      @load="sendId"
      src="https://demo.new-ledge.com/scenarios/dev/index.html"
      frameborder="0"
      allowfullscreen="true"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
    ></iframe>
    <button class="button to-profile-btn" @click="() => this.$router.push('/')">
      Go back to Profile
    </button>
  </div>
</template>

<script>
export default {
  name: "dev",
  computed: {
    loggedinUser() {
      return this.$store.getters.loggedinUser;
    },
    authorizedToken() {
      return this.$store.getters.authorizedToken;
    },
  },
  methods: {
    showIframe() {
      this.readyIframe = true;
    },
    sendId() {
      this.$refs["iframe1"].contentWindow.postMessage(
        { id: this.loggedinUser.id, token: this.authorizedToken },
        "*"
      );
    },
  },
  async destroyed() {
    console.log("dev destoyed");
  },
  mounted() {
    this.$parent.changeLogo();
  },
  async created() {
    if (!this.loggedinUser) {
      this.$message({
        showClose: true,
        message: `Login first!`,
        type: "warning",
        duration: 1500,
      });
      window.location =
        "https://demo-newledge.auth.us-east-2.amazoncognito.com/login?client_id=3ijg2b3k5i7epp88779v9l7p1q&response_type=token&scope=phone+email+openid+aws.cognito.signin.user.admin+profile&redirect_uri=https://demo.new-ledge.com/";
      return;
    }
  },
};
</script>

<style lang="scss" scoped>
$primary_color: #00f5af;

.sql-injection {
  position: relative;
  width: 100%;
  height: 100%;
  & iframe {
    width: 100%;
    height: 100%;
  }
  // .btn-show-iframe {
  //   position: absolute;
  //   top: 10px;
  //   left: 15px;
  //   padding: 10px;
  // }
  .hint {
    position: absolute;
    top: 33px;
    color: black;
  }
  .to-profile-btn {
    margin-top: 16px !important;
  }
  .button {
    border: $primary_color 2px solid;
    font-family: regular;
    padding: 0.5rem 1rem;
    border-radius: 5rem;
    margin: 0 0.25rem;
    transition: 0.3s;
    background: black;
    color: white;
    font-size: 1rem;
    &:hover {
      cursor: pointer;
      background: $primary_color;
      border-color: rgba(255, 255, 255, 0.25);
    }
    &.is-active {
      background: $primary_color;
      color: black;
      border-color: transparent;

      &:hover {
        background: darken($primary_color, 10%);
      }
    }
  }
}
</style>