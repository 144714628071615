import { render, staticRenderFns } from "./second-video-quiz.vue?vue&type=template&id=1a35b0d4&scoped=true&"
import script from "./second-video-quiz.vue?vue&type=script&lang=js&"
export * from "./second-video-quiz.vue?vue&type=script&lang=js&"
import style0 from "./second-video-quiz.vue?vue&type=style&index=0&id=1a35b0d4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a35b0d4",
  null
  
)

export default component.exports