import Vue from 'vue';
import VueRouter from 'vue-router';
// import login from '../views/login';
import addUser from '../views/addUser';
import controllUsers from '../views/controllUsers';
import userDetails from '../views/userDetails';
import profile from '../views/profile';
import leaderboard from '../views/leaderboard';
import scenario from '../views/scenario';
import dashboard from '../views/dashboard';
import adminTools from '../views/adminTools';
import cyberRoom from '../views/cyber-room';
import cyberBank from '../views/cyber-bank';
import scenarioMobile from '../views/scenario-mobile';
import sqlInjectionMobile from '../views/sql-injection-mobile';
import cyberRoomMobile from '../views/cyber-room-mobile';
import cyberBankMobile from '../views/cyber-bank-mobile';
import longQuizMobile from '../views/long-quiz-mobile';
import sqlInjection from '../views/sql-injection';
import longQuiz from '../views/long-quiz';
import firstVideoQuiz from '../views/first-video-quiz';
import secondVideoQuiz from '../views/second-video-quiz';
import dev from '../views/dev';
import dailyQuiz1 from '../views/daily-quiz-1';
import dailyQuiz2 from '../views/daily-quiz-2';
import dailyQuiz3 from '../views/daily-quiz-3';
import dailyQuiz4 from '../views/daily-quiz-4';
import dailyQuiz5 from '../views/daily-quiz-5';
Vue.use(VueRouter)

const routes = [{
        path: '/dev',
        name: 'dev',
        component: dev
    },
    {
        path: '/daily-quiz-1',
        name: 'dailyQuiz1',
        component: dailyQuiz1
    },
    {
        path: '/daily-quiz-2',
        name: 'dailyQuiz2',
        component: dailyQuiz2
    },
    {
        path: '/daily-quiz-3',
        name: 'dailyQuiz3',
        component: dailyQuiz3
    },
    {
        path: '/daily-quiz-4',
        name: 'dailyQuiz4',
        component: dailyQuiz4
    },
    {
        path: '/daily-quiz-5',
        name: 'dailyQuiz5',
        component: dailyQuiz5
    },
    {
        path: '/first-video-quiz',
        name: 'firstVideoQuiz',
        component: firstVideoQuiz
    },
    {
        path: '/second-video-quiz',
        name: 'secondVideoQuiz',
        component: secondVideoQuiz
    },
    {
        path: '/sql-injection',
        name: 'sqlInjection',
        component: sqlInjection
    },
    {
        path: '/scenario/:lang?',
        name: 'scenario',
        component: scenario
    },
    {
        path: '/long-quiz',
        name: 'longQuiz',
        component: longQuiz
    },
    {
        path: '/adminTools',
        name: 'adminTools',
        component: adminTools
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: dashboard
    },
    {
        path: '/cyber-room/:lang?',
        name: 'cyberRoom',
        component: cyberRoom
    },
    {
        path: '/cyber-bank',
        name: 'cyberBank',
        component: cyberBank
    },
    {
        path: '/scenario-mobile',
        name: 'scenarioMobile',
        component: scenarioMobile
    },    
    {
        path: '/cyber-bank-mobile',
        name: 'cyberBankMobile',
        component: cyberBankMobile
    },
    {
        path: '/sql-injection-mobile',
        name: 'sqlInjectionMobile',
        component: sqlInjectionMobile
    },    
    {
        path: '/long-quiz-mobile',
        name: 'longQuizMobile',
        component: longQuizMobile
    },    
    {
        path: '/cyber-room-mobile',
        name: 'cyberRoomMobile',
        component: cyberRoomMobile
    },    
    {
        path: '/leaderboard',
        name: 'leaderboard',
        component: leaderboard
    },
    {
        path: '/',
        name: 'profile',
        component: profile
    },
    {
        path: '/addUser',
        name: 'addUser',
        component: addUser
    },
    {
        path: '/controllUsers',
        name: 'controllUsers',
        component: controllUsers
    },
    {
        path: '/userDetails/:userId',
        name: 'userDetails',
        component: userDetails
    },
    {
        path: '/:idToken',
        name: 'profile',
        component: profile
    },
    // {
    //     path: '/',
    //     name: 'login',
    //     component: login
    // }
]

const router = new VueRouter({
    routes,
    mode: 'hash'
})

export default router